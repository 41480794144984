import React, {useState, useEffect, useRef} from 'react';
import stile from './stile.module.css';
import casa2 from './casa2.png';
import menu2 from './menu2.png';
import frecciaSinistraBianca from './frecciasinistra-bianca.png';

function BarraTitolo(props) {
  const larghezzaMenu = 150;
  const [menuTendinaVisibile, setMenuTendinaVisibile] = useState(false);
  
  // Ref per il menu per rilevare clic fuori
  const menuRef = useRef(null);

  // Evento per chiudere il menu cliccando fuori
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuTendinaVisibile(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function VoceMenu(props) {
    return (<div
      style={{
        padding: "10px",
        cursor: "pointer",
        border: "0.5px solid lightgray"
      }}
      onClick={() => {
        props.callbackMenu(props.paginaDestinazione);
        setMenuTendinaVisibile(false);
      }}
    >
      {props.etichetta}
    </div>
    )
  }

  return (
    <div style={{flexDirection:'column', height:props.altezza}}>
      <div style={{flex: 2, display: 'flex', flexDirection:'row'}}>
        {props.flagIcone ? 
          <div class={stile.barraTitoloSpazioIcona} onClick={() => {if (props.paginaClienti!==props.PAGINECLIENTI.home) props.setFlagRichiestaBack(true)}}>
            <img 
              style={{ objectFit: "contain", marginLeft: 10, marginRight: 10, width: 35, cursor: 'pointer' }}
              src={props.paginaClienti === props.PAGINECLIENTI.home ? casa2 : frecciaSinistraBianca}
            />
          </div>
        : <></>}
        
        {props.identitaSelezionata.idpersona === undefined ?
          <div class={stile.barraTitoloSpazioNomePersona}>
            <div class={stile.barraTitoloNomePersona}>{"Gestione\nSportiva"}</div>
          </div>
        :      
          <div class={stile.barraTitoloSpazioNomePersona} onClick={() => { if (props.flagMultiIdentita) props.callbackMenu(props.PAGINECLIENTI.selezionaIdentita)}}>
            <div class={stile.barraTitoloNomePersona}>{props.identitaSelezionata.cognome} {props.identitaSelezionata.nome}</div>
            <div class={stile.barraTitoloNomePersona}>{props.identitaSelezionata.societa}</div>
          </div>      
        }

        {props.flagIcone ?
          <div class={stile.barraTitoloSpazioIcona} onClick={() => {setMenuTendinaVisibile(!menuTendinaVisibile)}}>
            <img 
              style={{marginLeft:10,marginRight:10, cursor: 'pointer'}}           
              src={menu2}
            />
          </div>
        : <></>}
      </div>

      <div className={stile.barraTitoloSpazioTitolo}>
  
        {/* STAFF a sinistra */}

        {props.identitaSelezionata.flag_staff==1 && (
          <div style={{
            backgroundColor: "#897070",
            display: 'flex',
            alignItems: 'center',
            padding: '0 5px',
            marginLeft: 10,
            justifyContent: 'center',
          }}>
            <div style={{ color: "#f9e0e0", fontSize: 11 }}>STAFF</div>
          </div>
        )}

        {/* TITOLO centrato assolutamente */}

        <div style={{
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
          textAlign: 'center',
          cursor: 'pointer'
        }} onClick={() => {
          if (props.titolo !== "Seleziona identità" && props.titolo !== "Necessario aggiornamento" && props.identitaSelezionata.idpersona !== undefined) {
            props.callbackMenu(props.PAGINECLIENTI.home);
          }
        }}>
          <div className={stile.barraTitoloTestoTitolo}>{props.titolo}</div>
        </div>

      </div>

      {/* Menu a tendina */}
      {menuTendinaVisibile && (
        <div
          ref={menuRef}
          style={{
            position: "absolute",
            top: "50px", // Modifica in base alla posizione desiderata
            right: "10px",
            backgroundColor: "#fff",
            border: "1px solid #ccc",
            borderRadius: "5px",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            zIndex: 1000,
            width: `${larghezzaMenu}px`,
          }}
        >
          <VoceMenu {...props} etichetta="Home" paginaDestinazione={props.PAGINECLIENTI.home}/>
          <VoceMenu {...props} etichetta="Eventi" paginaDestinazione={props.PAGINECLIENTI.eventi}/>
          <VoceMenu {...props} etichetta="Allenamenti" paginaDestinazione={props.PAGINECLIENTI.allenamenti}/>
          <VoceMenu {...props} etichetta="Prenotazioni" paginaDestinazione={props.PAGINECLIENTI.prenotazioni}/>
          <VoceMenu {...props} etichetta="News" paginaDestinazione={props.PAGINECLIENTI.news}/>
          <VoceMenu {...props} etichetta="Scadenze" paginaDestinazione={props.PAGINECLIENTI.scadenze}/>
          
          {/* questi database non fanno vedere la voce Pagamenti */}
          {/* attenzione che la stessa cosa vale per l'icona in Home.js */}
          {(props.identitaSelezionata.nome_database!="EMA48" && props.identitaSelezionata.nome_database!="EMA108") && 
            <VoceMenu {...props} etichetta="Pagamenti" paginaDestinazione={props.PAGINECLIENTI.movimenti}/>
          }
                    
          <VoceMenu {...props} etichetta="Abbonamenti" paginaDestinazione={props.PAGINECLIENTI.abbonamenti}/>
          <VoceMenu {...props} etichetta="Impostazioni" paginaDestinazione={props.PAGINECLIENTI.impostazioni}/>
        </div>
      )}

    </div>
  );
}


export default BarraTitolo;
