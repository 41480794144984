import React, {Fragment,useState,useRef} from 'react';
import { useEffect } from 'react';
import {
    Table,Col, Row, Card, CardBody,
    CardTitle, Button, Form, FormGroup, Label, Input, Modal
} from 'reactstrap';
import Parametri from '../../parametri';
import TendinaEditabile from '../TendinaEditabile.js';
import TrasformaDateAUmano from '../TrasformaDateAUmano.js';
import Clessidra from '../Clessidra.js';
import Modale from '../Modale.js';
import FinestraDialogo from '../FinestraDialogo.js';
import controllaDataAAAAMMGG from '../controllaDataAAAAMMGG.js';

//import moment from 'moment';//npm install moment

const STATO_INDEFINITO = 0;
const STATO_INSERISCI = 1;
const STATO_MEMORIZZAZIONE = 2;//utilizzato per eseguire le operazioni di inserimento/modifica/eliminazione record
const STATO_ERRORE_CONNESSIONE = 3;
const STATO_OPERAZIONE_FALLITA = 4;
const STATO_RICERCA_DATI = 5;//utilizzato nel caso modifica e cancella quando vado a ricercare i dati per popolare i campi della scheda
const STATO_RICERCA_DATI_CORRETTA = 6;//utilizzato a valle della ricerca dati record in caso di modifica o cancellazione di un record
//const STATO_RICHIEDI_PERMESSO_INSERIRE = 8;


function FormGridFormRow(props) {

    var today = new Date();
    let sMese = String(today.getMonth()+1);
    if(sMese.length === 1) sMese = "0"+sMese;
    let sGiorno = String(today.getDate());
    if(sGiorno.length === 1) sGiorno = "0"+sGiorno;

    const dataOggi = today.getFullYear()+'-'+sMese+'-'+sGiorno
    
    const [operazione,setOperazione] = useState('');
    const [variazioneCampi,setVariazioneCampi]=useState(0);

    const [modaleInserisciEnte, setModaleInserisciEnte] = React.useState(false);
    const [modaleRichiediPermessoInserire, setModaleRichiediPermessoInserire] = React.useState(false);
    const [modaleInserisciDescrizione, setModaleInserisciDescrizione] = React.useState(false);
    const [modaleInserisciDataInizio, setModaleInserisciDataInizio] = React.useState(false);

    const [flagAttivaModaleInserisciEnte, setFlagAttivaModaleInserisciEnte]  = useState(0);
    const [flagAttivaModaleRichiediPermessoInserire, setFlagAttivaModaleRichiediPermessoInserire] = useState(0);
    const [flagAttivaModaleInserisciDescrizione, setFlagAttivaModaleInserisciDescrizione] = useState(0);
    const [flagAttivaModaleInserisciDataInizio, setFlagAttivaModaleInserisciDataInizio] = useState(0);

    //const ente   = useRef();
    const descrizione   = useRef();
    const dataInizio   = useRef();
    const dataFine   = useRef();
    const note   = useRef();

    const [statoPagina,setStatoPagina]=useState(STATO_INDEFINITO);
    const [statoTendinaEnte,setStatoTendinaEnte]=useState(false);

    const [error,setError]=useState('');
    //const [risultatoServer,setRisultatoServer]=useState('');

    const [risultatoServerDatiEnte,setRisultatoServerDatiEnte]=useState('');

    const [insEnteEdi,setInsEnteEdi]=useState('');

    const [permessoInserireEnte,setPermessoInserireEnte]=useState(0);

    //const [defaultEnte,setDefaultEnte]=useState('');
    const [descrizioneDefault,setDescrizioneDefault]=useState('');
    const [dataInizioDefault,setDataInizioDefault]=useState(dataOggi);
    const [dataFineDefault,setDataFineDefault]=useState('');
    const [noteDefault,setNoteDefault]=useState('');

    const [disabilitaBottoni,setDisabilitaBottoni]= useState(false);
    const [loading,setLoading]= useState(false);

    useEffect(() => {
        setStatoPagina(STATO_INSERISCI);
        setStatoTendinaEnte(false);
        setPermessoInserireEnte(0);
    },[]);

    useEffect(() => {
        if(statoTendinaEnte === true){
            setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
        }
    },[statoTendinaEnte]);

    useEffect(() => {
        if(permessoInserireEnte===1) fetchOperazioneServer(0,1);
    },[permessoInserireEnte]);

    /*useEffect(() => {
        console.log("ecco"+dataInizioDefault);
        if(moment(dataInizioDefault, 'YYYY-MM-DD').isValid()) console.log("Si")
        else console.log("no")
        
    },[dataInizioDefault]);*/
    

    useEffect(() => {
        if(props.ope==='inserisci') setOperazione("convenzioniIns");
        if(props.ope==='modifica') setOperazione("convenzioniMod");
        if(props.ope==='cancella') setOperazione("convenzioniCanc");

        if(props.ope==="modifica" || props.ope==="cancella"){
            //devo eseguire una fetch per popolare i campi della scheda con i dati presi da db per il record in esame:
            // parametri per inviare i dati al server:
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({op:"convenzioniRecuperaDati",
                    id:props.id,
                    emak: props.emak,
                    sessionId:props.sessionId
                })
            };
            setStatoPagina(STATO_RICERCA_DATI);

            fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valoreDiRitorno) => {
                    if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                        alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                    }
                    if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                    if(valoreDiRitorno.risultatoOperazione===1){
                    //setRisultatoServer(valoreDiRitorno.risultatoRitorno);
                    setInsEnteEdi(valoreDiRitorno.risultatoRitorno[0].COGNOME);//serve per settare la var di stato se non cambi valore nella tendina in caso di modifica
                    //setDefaultEnte(valoreDiRitorno.risultatoRitorno[0].COGNOME);
                    setDescrizioneDefault (valoreDiRitorno.risultatoRitorno[0].DESCRIZIONE);
                    setDataInizioDefault(valoreDiRitorno.risultatoRitorno[0].DATA_INIZIO);
                    setDataFineDefault(valoreDiRitorno.risultatoRitorno[0].DATA_FINE);
                    setNoteDefault(valoreDiRitorno.risultatoRitorno[0].NOTE);
                
                    ricercaDatiEnte(props.ope);
                    }
                    },
                (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            ); 
        }else{
            setStatoPagina(STATO_RICERCA_DATI);
            ricercaDatiEnte(props.ope);
        }
    },[props.ope]);

    function ricercaDatiEnte(operazione) {
        //ricerco gli enti convenzioni che mi servono per popolare le tendine:
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoEntiConvenzioni",recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                setRisultatoServerDatiEnte(valoreDiRitorno.risultatoRitorno);
               setStatoTendinaEnte(true);
                }
            },
            (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }

    function tornaElenco() {
        props.callbackTermineModifica();
    }

    function handleCambiamentoMod(event) {
        //event.preventDefault();
        setVariazioneCampi(1);
    }


    function annullaInserimentoDatiNuovi(){
        setPermessoInserireEnte(0);
        setModaleInserisciEnte(false);
        setModaleRichiediPermessoInserire(false);
        setModaleInserisciDescrizione(false);
        setModaleInserisciDataInizio(false);

        setFlagAttivaModaleInserisciEnte(0);
        setFlagAttivaModaleRichiediPermessoInserire(0);
        setFlagAttivaModaleInserisciDescrizione(0);
        setFlagAttivaModaleInserisciDataInizio(0);

        //setStatoPagina(STATO_INSERISCI);
    }

    function confermaInserimentoDatiNuovi(){
        //setStatoPagina(STATO_INSERISCI);
        setPermessoInserireEnte(1);
    }
    function fetchOperazioneServer(bypassaControlli=0,permessoInserire=0) {
        //invio l'azione da fare sul server (aggiungi/togli) e l'elemento in esame al server che mi ritornerà l'elenco aggiornato
        let controlli=0;
        //cancello comunque;
        if(props.ope!=='cancella' && bypassaControlli===0){

            var dInizio = controllaDataAAAAMMGG(dataInizio.current.value,2015);
            var dFine = controllaDataAAAAMMGG(dataFine.current.value,2015);

            if(dInizio == ''){
                controlli = 1;
                setModaleInserisciDataInizio(true);
                setFlagAttivaModaleInserisciDataInizio(1);
            }
            if (dInizio == "errore") {
                controlli = 1;
                alert("Data di inizio convenzione non valida (anno minimo 2015)");
                setDisabilitaBottoni(false);
                annullaInserimentoDatiNuovi();
            }
            if (dFine == "errore") {
                controlli = 1;
                alert("Data di fine convenzione non valida (anno minimo 2015)");
                setDisabilitaBottoni(false);
                annullaInserimentoDatiNuovi()
            }

            if(note.current.value.length>500){
                controlli = 1;
                alert("Il campo Note contiene troppi caratteri (massimo accettato 500)");
                setDisabilitaBottoni(false);
            }
            
            if(insEnteEdi === ''){
                controlli = 1;
                setModaleInserisciEnte(true);
                setFlagAttivaModaleInserisciEnte(1);
            }
            if(descrizione.current.value=== ''){
                controlli = 1;
                setModaleInserisciDescrizione(true);
                setFlagAttivaModaleInserisciDescrizione(1);
            }
        }

        if(controlli===0){ 
            if(props.ope==='modifica' && variazioneCampi===0){
                //in relatà non e stata fatta alcuna variazione nei campi originali per cui torno ad elenco:
                tornaElenco();
            }else{
                setLoading(true);
                // parametri per inviare i dati al server:
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: props.ope!=='cancella' ? 
                    JSON.stringify({op:operazione,
                        ente:insEnteEdi,
                        permessoInserireEnte:permessoInserire,
                        descrizione:descrizione.current.value,
                        dataInizio:dInizio,
                        dataFine:dFine,
                        note:note.current.value,
                        idElemento:props.id,
                        emak: props.emak,
                        sessionId:props.sessionId
                    })
                    : 
                        JSON.stringify({op:operazione,
                        idElemento:props.id,
                        emak: props.emak,
                        sessionId:props.sessionId})
                };
            
            // qui faccio la chiamata alla pagina sul server, quindi ne leggo la risposta che io trasformo in oggetto json 
            // con .json() ottenendo "valore_di_ritorno": in questo oggetto json ho "risultato_ritorno" che metto nella 
            // variabile di stato "risultato_server"
                //setStatoPagina(STATO_MEMORIZZAZIONE);

                fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                    .then(risposta => risposta.json())
                    .then(
                    (valoreDiRitorno) => {
                        setLoading(false);
                        if(valoreDiRitorno.risultatoOperazione===1) tornaElenco();
                        if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                        if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                            if(valoreDiRitorno.mess !== undefined){
                                if(valoreDiRitorno.mess.indexOf("Richiedi permesso per inserire ente") !== -1){
                                    //setStatoPagina(STATO_RICHIEDI_PERMESSO_INSERIRE),
                                    //setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                                    setModaleRichiediPermessoInserire(true);
                                    setPermessoInserireEnte(-1);
                                    setFlagAttivaModaleRichiediPermessoInserire(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Descrizione non valorizzata") !== -1){
                                    //setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                                    setModaleInserisciDescrizione(true);
                                    setFlagAttivaModaleInserisciDescrizione(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Indicare l'ente da tendina") !== -1){
                                    //setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                                    setModaleInserisciEnte(true);
                                    setFlagAttivaModaleInserisciEnte(1);
                                }  
                                if(valoreDiRitorno.mess.indexOf("Data inizio non valorizzata") !== -1){
                                    //setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                                    setModaleInserisciDataInizio(true);
                                    setFlagAttivaModaleInserisciDataInizio(1);
                                }
                            }
                            //altrimenti vado in errore generico
                            if(!valoreDiRitorno.mess) setStatoPagina(STATO_OPERAZIONE_FALLITA);
                        } ;
                    },
                    (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
                );
            }
        }
    }
 
    //JSON.stringify(risultatoServer)
        return (
            <Fragment>
              
                {/*eccola {props.sessionId}*/}
            
                {statoPagina === STATO_MEMORIZZAZIONE ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}
                {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1Conv {error.message}</div> :""}
                {statoPagina === STATO_OPERAZIONE_FALLITA ? <div>Errore per operazione errata</div> :""}
                {statoPagina === STATO_RICERCA_DATI ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}

                {statoPagina === STATO_RICERCA_DATI_CORRETTA ?
                <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>

                    {flagAttivaModaleInserisciDescrizione===1 ?
                        <Modale 
                            titolo="Richiesta descrizione"
                            flagErrore={true}
                            contenuto={<div>Non è stata indicata la descrizione, che è necessaria</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModaleRichiediPermessoInserire===1 ?
                        <Modale 
                            titolo="Richiesta permesso inserire"
                            flagErrore={true}
                            contenuto={<div>
                                {permessoInserireEnte===-1 ? <div>Vuoi davvero inserire {insEnteEdi} nell'elenco degli enti convenzioni?</div> :""}                          
                               </div>}
                            bottoni={[
                                {
                                    "etichetta":"Ok",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);confermaInserimentoDatiNuovi()}
                                },
                                {
                                    "etichetta":"Annulla",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModaleInserisciEnte===1 ?
                        <Modale 
                            titolo="Richiesta ente"
                            flagErrore={true}
                            contenuto={<div>Non è stato indicato l'ente, che è necessario</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModaleInserisciDataInizio===1 ?
                        <Modale 
                            titolo="Richiesta data inizio"
                            flagErrore={true}
                            contenuto={<div>Non è stata indicata la data inizio, che è necessaria</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                                }    
                            ]}
                        />
                    :""}

        
                    <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}>
                            {props.ope === "inserisci" ? <CardTitle>Inserimento Convenzione</CardTitle> :""}
                            {props.ope === "modifica" ? <CardTitle>Modifica Convenzione</CardTitle> :""}
              
                       
                            <Clessidra loading={loading}/>

                            {props.ope === "cancella" ?
                                <FinestraDialogo 
                                    titolo="Conferma eliminazione"
                                    flagErrore={true}                                
                                    contenuto={<div>
                                        Confermi l'eliminazione della convenzione <b>{descrizioneDefault}</b> di <b>{insEnteEdi}</b> ?<br/>                                    
                                    </div>}
                                    bottoni={[
                                        {
                                            "etichetta":"Conferma",
                                            "tipo":"primary",
                                            callback:() => {setDisabilitaBottoni(true);fetchOperazioneServer()}
                                        },
                                        {
                                            "etichetta":"Annulla",
                                            "tipo":"primary",
                                            callback: () => {tornaElenco()}
                                        }
                                    ]}
                                />                        

                            :
                            
                            <>
                            <Row form>  
                                <Col md={12}>   
                                <FormGroup>
                                <Label>Ente convenzione*</Label>
                                <TendinaEditabile
                                    //titolo={"Ente convenzione*"}
                                    nomeCampo="COGNOME"
                                    valoreDefault={insEnteEdi}
                                    arrayValori={risultatoServerDatiEnte}
                                    callbackSetVariazioneCampi={setVariazioneCampi}
                                    callbackSetValoreTendEditabile={setInsEnteEdi}
                                ></TendinaEditabile>
                                </FormGroup>
                                </Col>
                                    
                                </Row>   
                                <Row form>  
                                <Col md={12}>
                                <FormGroup>
                                    <Label for="descrizione">Descrizione*</Label>
                                        <Input maxLength={255} type="text" name="descrizione" id="descrizione"
                                        innerRef={descrizione}
                                        placeholder="" 
                                        defaultValue={descrizioneDefault}
                                        onInput={(e)=>{handleCambiamentoMod(e);setDescrizioneDefault(descrizione.current.value);}}/> 
                                </FormGroup>
                                </Col>
                                </Row>
                                <Row form>  
                                <Col md={6}>
                                <FormGroup>
                                    <Label for="dataInizio">Data Inizio*</Label>
                                        <Input maxLength={10} type="date" name="dataInizio" id="dataInizio"
                                        innerRef={dataInizio}
                                        placeholder="" 
                                        defaultValue={dataInizioDefault}
                                        value = {dataInizioDefault}
                                        onInput={(e)=>{handleCambiamentoMod(e);setDataInizioDefault(dataInizio.current.value);}}/>
                                </FormGroup>
                                </Col>
                                <Col md={6}>
                                <FormGroup>
                                    <Label for="dataFine">Data Fine</Label>
                                        <Input maxLength={10} type="date" name="dataFine" id="dataFine"
                                        innerRef={dataFine}
                                        placeholder="" 
                                        defaultValue={dataFineDefault}
                                        onInput={(e)=>{handleCambiamentoMod(e);setDataFineDefault(dataFine.current.value);}}/> 
                                </FormGroup>
                                </Col>
                                </Row>
                                <Row form>  
                                <Col md={12}>
                                <FormGroup>
                                    <Label for="note">Note</Label>
                                        <Input type="textarea" name="note" id="note"
                                        innerRef={note}
                                        placeholder="" 
                                        defaultValue={noteDefault}
                                        onInput={(e)=>{handleCambiamentoMod(e);setNoteDefault(note.current.value);}}/> 
                                </FormGroup>
                                </Col>
                                </Row>
                                </>
                              
                                }
                               
                                
                                </div>

                                <div className="schedaSpazioInferiore">
                                {props.ope === "inserisci" ? <Button disabled={disabilitaBottoni} color="success" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer()}}>Inserisci</Button> :""}
                                {props.ope === "modifica" && variazioneCampi === 1? <Button disabled={disabilitaBottoni} color="warning" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer()}}>Memorizza Modifiche</Button>:""}
                                
                                <Button disabled={disabilitaBottoni} color="primary" className="mt-2" onClick={() => tornaElenco()} >
                                {(variazioneCampi === 1 && props.ope === "modifica") ?  <Fragment> Annulla </Fragment>: <Fragment>Torna all'elenco</Fragment>}
                                </Button>
                                </div>
                            
                </div>
                :""}
            </Fragment>
        );
}
export default FormGridFormRow;

