import React, {Fragment,useState,useRef} from 'react';
import { useEffect } from 'react';
import {
    Table,Col, Row, Card, CardBody,
    CardTitle, Button, Form, FormGroup, Label, Input , Modal
} from 'reactstrap';
import Parametri from '../../parametri';
import TendinaEditabile from '../TendinaEditabile.js';
import Clessidra from '../Clessidra.js';
import Modale from '../Modale.js';
import FinestraDialogo from '../FinestraDialogo.js';
import TrasformaDateAUmano from '../TrasformaDateAUmano.js';
import FormattaImportoVideo from '../FormattaImportoVideo.js';

const STATO_INDEFINITO = 0;
const STATO_INSERISCI = 1;
const STATO_MEMORIZZAZIONE = 2;//utilizzato per eseguire le operazioni di inserimento/modifica/eliminazione record
const STATO_ERRORE_CONNESSIONE = 3;
const STATO_OPERAZIONE_FALLITA = 4;
const STATO_RICERCA_DATI = 5;//utilizzato nel caso modifica e cancella quando vado a ricercare i dati per popolare i campi della scheda
const STATO_RICERCA_DATI_CORRETTA = 6;//utilizzato a valle della ricerca dati record in caso di modifica o cancellazione di un record

function FormGridFormRow(props) {
    
    const [statoPagina,setStatoPagina]=useState(STATO_INDEFINITO);
    const [error,setError]=useState('');
    const [dati, setDati] = useState([]);
    const [argomento, setArgomento] = useState('');
    const [tipo, setTipo] = useState('');
    const [loading,setLoading]= useState(false);
   
    useEffect(() => {
 
        if(props.ope==="dettagli"){
            //devo eseguire una fetch per popolare i campi della scheda con i dati presi da db per il record in esame:
            // parametri per inviare i dati al server:
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({op:"azioneRecuperaDettagli",
                    chiave:props.id,
                    dataPartenza:props.dataPartenza,
                    dataArrivo:props.dataArrivo,
                    emak: props.emak,
                    sessionId:props.sessionId
                })
            };
            setStatoPagina(STATO_RICERCA_DATI);

            fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valoreDiRitorno) => {
                    if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                        alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                    }
                    if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                    if(valoreDiRitorno.risultatoOperazione===1){
                    //setRisultatoServer(valoreDiRitorno.risultatoRitorno);
                    
                    setDati(valoreDiRitorno.risultatoRitorno);
                    setArgomento(valoreDiRitorno.argomento);
                    setTipo(valoreDiRitorno.tipo);
                    setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                    }
                    },
                (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            ); 
        }else setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
    },[props.ope]);

    function tornaElenco() {
        props.callbackTermineModifica();
    }


    function FormattaFlag($val){
        if($val == 1) return "Attivo";
        else return "Disattivo";
    }

        return (
            <>
                
                {/*eccola {props.sessionId}*/}
            
                {statoPagina === STATO_MEMORIZZAZIONE ? <Card className="main-card mb-3"><Clessidra loading={true}/></Card> :""}
                {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1AZ {error.message}</div> :""}
                {statoPagina === STATO_OPERAZIONE_FALLITA ? <div>Errore per operazione errata</div> :""}
                {statoPagina === STATO_RICERCA_DATI ? <Card className="main-card mb-3"><Clessidra loading={true}/></Card> :""}

                {statoPagina === STATO_RICERCA_DATI_CORRETTA ?
                <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>

                   

                    <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}>
                           
                            {props.ope === "dettagli" ? <CardTitle>Dettagli Azione</CardTitle> :""}
                                <>

                                {argomento === "iscritti" &&
                                        dati.map((item, index) => (
                                        <div key={index}>
                                            <Row form>  
                                                <Col md={6}><FormGroup><Label for="data">Data operazione:</Label></FormGroup></Col>
                                                <Col md={6}><FormGroup><TrasformaDateAUmano data={item.DATA_OPERAZIONE}></TrasformaDateAUmano></FormGroup></Col>
                                            </Row>
                                            <Row form>  
                                                <Col md={6}><FormGroup><Label for="data">Eseguita da:</Label></FormGroup></Col>
                                                <Col md={6}><FormGroup>{item.ATTORE}</FormGroup></Col>
                                            </Row>
                                            <Row form>  
                                                <Col md={6}><FormGroup><Label for="argomento">Argomento:</Label></FormGroup></Col>
                                                <Col md={6}><FormGroup>{argomento}</FormGroup></Col>
                                            </Row>
                                            <Row form>  
                                                <Col md={6}><FormGroup><Label for="tipo">Tipo:</Label></FormGroup></Col>
                                                {tipo == "IViscritto" ? <Col md={6}><FormGroup>Inserimento con inserimento privilegi</FormGroup></Col> :""} 
                                                {tipo == "MViscritto" ? <Col md={6}><FormGroup>Modifica con variazione dei privilegi</FormGroup></Col> :""}
                                            </Row>

                                            <Row form>
                                            <Col md={6}>
                                                <FormGroup>
                                                <Label for="su">Eseguito su:</Label>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>{item.ISCRITTO}</FormGroup>
                                            </Col>
                                            </Row>
                                            {item.FLAG_STAFF_NUOVO != item.FLAG_STAFF_PREC  ?
                                                <Row form>
                                                <Col md={6}>
                                                    <FormGroup>
                                                    <Label for="varimp">Il privilegio staff è stato modificato :</Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup>
                                                     da {FormattaFlag(item.FLAG_STAFF_PREC)} a {FormattaFlag(item.FLAG_STAFF_NUOVO)} 
                                                    </FormGroup>
                                                </Col>
                                                </Row>
                                            :""
                                            }
                                            {item.FLAG_AMM_NUOVO != item.FLAG_AMM_PREC  ?
                                                <Row form>
                                                <Col md={6}>
                                                    <FormGroup>
                                                    <Label for="varimp">Il privilegio amministatore è stato modificato :</Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup>
                                                     da {FormattaFlag(item.FLAG_AMM_PREC)} a {FormattaFlag(item.FLAG_AMM_NUOVO)} 
                                                    </FormGroup>
                                                </Col>
                                                </Row>
                                            :""
                                            }
                                            
                                            
                
                                            <hr />
                                        </div>
                                ))}
                                

                    
                                {argomento === "abbonati" &&
                                        dati.map((item, index) => (
                                        <div key={index}>
                                            <Row form>  
                                                <Col md={6}><FormGroup><Label for="data">Data operazione:</Label></FormGroup></Col>
                                                <Col md={6}><FormGroup><TrasformaDateAUmano data={item.DATA_OPERAZIONE}></TrasformaDateAUmano></FormGroup></Col>
                                            </Row>
                                            <Row form>  
                                                <Col md={6}><FormGroup><Label for="data">Eseguita da:</Label></FormGroup></Col>
                                                <Col md={6}><FormGroup>{item.ATTORE}</FormGroup></Col>
                                            </Row>
                                            <Row form>  
                                                <Col md={6}><FormGroup><Label for="argomento">Argomento:</Label></FormGroup></Col>
                                                <Col md={6}><FormGroup>{argomento}</FormGroup></Col>
                                            </Row>
                                            <Row form>  
                                                <Col md={6}><FormGroup><Label for="tipo">Tipo:</Label></FormGroup></Col>
                                                {tipo == "I" ? <Col md={6}><FormGroup>Inserimento</FormGroup></Col> :""} 
                                                {tipo == "IVabb" ? <Col md={6}><FormGroup>Inserimento con variazione del valore dell'importo</FormGroup></Col> :""} 
                                                {tipo == "M" ? <Col md={6}><FormGroup>Modifica</FormGroup></Col> :""} 
                                                {tipo == "MVabb" ? <Col md={6}><FormGroup>Modifica con variazione del valore dell'importo</FormGroup></Col> :""}
                                                {tipo == "C" ? <Col md={6}><FormGroup>Eliminazione</FormGroup></Col> :""}
                                            </Row>

                                            {item.MODIFICA_IMPORTO != '' ?
                                                <Row form>
                                                <Col md={6}>
                                                    <FormGroup>
                                                    <Label for="varimp">Variazione Importo:</Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup>
                                                    Importo modificato da {FormattaImportoVideo(item.MODIFICA_IMPORTO.split('_')[0])} € a {FormattaImportoVideo(item.MODIFICA_IMPORTO.split('_')[1])} €
                                                    </FormGroup>
                                                </Col>
                                                </Row>
                                            :
                                                <Row form>
                                                <Col md={6}>
                                                    <FormGroup>
                                                    <Label for="importo">Importo:</Label>
                                                    </FormGroup>
                                                </Col>
                                                <Col md={6}>
                                                    <FormGroup>{FormattaImportoVideo(item.IMPORTO)} €</FormGroup>
                                                </Col>
                                                </Row>
                                            }
                                            
                                            <Row form>
                                            <Col md={6}>
                                                <FormGroup>
                                                <Label for="su">Eseguito su:</Label>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>{item.ISCRITTO}</FormGroup>
                                            </Col>
                                            </Row>
                                            <Row form>
                                            <Col md={6}>
                                                <FormGroup>
                                                <Label for="abbo">Abbonamento:</Label>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>{item.NOMEABBONAMENTO}</FormGroup>
                                            </Col>
                                            </Row>
                                            <Row form>
                                            <Col md={6}>
                                                <FormGroup>
                                                <Label for="inizio">Data Inizio:</Label>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                <TrasformaDateAUmano data={item.DATA_INIZIO} />
                                                </FormGroup>
                                            </Col>
                                            </Row>
                                            <Row form>
                                            <Col md={6}>
                                                <FormGroup>
                                                <Label for="fine">Data Fine:</Label>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                <TrasformaDateAUmano data={item.DATA_FINE} />
                                                </FormGroup>
                                            </Col>
                                            </Row>
                                            
                                            

                                            <Row form>
                                            <Col md={6}>
                                                <FormGroup>
                                                <Label for="nAcc">Numero Accessi:</Label>
                                                </FormGroup>
                                            </Col>
                                            {item.ACCESSI == -1 ?
                                                <Col md={6}>
                                                    <FormGroup>Illimitato</FormGroup>
                                                </Col>
                                            :
                                                <Col md={6}>
                                                    <FormGroup>{item.ACCESSI}</FormGroup>
                                                </Col>
                                            }   
                                            </Row>
                                            <hr />
                                        </div>
                                ))}
                                    
                                


                            </>
                            
                            </div>

                            <div className="schedaSpazioInferiore">
                            <Button color="primary" className="mt-2 bottoneMargineADestra" onClick={() => tornaElenco()} >
                            <Fragment>Torna all'elenco</Fragment>
                            </Button>
                            </div>     
                </div>
                :""}
            </>
        );
}
export default FormGridFormRow;
