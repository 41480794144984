import React, {Fragment,useState,useRef} from 'react';
import { useEffect } from 'react';
import {
    Table,Col, Row, Card, CardBody,
    CardTitle, Button, Form, FormGroup, Label, Input,Modal
} from 'reactstrap';
import Parametri from '../../parametri';
import FormattaNumeroDb from '../FormattaNumeroDb.js';
import VisualizzaAllegato from '../VisualizzaAllegato.js';

import Clessidra from '../Clessidra.js';
import Modale from '../Modale.js';
import Interrogativo from '../../immagini/interrogativo-azzurro.png';
import Resizer from "react-image-file-resizer";

const STATO_INDEFINITO = 0;
const STATO_INSERISCI = 1;
const STATO_MEMORIZZAZIONE = 2;//utilizzato per eseguire le operazioni di inserimento/modifica/eliminazione record
const STATO_ERRORE_CONNESSIONE = 3;
const STATO_OPERAZIONE_FALLITA = 4;
const STATO_RICERCA_DATI = 5;//utilizzato nel caso modifica e cancella quando vado a ricercare i dati per popolare i campi della scheda
const STATO_RICERCA_DATI_CORRETTA = 6;//utilizzato a valle della ricerca dati record in caso di modifica o cancellazione di un record

function TablesImpostazioniSocieta(props) {
    
    const [variazioneCampi,setVariazioneCampi]=useState(0);
    const [variazioneCampiLayoutTessere,setVariazioneCampiLayoutTessere]=useState(0);

    //const data   = useRef();
    const dataTesseramenti   = useRef();
    const annullamentoPrenotazioni   = useRef();
    const unita   = useRef();
    const nome   = useRef();
    const piva   = useRef();
    const codiceFiscale  = useRef();
    const cap   = useRef();
    const indirizzo   = useRef();
    const citta   = useRef();
    const provincia   = useRef();
    const comune   = useRef();
    const pec   = useRef();
    const telefono   = useRef();
    const regimeFiscale = useRef();
    const reaUfficio = useRef();
    const reaNumero = useRef();
    const capitaleSociale = useRef();

    const host = useRef();
    const port = useRef();
    const senderName = useRef();
    const senderMail = useRef();
    const user = useRef();
    const pass = useRef();
    const tipoEnc = useRef();
    const auth= useRef();
    const autotls = useRef();

    //const logo   = useRef();
    const flagEliminaFotoRif   = useRef();

    const posizioneFoto   = useRef();
    const posizioneLogo   = useRef();
    const coloreSfondo     = useRef();
    const bordoArrotondato     = useRef();
   
    var today = new Date();
    let sMese = String(today.getMonth()+1);
    if(sMese.length === 1) sMese = "0"+sMese;
    let sGiorno = String(today.getDate());
    if(sGiorno.length === 1) sGiorno = "0"+sGiorno;

    
    const ultimoGiorno = 31;
    const ultimoMese = 12;
    const dataFineAnno = today.getFullYear()+'-'+ultimoMese+'-'+ultimoGiorno

    const [statoPagina,setStatoPagina]=useState(STATO_INDEFINITO);

    const [error,setError]=useState('');

    //const [flagAttivaModaleRichiestaDataChiusuraEsercizio, setFlagAttivaModaleRichiestaDataChiusuraEsercizio] = useState(0);
    const [flagAttivaModaleRichiestaDataChiusuraTesseramenti, setFlagAttivaModaleRichiestaDataChiusuraTesseramenti] = useState(0);
    const [flagAttivaModaleAnnullamentoPrenotazioniErrato, setFlagAttivaModaleAnnullamentoPrenotazioniErrato] = useState(0);
    const [flagAttivaModaleDimensioneFoto, setFlagAttivaModaleDimensioneFoto] = useState(0);
    const [flagAttivaModaleInfoAnnullamantoPrenotazioni, setFlagAttivaModaleInfoAnnullamantoPrenotazioni] = useState(0);
    const [fineSpazio, setFineSpazio] = useState(0);

    const [modaleImpostazioniLayoutTessere, setModaleImpostazioniLayoutTessere] = useState(false);
    const [modaleIndirizzoLungo, setModaleIndirizzoLungo] = useState(false);
    
    
    //const [dataDefault,setDataDefault]=useState(dataFineAnno);
    const [dataTesseramentiDefault,setDataTesseramentiDefault]=useState(dataFineAnno);
    const [annullamentoPrenotazioniDefault,setAnnullamentoPrenotazioniDefault]=useState(24);
    const [unitaDefault,setUnitaDefault]=useState(24);
    const [nomeDefault,setNomeDefault]=useState('');
    const [pivaDefault,setPivaDefault]=useState('');
    const [codiceFiscaleDefault,setCodiceFiscaleDefault]=useState('');
    const [capDefault,setCapDefault]=useState('');
    const [indirizzoDefault,setIndirizzoDefault]=useState('');
    const [cittaDefault,setCittaDefault]=useState('');
    const [provinciaDefault,setProvinciaDefault]=useState('');
    const [comuneDefault,setComuneDefault]=useState('');
    const [pecDefault,setPecDefault]=useState('');
    const [telefonoDefault,setTelefonoDefault]=useState('');
    const [regimeFiscaleDefault,setRegimeFiscaleDefault]=useState('');
    const [reaUfficioDefault,setReaUfficioDefault]=useState('');
    const [reaNumeroDefault,setReaNumeroDefault]=useState('');
    const [capitaleSocialeDefault,setCapitaleSocialeDefault]=useState('');
    const [socioUnicoDefault,setSocioUnicoDefault]=useState('');
    const [statoLiquidazioneDefault,setStatoLiquidazioneDefault]=useState('');

    const [hostDefault,setHostDefault]=useState('');
    const [portDefault,setPortDefault]=useState('');
    const [senderNameDefault,setSenderNameDefault]=useState('');
    const [senderMailDefault,setSenderMailDefault]=useState('');
    const [userDefault,setUserDefault]=useState('');
    const [passDefault,setPassDefault]=useState('');
    const [tipoEncDefault,setTipoEncDefault]=useState('');
    const [authDefault,setAuthDefault]=useState('');
    const [autotlsDefault,setAutotlsDefault]=useState('');
    
    //const [logoDefault,setLogoDefault]=useState('');
    const [disabilitaBottoni,setDisabilitaBottoni]= useState(false);
    const [loading,setLoading]= useState(false);

    const [typeFotoDefault,setTypeFotoDefault]=useState('');

    const [contenutoFile,setContenutoFile]=useState('');
    const [dimensioneFoto,setDimensioneFoto]=useState('');
    const [dimensioneMassimaFoto,setDimensioneMassimaFoto]=useState('');
    const [flagEliminaFoto,setFlagEliminaFoto]=useState('');

    const [estensioneFileFoto, setEstensioneFileFoto] = useState('');
    const [flagFotoModificata, setFlagFotoModificata] = useState(false);

    const [flagModaleInfoFoto, setFlagModaleInfoFoto] = React.useState(false);
    const [flagModaleConfermaCancFoto, setFlagModaleConfermaCancFoto] = React.useState(false);

    const [arrayLayoutTessere,setArrayLayoutTessere]=useState([]);

    useEffect(() => {
        setStatoPagina(STATO_INSERISCI);
    },[]);

    useEffect(() => {
        //setOperazione("impostazioniSocietaMod");
        if(statoPagina === STATO_INSERISCI){
        //if(props.ope==="modifica" || props.ope==="cancella"){
            //devo eseguire una fetch per popolare i campi della scheda con i dati presi da db per il record in esame:
            // parametri per inviare i dati al server:
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({op:"impostazioniSocietaRecuperaDati",
                    id:props.id,
                    emak: props.emak,
                    sessionId:props.sessionId
                })
            };
            setStatoPagina(STATO_RICERCA_DATI);

            fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valoreDiRitorno) => {
                    //setRisultatoServer(valoreDiRitorno.risultatoRitorno);
                    if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                        alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                    }
                    if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                    if(valoreDiRitorno.risultatoOperazione===1){
                        
                    //setDataDefault(valoreDiRitorno.risultatoRitorno[0].DATA_CHIUSURA_ESERCIZIO);
                    setDataTesseramentiDefault(valoreDiRitorno.risultatoRitorno[0].DATA_CHIUSURA_TESSERAMENTI);
                    setAnnullamentoPrenotazioniDefault(valoreDiRitorno.risultatoRitorno[0].TEMPO_ANNULLAMENTO_PRENOTAZIONI);
                    setUnitaDefault(valoreDiRitorno.risultatoRitorno[0].UNITA_TEMPO_ANNULLAMENTO_PRENOTAZIONI);
                    setNomeDefault(valoreDiRitorno.risultatoRitorno[0].NOME);
                    setPivaDefault(valoreDiRitorno.risultatoRitorno[0].PIVA);
                    setCodiceFiscaleDefault(valoreDiRitorno.risultatoRitorno[0].CODICE_FISCALE);
                    setCapDefault(valoreDiRitorno.risultatoRitorno[0].CAP);
                    setIndirizzoDefault(valoreDiRitorno.risultatoRitorno[0].INDIRIZZO);
                    setCittaDefault(valoreDiRitorno.risultatoRitorno[0].CITTA);
                    setProvinciaDefault(valoreDiRitorno.risultatoRitorno[0].PROVINCIA);
                    setComuneDefault(valoreDiRitorno.risultatoRitorno[0].COMUNE);
                    setPecDefault(valoreDiRitorno.risultatoRitorno[0].PEC);
                    setTelefonoDefault(valoreDiRitorno.risultatoRitorno[0].TELEFONO);
                    setRegimeFiscaleDefault(valoreDiRitorno.risultatoRitorno[0].REGIME_FISCALE);
                    setReaUfficioDefault(valoreDiRitorno.risultatoRitorno[0].REA_UFFICIO);
                    setReaNumeroDefault(valoreDiRitorno.risultatoRitorno[0].REA_NUMERO);
                    setCapitaleSocialeDefault(valoreDiRitorno.risultatoRitorno[0].REA_CAPITALESOCIALE);
                    setSocioUnicoDefault(valoreDiRitorno.risultatoRitorno[0].REA_SOCIOUNICO);
                    setStatoLiquidazioneDefault(valoreDiRitorno.risultatoRitorno[0].REA_STATOLIQUIDAZIONE);

                    setHostDefault(valoreDiRitorno.risultatoRitorno[0].HOST);
                    setPortDefault(valoreDiRitorno.risultatoRitorno[0].PORT);
                    setSenderNameDefault(valoreDiRitorno.risultatoRitorno[0].SENDER_NAME);
                    setSenderMailDefault(valoreDiRitorno.risultatoRitorno[0].SENDER_MAIL);
                    setUserDefault(valoreDiRitorno.risultatoRitorno[0].USER);
                    setPassDefault(valoreDiRitorno.risultatoRitorno[0].PASS);
                    setTipoEncDefault(valoreDiRitorno.risultatoRitorno[0].TIPO_ENC);
                    setAuthDefault(valoreDiRitorno.risultatoRitorno[0].AUTH);
                    setAutotlsDefault(valoreDiRitorno.risultatoRitorno[0].AUTOTLS);

                    //setLogoDefault(valoreDiRitorno.risultatoRitorno[0].LOGO);
                    setContenutoFile(valoreDiRitorno.risultatoRitorno[0].FILE);
                    setTypeFotoDefault(valoreDiRitorno.risultatoRitorno[0].TYPE);
                    setEstensioneFileFoto(valoreDiRitorno.risultatoRitorno[0].ESTENSIONE_FOTO);
                    setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                    }
                    },
                (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            ); 
        }
    },[statoPagina]);

    useEffect(() => { 
        if(flagEliminaFoto === 'on') setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
    },[flagEliminaFoto]);

    function scaricaFile(nomeFile, contenuto) {
        const link = document.createElement('a');
        link.href = contenuto;
        link.setAttribute('download',nomeFile);     
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();      
        // Clean up and remove the link
        link.parentNode.removeChild(link);
    }

    function tornaScheda() {
        setFlagModaleInfoFoto(false);
        setFlagModaleConfermaCancFoto(false);
        //setFlagAttivaModaleRichiestaDataChiusuraEsercizio(0);
        setFlagAttivaModaleRichiestaDataChiusuraTesseramenti(0);
        setFlagAttivaModaleAnnullamentoPrenotazioniErrato(0);
        setFlagAttivaModaleDimensioneFoto(0);
        setFlagAttivaModaleInfoAnnullamantoPrenotazioni(0);
        setFineSpazio(0);
    }

    function attivaModaleInfoAnnullamantoPrenotazioni(){
        setFlagAttivaModaleInfoAnnullamantoPrenotazioni(1);
    }

    function handleCambiamentoMod(event) {
        //event.preventDefault();
        setVariazioneCampi(1);
    }

    function aggiornaFile(valore){
        setVariazioneCampi(1);
        let typeFile = '';
        if (valore.type.substring(0, 5) === "image") {
            var nuovoTipo = valore.type.substring(6);
            if (nuovoTipo.toUpperCase()!="JPG" && nuovoTipo.toUpperCase()!="JPEG" && nuovoTipo.toUpperCase()!="PNG") {
                nuovoTipo = "JPEG";
                typeFile = "image/jpeg";
            }else{
                typeFile = valore.type;
            }

            try {
                Resizer.imageFileResizer(
                    valore,
                    600,
                    600,
                    nuovoTipo,
                    100,
                    0,
                    (uri) => {
                        setContenutoFile(uri);
                    },
                    "base64"
                );                
            } catch (err) {
                // console.log(err);
                alert("Si è verificato un errore nel ridimensionamento dell'immagine, contattare l'assistenza tecnica");
            }
        } else {            
            setContenutoFile(valore)
        }
        const estensione = valore.name.split('.').pop();
        setFlagFotoModificata(true);
        setFlagEliminaFoto("");
        setTypeFotoDefault(typeFile);
        setEstensioneFileFoto(estensione);
    }

    {/*function aggiornaFile(valore){
        setVariazioneCampi(1);
        setContenutoFile(valore);
        setFotoDefault("");
        setTypeFotoDefault('');
        setFlagEliminaFoto("");
        setAnteprima(URL.createObjectURL(valore));
        setTypeAnteprima(valore.type);
    }*/}
    function funzioneEliminaFoto(valore){
        setFlagEliminaFoto(valore);
        setTypeFotoDefault('');
        setContenutoFile('');
        setStatoPagina(STATO_MEMORIZZAZIONE);
    }

    function inviaMailTest() {
        const destinatarioTest = window.prompt("Inserisci l'indirizzo email cui inviare la mail di test:");        
        if (destinatarioTest && destinatarioTest!="") {
            const requestOptions = {
                method: 'POST',
                body: JSON.stringify({op:"mailtest",
                    destinatarioTest:destinatarioTest,
                    emak: props.emak,
                    sessionId:props.sessionId
                })
            };
            fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                .then(risposta => risposta.json())
                .then(
                (valoreDiRitorno) => {
                    setLoading(false);
                    if(valoreDiRitorno.risultatoOperazione===1){
                        alert("Richiesta di invio andata a buon fine");
                    } else {
                        alert("MAIL NON INVIATA, verifica i parametri");
                    }
                }
            )
        }
    }
    
    function fetchOperazioneServer(bypassaControlli=0) {
        //invio l'azione da fare sul server (aggiungi/togli) e l'elemento in esame al server che mi ritornerà l'elenco aggiornato
        
        let controlli=0;

        if(bypassaControlli===0){
            /*if(data.current.value=== ''){
                controlli = 1;
                setFlagAttivaModaleRichiestaDataChiusuraEsercizio(1);
            }*/
            if(dataTesseramenti.current.value=== ''){
                controlli = 1;
                setFlagAttivaModaleRichiestaDataChiusuraTesseramenti(1);
            }
        }
        
        if(controlli===0){ 
            if(variazioneCampi===0){
                //in relatà non e stata fatta alcuna variazione nei campi originali per cui torno ad elenco:
                //tornaElenco();
                tornaScheda();
            }else{

                // parametri per inviare i dati al server:
                setLoading(true);

                if (flagFotoModificata || flagEliminaFoto==1) {
                    props.setFlagAggiornaFileLogo(1);
                }

                const formData = new FormData();
                
                    formData.append('op', "impostazioniSocietaMod");
                    //formData.append('data',data.current.value);
                    formData.append('dataTesseramenti',dataTesseramenti.current.value);
                    formData.append('annullamentoPrenotazioni',FormattaNumeroDb(annullamentoPrenotazioni.current.value));
                    formData.append('unita',unitaDefault);
                    formData.append('nome',nome.current.value);
                    formData.append('piva',piva.current.value);
                    formData.append('codiceFiscale',codiceFiscale.current.value);
                    formData.append('cap',cap.current.value);
                    formData.append('indirizzo',indirizzo.current.value);
                    formData.append('citta',citta.current.value);
                    formData.append('provincia',provincia.current.value);
                    formData.append('comune',comune.current.value);
                    formData.append('pec',pec.current.value);
                    formData.append('telefono',telefono.current.value);
                    formData.append('REGIME_FISCALE',regimeFiscaleDefault);
                    formData.append('REA_UFFICIO',reaUfficio.current.value);
                    formData.append('REA_NUMERO',reaNumero.current.value);
                    formData.append('REA_CAPITALESOCIALE',capitaleSociale.current.value);
                    formData.append('REA_SOCIOUNICO',socioUnicoDefault);
                    formData.append('REA_STATOLIQUIDAZIONE',statoLiquidazioneDefault);

                    formData.append('HOST',host.current.value);
                    formData.append('PORT',port.current.value);
                    formData.append('SENDER_NAME',senderName.current.value);
                    formData.append('SENDER_MAIL',senderMail.current.value);
                    formData.append('USER',user.current.value);
                    formData.append('PASS',pass.current.value);
                    formData.append('TIPO_ENC',tipoEnc.current.value);
                    formData.append('AUTH',auth.current.value);
                    formData.append('AUTOTLS',autotls.current.value);

                    if (flagFotoModificata) {
                        formData.append('contenutoFileFoto', contenutoFile);
                        formData.append('estensioneFileFoto', estensioneFileFoto);
                        formData.append('typeFileFoto', typeFotoDefault);                        
                    }
                    formData.append('flagEliminaFoto', flagEliminaFoto);
                    
                    formData.append('emak', props.emak);
                    formData.append('sessionId', props.sessionId);
                

                const requestOptions = {
                    method: 'POST',
                    body: formData
                }
                
                // qui faccio la chiamata alla pagina sul server, quindi ne leggo la risposta che io trasformo in oggetto json 
                // con .json() ottenendo "valore_di_ritorno": in questo oggetto json ho "risultato_ritorno" che metto nella 
                // variabile di stato "risultato_server"
                //setStatoPagina(STATO_MEMORIZZAZIONE);

                fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                    .then(risposta => risposta.json())
                    .then(
                    (valoreDiRitorno) => {
                        setLoading(false);
                        if(valoreDiRitorno.risultatoOperazione===1){
                            setDisabilitaBottoni(false);
                            setVariazioneCampi(0);
                            tornaScheda();
                            //tornaElenco();
                        } 
                        if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                        if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                            if(valoreDiRitorno.mess !== undefined){
                                /*if(valoreDiRitorno.mess.indexOf("Impostazione priva di data chiusura esercizio") !== -1){
                                    setFlagAttivaModaleRichiestaDataChiusuraEsercizio(1);
                                }*/   
                                if(valoreDiRitorno.mess.indexOf("Impostazione priva di data chiusura tesseramenti") !== -1){
                                    setFlagAttivaModaleRichiestaDataChiusuraTesseramenti(1);
                                }  
                                if(valoreDiRitorno.mess.indexOf("annullamentoPrenotazioni errato") !== -1){
                                    setFlagAttivaModaleAnnullamentoPrenotazioniErrato(1);
                                } 
                                if(valoreDiRitorno.mess.indexOf("Dimensione foto logo troppo elevata") !== -1){
             
                                    setFlagAttivaModaleDimensioneFoto(1);
                                    setDimensioneFoto(valoreDiRitorno.dimFoto);
                                    setDimensioneMassimaFoto(valoreDiRitorno.dimMaxFoto);
                                    setTypeFotoDefault('');
                                    setContenutoFile('');
                                }
                                if(valoreDiRitorno.mess.indexOf("Spazio Terminato") !== -1){
                                    setFineSpazio(1);
                                }
                            }else{
                                //altrimenti vado in errore generico
                                setStatoPagina(STATO_OPERAZIONE_FALLITA);
                            }
                        } 
                    },
                    (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
                );
            }
        }
    }

    function aggiornaImpostazioniLayoutTessere(usaValoriDefault){
        if(usaValoriDefault===1)setStatoPagina(STATO_MEMORIZZAZIONE);
        //vado a fare la fetch di aggiornamento delle impostazioni del report per la persona in esame:
        const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          op:"impostazioniLayoutTessere",
          modificaImpostazioni : 1,
          usaValoriDefault : usaValoriDefault,
          logoSocieta:arrayLayoutTessere[0],
          posizioneLogoSocieta:arrayLayoutTessere[1],
          indirizzoSocieta:arrayLayoutTessere[3],
          dataNascitaIscritto:arrayLayoutTessere[5],
          genereIscritto:arrayLayoutTessere[6],
          raggruppamentiIscritto:arrayLayoutTessere[7],
          numeroTessera:arrayLayoutTessere[8],
          posizioniSocialiIscritto:arrayLayoutTessere[9],
          fotoIscritto:arrayLayoutTessere[10],
          posizioneFotoIscritto:arrayLayoutTessere[11],
          dataRilascioTessera:arrayLayoutTessere[12],
          coloreSfondo:arrayLayoutTessere[13],
          bordoArrotondato:arrayLayoutTessere[14],
          eliminaIntestazione:arrayLayoutTessere[15],
          coloreTesto:arrayLayoutTessere[16],
          emak: props.emak,
          sessionId: props.sessionId})
      };

      fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
      .then(risposta => risposta.json())
      .then(
      (valoreDiRitorno) => {         
          if(valoreDiRitorno.risultatoOperazione===1 && valoreDiRitorno.risultatoRitorno){
            let arrLocale=[];
            arrLocale[0]=parseInt(valoreDiRitorno.risultatoRitorno[0].LOGO_SOCIETA);
            arrLocale[1]=parseInt(valoreDiRitorno.risultatoRitorno[0].POSIZIONE_LOGO_SOCIETA);
            arrLocale[2]=parseInt(valoreDiRitorno.risultatoRitorno[0].DENOMINAZIONE_SOCIETA);
            arrLocale[3]=parseInt(valoreDiRitorno.risultatoRitorno[0].INDIRIZZO_SOCIETA);
            arrLocale[4]=parseInt(valoreDiRitorno.risultatoRitorno[0].COGNOME_NOME_ISCRITTO);
            arrLocale[5]=parseInt(valoreDiRitorno.risultatoRitorno[0].DATA_NASCITA_ISCRITTO);
            arrLocale[6]=parseInt(valoreDiRitorno.risultatoRitorno[0].GENERE_ISCRITTO);
            arrLocale[7]=parseInt(valoreDiRitorno.risultatoRitorno[0].RAGGRUPPAMENTI_ISCRITTO);
            arrLocale[8]=parseInt(valoreDiRitorno.risultatoRitorno[0].NUMERO_TESSERA);
            arrLocale[9]=parseInt(valoreDiRitorno.risultatoRitorno[0].POSIZIONI_SOCIALI_ISCRITTO);
            arrLocale[10]=parseInt(valoreDiRitorno.risultatoRitorno[0].FOTO_ISCRITTO);
            arrLocale[11]=parseInt(valoreDiRitorno.risultatoRitorno[0].POSIZIONE_FOTO_ISCRITTO);
            arrLocale[12]=parseInt(valoreDiRitorno.risultatoRitorno[0].DATA_RILASCIO_TESSERA);
            arrLocale[13]=valoreDiRitorno.risultatoRitorno[0].COLORE_SFONDO;
            arrLocale[14]=parseInt(valoreDiRitorno.risultatoRitorno[0].BORDO_ARROTONDATO);
            arrLocale[15]=parseInt(valoreDiRitorno.risultatoRitorno[0].ELIMINA_INTESTAZIONE);
            arrLocale[16]=valoreDiRitorno.risultatoRitorno[0].COLORE_TESTO;
            setArrayLayoutTessere(arrLocale);
            if(usaValoriDefault!==1)setModaleImpostazioniLayoutTessere(false);
            if(usaValoriDefault===1)setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
            setVariazioneCampiLayoutTessere(0);
          }
      },
      (error) => {console.log("Errore connessione");}
    );

    }

    function ricercaLayoutTessere(){
        //vado a fare la fetch di ricerca delle impostazioni del layout tessere:
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
            op:"impostazioniLayoutTessere",
            ricercaImpostazioni : 1,
            emak: props.emak,
            sessionId: props.sessionId})
        };

        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
        .then(risposta => risposta.json())
        .then(
        (valoreDiRitorno) => {          
            
            if(valoreDiRitorno.risultatoOperazione===1){
                let arrLocale=[];
                arrLocale[0]=parseInt(valoreDiRitorno.risultatoRitorno[0].LOGO_SOCIETA);
                arrLocale[1]=parseInt(valoreDiRitorno.risultatoRitorno[0].POSIZIONE_LOGO_SOCIETA);
                arrLocale[2]=parseInt(valoreDiRitorno.risultatoRitorno[0].DENOMINAZIONE_SOCIETA);
                arrLocale[3]=parseInt(valoreDiRitorno.risultatoRitorno[0].INDIRIZZO_SOCIETA);
                arrLocale[4]=parseInt(valoreDiRitorno.risultatoRitorno[0].COGNOME_NOME_ISCRITTO);
                arrLocale[5]=parseInt(valoreDiRitorno.risultatoRitorno[0].DATA_NASCITA_ISCRITTO);
                arrLocale[6]=parseInt(valoreDiRitorno.risultatoRitorno[0].GENERE_ISCRITTO);
                arrLocale[7]=parseInt(valoreDiRitorno.risultatoRitorno[0].RAGGRUPPAMENTI_ISCRITTO);
                arrLocale[8]=parseInt(valoreDiRitorno.risultatoRitorno[0].NUMERO_TESSERA);
                arrLocale[9]=parseInt(valoreDiRitorno.risultatoRitorno[0].POSIZIONI_SOCIALI_ISCRITTO);
                arrLocale[10]=parseInt(valoreDiRitorno.risultatoRitorno[0].FOTO_ISCRITTO);
                arrLocale[11]=parseInt(valoreDiRitorno.risultatoRitorno[0].POSIZIONE_FOTO_ISCRITTO);
                arrLocale[12]=parseInt(valoreDiRitorno.risultatoRitorno[0].DATA_RILASCIO_TESSERA);
                arrLocale[13]=valoreDiRitorno.risultatoRitorno[0].COLORE_SFONDO;
                arrLocale[14]=parseInt(valoreDiRitorno.risultatoRitorno[0].BORDO_ARROTONDATO);
                arrLocale[15]=parseInt(valoreDiRitorno.risultatoRitorno[0].ELIMINA_INTESTAZIONE);
                arrLocale[16]=valoreDiRitorno.risultatoRitorno[0].COLORE_TESTO;
                setArrayLayoutTessere(arrLocale);
                setModaleImpostazioniLayoutTessere(true);
            }
        },
        (error) => {console.log("Errore connessione");}
        ); 
    }

    function cambiaArrayLayoutTessere(posizione,valore){
        console.log("pos"+posizione);
        console.log("val"+valore);
        console.log("lul"+indirizzoDefault.length);
        let prosegui = 1;
        
        if(posizione === 3 && valore === 1){
            //controllo che il campo indirizzo non superi i 40 caratteri (in accordo con le impostazioni in StampaTessera.js) altrimenti avviso:
            if(indirizzoDefault.length + cittaDefault.length + 1>40){
                setModaleImpostazioniLayoutTessere(false);
                setModaleIndirizzoLungo(true);
                prosegui = 0;
            }
        }
        if(prosegui === 1){
            let dati = [...arrayLayoutTessere];//popolo l'array locale con i dati dell'array di stato
            dati.splice(posizione,1,valore);//modifico l'elemento desiderato
            setArrayLayoutTessere(dati);//aggiorno nuovamente la var di stato
        }
    }

    //JSON.stringify(risultatoServer)
        return (
        <Fragment>
 
        
            <div className="app-main__outer">
                <div className="app-main__inner">
                    
            <Fragment>
               
                {/*eccola {props.sessionId}*/}
            
                {statoPagina === STATO_MEMORIZZAZIONE ?  <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}
                {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1ImpSoc {error.message}</div> :""}
                {statoPagina === STATO_OPERAZIONE_FALLITA ? <div>Errore per operazione errata</div> :""}
                {statoPagina === STATO_RICERCA_DATI ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}

                {statoPagina === STATO_RICERCA_DATI_CORRETTA ?
                <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>

                    {/*{flagAttivaModaleRichiestaDataChiusuraEsercizio===1 ?
                        <Modale 
                            titolo="Richiesta data chiusura esercizio"
                            flagErrore={true}
                            contenuto={<div>Non è stata indicata la data chiusura esercizio, che è necessaria</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                        :""}*/}

                    {fineSpazio === 1 ?
                        <Modale 
                        titolo="Informativa spazio terminato"
                        flagErrore={true}
                        contenuto={<div>Lo spazio dati è terminato</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                            }    
                        ]}
                    />
                    :""}

                    {modaleIndirizzoLungo===true ?
                        <Modale 
                            titolo="Indirizzo non inseribile nelle tessere"
                            flagErrore={true}
                            contenuto={<div>L'indirizzo nelle tessere, composto dall'unione dei campi 'Indirizzo' e 'Citta', risulta troppo lungo</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setModaleIndirizzoLungo(false);setModaleImpostazioniLayoutTessere(true);}
                                }    
                            ]}
                        />
                    :""}


                    {modaleImpostazioniLayoutTessere === true ?
                        <Modale 
                        larghezza = "lg"
                        titolo="Impostazioni layout tessere sociali"
                        flagErrore={false}
                        bottoni={[
                            {
                                "etichetta":"Resetta Impostazioni",
                                "tipo":"warning",
                                callback:() => {aggiornaImpostazioniLayoutTessere(1)}
                            },   
                            
                            {
                                "etichetta":"Memorizza Nuove Impostazioni",
                                "tipo":"danger",
                                callback:() => {aggiornaImpostazioniLayoutTessere(0)}
                            }, 
                            {
                                "etichetta":"Annulla",
                                "tipo":"primary",
                                callback:() => {setModaleImpostazioniLayoutTessere(false);}
                            },
                        ]}
                        contenuto={<div>
                            <CardBody>
                            <Row style={{marginLeft:20,marginRight:20,backgroundColor : "#99cbff"}} className="border"> 
                            <Col><b><center>Oggetto</center></b></Col>
                            <Col><b><center>Presenza</center></b></Col>
                            <Col><b><center>Posizione</center></b></Col>
                            </Row>
                            <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col>Logo Società</Col>
                                <Col><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaLogo" 
                                        defaultChecked = {arrayLayoutTessere[0] === 1 ?  'checked' :""}                               
                                        placeholder=""
                                        onClick={(e)=>{arrayLayoutTessere[0] === 1 ? cambiaArrayLayoutTessere(0,0) : cambiaArrayLayoutTessere(0,1);setVariazioneCampiLayoutTessere(1)}}/></center>
                                </Col>
                                <Col>
                                    <center><select style={{marginTop:1}} name="posizioneLogo" id="posizioneLogo" innerRef={posizioneLogo} onChange={(e)=>{cambiaArrayLayoutTessere(1,e.target.value);setVariazioneCampiLayoutTessere(1)}}>
                                    {arrayLayoutTessere[1] === 0 ? <option selected value="0">Sinistra</option> : <option value="0">Sinistra</option>}
                                    {arrayLayoutTessere[1] === 1 ? <option selected value="1">Destra</option> : <option value="1">Destra</option>}
                                    </select></center> 
                                </Col>
                            </Row>
                            <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col>Indirizzo Società</Col>
                                <Col><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaLogo" 
                                        defaultChecked = {arrayLayoutTessere[3] === 1 ?  'checked' :""}                               
                                        placeholder=""
                                        onClick={(e)=>{arrayLayoutTessere[3] === 1 ? cambiaArrayLayoutTessere(3,0) : cambiaArrayLayoutTessere(3,1);setVariazioneCampiLayoutTessere(1)}}/></center>
                                </Col>
                                <Col></Col>
                            </Row>
                            <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col>Data di Nascita Iscritto</Col>
                                <Col><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaLogo" 
                                        defaultChecked = {arrayLayoutTessere[5] === 1 ?  'checked' :""}                               
                                        placeholder=""
                                        onClick={(e)=>{arrayLayoutTessere[5] === 1 ? cambiaArrayLayoutTessere(5,0) : cambiaArrayLayoutTessere(5,1);setVariazioneCampiLayoutTessere(1)}}/></center>
                                </Col>
                                <Col></Col>
                            </Row>
                            <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col>Genere Iscritto</Col>
                                <Col><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaLogo" 
                                        defaultChecked = {arrayLayoutTessere[6] === 1 ?  'checked' :""}                               
                                        placeholder=""
                                        onClick={(e)=>{arrayLayoutTessere[6] === 1 ? cambiaArrayLayoutTessere(6,0) : cambiaArrayLayoutTessere(6,1);setVariazioneCampiLayoutTessere(1)}}/></center>
                                </Col>
                                <Col></Col>
                            </Row>
                            <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col>Raggruppamenti Iscritto</Col>
                                <Col><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaLogo" 
                                        defaultChecked = {arrayLayoutTessere[7] === 1 ?  'checked' :""}                               
                                        placeholder=""
                                        onClick={(e)=>{arrayLayoutTessere[7] === 1 ? cambiaArrayLayoutTessere(7,0) : cambiaArrayLayoutTessere(7,1);setVariazioneCampiLayoutTessere(1)}}/></center>
                                </Col>
                                <Col></Col>
                            </Row>
                            <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col>Numero Tessera</Col>
                                <Col><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaLogo" 
                                        defaultChecked = {arrayLayoutTessere[8] === 1 ?  'checked' :""}                               
                                        placeholder=""
                                        onClick={(e)=>{arrayLayoutTessere[8] === 1 ? cambiaArrayLayoutTessere(8,0) : cambiaArrayLayoutTessere(8,1);setVariazioneCampiLayoutTessere(1)}}/></center>
                                </Col>
                                <Col></Col>
                            </Row>
                            <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col>Posizioni Sociali Iscritto</Col>
                                <Col><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaLogo" 
                                        defaultChecked = {arrayLayoutTessere[9] === 1 ?  'checked' :""}                               
                                        placeholder=""
                                        onClick={(e)=>{arrayLayoutTessere[9] === 1 ? cambiaArrayLayoutTessere(9,0) : cambiaArrayLayoutTessere(9,1);setVariazioneCampiLayoutTessere(1)}}/></center>
                                </Col>
                                <Col></Col>
                            </Row>
                            <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col>Foto Iscritto</Col>
                                <Col><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaLogo" 
                                        defaultChecked = {arrayLayoutTessere[10] === 1 ?  'checked' :""}                               
                                        placeholder=""
                                        onClick={(e)=>{arrayLayoutTessere[10] === 1 ? cambiaArrayLayoutTessere(10,0) : cambiaArrayLayoutTessere(10,1);setVariazioneCampiLayoutTessere(1)}}/></center>
                                </Col>
                                <Col>
                                    <center><select style={{marginTop:1}} name="posizioneFoto" id="posizioneFoto" innerRef={posizioneFoto} onChange={(e)=>{cambiaArrayLayoutTessere(11,e.target.value);setVariazioneCampiLayoutTessere(1)}}>
                                    {arrayLayoutTessere[11] === 0 ? <option selected value="0">Sinistra</option> : <option value="0">Sinistra</option>}
                                    {arrayLayoutTessere[11] === 1 ? <option selected value="1">Destra</option> : <option value="1">Destra</option>}
                                    </select></center> 
                                </Col>
                            </Row>
                            <Row style={{marginLeft:20,marginRight:20}} className="border">
                                <Col>Data di Rilascio</Col>
                                <Col><center><Input className="ml-2 mt-2" type="checkbox" name="visualizzaLogo" 
                                        defaultChecked = {arrayLayoutTessere[12] === 1 ?  'checked' :""}                               
                                        placeholder=""
                                        onClick={(e)=>{arrayLayoutTessere[12] === 1 ? cambiaArrayLayoutTessere(12,0) : cambiaArrayLayoutTessere(12,1);setVariazioneCampiLayoutTessere(1)}}/></center>
                                </Col>
                                <Col></Col>
                            </Row>
                            </CardBody>
                            <br></br>
                            <CardBody>
                            <Row style={{marginLeft:20,marginRight:20}} className="border">   
                            <Col><b><center>Colore dello Sfondo della Tessera</center></b></Col>
                            <Col style={{marginLeft:65}}>
                                <input type="color" id="coloreSfondo" name="coloreSfondo" value={arrayLayoutTessere[13]} onChange={(e)=>{cambiaArrayLayoutTessere(13,e.target.value);setVariazioneCampiLayoutTessere(1)}} />
                            </Col>
                            </Row>


                            {/*<Row style={{marginLeft:20,marginRight:20}} className="border">   
                            <Col><b><center>Colore dello Sfondo della Tessera</center></b></Col>
                            <Col>
                                <left><select style={{marginTop:1}} name="coloreSfondo" id="coloreSfondo" innerRef={coloreSfondo} onChange={(e)=>{cambiaArrayLayoutTessere(13,e.target.value);setVariazioneCampiLayoutTessere(1)}}>
                                {arrayLayoutTessere[13] === 0 ? <option selected value="0">Bianco</option> : <option value="0">Bianco</option>}
                                {arrayLayoutTessere[13] === 1 ? <option selected value="1">Celeste</option> : <option value="1">Celeste</option>}
                                {arrayLayoutTessere[13] === 2 ? <option selected value="2">Azzurro</option> : <option value="2">Azzurro</option>}
                                {arrayLayoutTessere[13] === 3 ? <option selected value="3">Giallo</option> : <option value="3">Giallo</option>}
                                {arrayLayoutTessere[13] === 4 ? <option selected value="4">Arancione</option> : <option value="4">Arancione</option>}
                                {arrayLayoutTessere[13] === 5 ? <option selected value="5">Rosa</option> : <option value="5">Rosa</option>}
                                {arrayLayoutTessere[13] === 6 ? <option selected value="6">Grigio Chiaro</option> : <option value="6">Grigio Chiaro</option>}
                                {arrayLayoutTessere[13] === 7 ? <option selected value="7">Grigio</option> : <option value="7">Grigio</option>}
                                {arrayLayoutTessere[13] === 8 ? <option selected value="8">Verde Chiaro</option> : <option value="8">Verde Chiaro</option>}
                                {arrayLayoutTessere[13] === 9 ? <option selected value="9">Verde</option> : <option value="9">Verde</option>}
                                </select></left> 
                            </Col>
                            </Row>*/}

                            <Row style={{marginLeft:20,marginRight:20}} className="border">   
                            <Col><b><center>Colore del Testo della Tessera</center></b></Col>
                            <Col style={{marginLeft:65}}>
                                <input type="color" id="coloreTesto" name="coloreTesto" value={arrayLayoutTessere[16]} onChange={(e)=>{cambiaArrayLayoutTessere(16,e.target.value);setVariazioneCampiLayoutTessere(1)}} />
                            </Col>
                            </Row>

                            <Row style={{marginLeft:20,marginRight:20}} className="border">   
                            <Col><b><center>Elimina Intestazione dal Foglio di Stampa della Tessera</center></b></Col>
                            <Col>
                                <center><Input className="ml-2 mt-2" type="checkbox" name="eliminaIntestazione" 
                                        defaultChecked = {arrayLayoutTessere[15] === 1 ?  'checked' :""}                               
                                        placeholder=""
                                        onClick={(e)=>{arrayLayoutTessere[15] === 1 ? cambiaArrayLayoutTessere(15,0) : cambiaArrayLayoutTessere(15,1);setVariazioneCampiLayoutTessere(1)}}/></center>
                            </Col>
                            </Row>

                            <Row style={{marginLeft:20,marginRight:20}} className="border">   
                            <Col><b><center>Tipo di Bordo della Tessera</center></b></Col>
                            <Col>
                                <left><select style={{marginTop:1}} name="bordoArrotondato" id="bordoArrotondato" innerRef={bordoArrotondato} onChange={(e)=>{cambiaArrayLayoutTessere(14,e.target.value);setVariazioneCampiLayoutTessere(1)}}>
                                {arrayLayoutTessere[14] === 0 ? <option selected value="0">Squadrato</option> : <option value="0">Squadrato</option>}
                                {arrayLayoutTessere[14] === 1 ? <option selected value="1">Leggermente Arrotondato</option> : <option value="1">Leggermente Arrotondato</option>}
                                {arrayLayoutTessere[14] === 2 ? <option selected value="2">Arrotondato</option> : <option value="2">Arrotondato</option>}
                                </select></left> 
                            </Col>
                            </Row>

                            
                            </CardBody>
                            </div>
                        }
                        ></Modale>
                    :""}

                    {flagAttivaModaleRichiestaDataChiusuraTesseramenti===1 ?
                        <Modale 
                            titolo="Richiesta data chiusura tesseramenti"
                            flagErrore={true}
                            contenuto={<div>Non è stata indicata la data chiusura tesseramenti per Federazioni / Enti di Tesseramento, che è necessaria</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModaleAnnullamentoPrenotazioniErrato===1 ?
                        <Modale 
                            titolo="Informativa tempo annullamanto prenotazioni errato"
                            flagErrore={true}
                            contenuto={<div>Il campo tempo di annullamanto prenotazioni deve essere valorizzato con un numero intero oppure lasciato vuoto: valorizzarlo in modo corretto per poter attuare la modifica</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModaleDimensioneFoto === 1 ?
                        <Modale 
                            titolo="Dimensione eccessiva logo"
                            flagErrore={true}
                            contenuto={<div>Il logo ha una dimensione troppo elevata:<br/>rilevata {dimensioneFoto} KB, massima accettata {dimensioneMassimaFoto} KB</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModaleInfoAnnullamantoPrenotazioni===1 ?
                        <Modale 
                            titolo="Informativa tempo annullamento prenotazioni"
                            flagErrore={true}
                            contenuto={<div>Il Tempo di Annullamento Prenotazioni indica il periodo antecedente la data di prenotazione nel quale non sarà più possibile effettuare una disdetta della prenotazione</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""}

                    {flagModaleInfoFoto === true ?
                        <Modale 
                            titolo="Logo Società"
                            flagErrore={false}
                            contenuto="Il logo può essere inserito in formato: jpg, jpeg, gif, png, webp"
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""}

                    {flagModaleConfermaCancFoto === true ?
                    <Modale 
                        titolo="Conferma eliminazione"
                        flagErrore={false}
                        contenuto="Confermi l'eliminazione del logo?"
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setFlagModaleConfermaCancFoto(false); funzioneEliminaFoto("on")}
                            },
                            {
                                "etichetta":"Annulla",
                                "tipo":"primary",
                                callback: () => {setDisabilitaBottoni(false);tornaScheda()}
                            }
                        ]}
                    />
                    :""}

                    <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}>
                            
                            <CardTitle>Modifica Impostazioni Società</CardTitle>
                            
                            
                            <Clessidra loading={loading}/>
                                <Row form>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="nome">Denominazione</Label>
                                            
                                                <Input maxLength={255} type="text" name="nome" id="nome"
                                                innerRef={nome} 
                                                placeholder=""
                                                defaultValue={nomeDefault}
                                                onInput={(e)=>{handleCambiamentoMod(e);setNomeDefault(nome.current.value)}}/>  
                                            
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row form>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="piva">Partita IVA</Label>
                                           
                                                <Input maxLength={30} type="text" name="piva" id="piva"
                                                innerRef={piva} 
                                                placeholder=""
                                                defaultValue={pivaDefault}
                                                onInput={(e)=>{handleCambiamentoMod(e);setPivaDefault(piva.current.value)}}/>  
                                            
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row form>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="codiceFiscale">Codice Fiscale</Label>
                                           
                                                <Input maxLength={30} type="text" name="codiceFiscale" id="codiceFiscale"
                                                innerRef={codiceFiscale} 
                                                placeholder=""
                                                defaultValue={codiceFiscaleDefault}
                                                onInput={(e)=>{handleCambiamentoMod(e);setCodiceFiscaleDefault(codiceFiscale.current.value)}}/>  
                                            
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row form>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="indirizzo">Indirizzo</Label>
                                            
                                                <Input maxLength={255} type="text" name="indirizzo" id="indirizzo"
                                                innerRef={indirizzo} 
                                                placeholder=""
                                                defaultValue={indirizzoDefault}
                                                onInput={(e)=>{handleCambiamentoMod(e);setIndirizzoDefault(indirizzo.current.value)}}/>  
                                            
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row form>
                                    <Col md={9}>
                                        <Row form>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label for="citta">Città</Label>
                                                    
                                                        <Input maxLength={100} type="text" name="citta" id="citta"
                                                        innerRef={citta} 
                                                        placeholder=""
                                                        defaultValue={cittaDefault}
                                                        onInput={(e)=>{handleCambiamentoMod(e);setCittaDefault(citta.current.value)}}/>  
                                                    
                                                </FormGroup>
                                            </Col>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label for="provincia">Provincia (2 caratteri)</Label>
                                                    
                                                        <Input maxLength={50} type="text" name="provincia" id="provincia"
                                                        innerRef={provincia} 
                                                        placeholder=""
                                                        defaultValue={provinciaDefault}
                                                        onInput={(e)=>{handleCambiamentoMod(e);setProvinciaDefault(provincia.current.value)}}/>  
                                                    
                                                </FormGroup>
                                            </Col>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label for="comune">Comune</Label>
                                                    
                                                        <Input maxLength={50} type="text" name="comune" id="comune"
                                                        innerRef={comune} 
                                                        placeholder=""
                                                        defaultValue={comuneDefault}
                                                        onInput={(e)=>{handleCambiamentoMod(e);setComuneDefault(comune.current.value)}}/>  
                                                    
                                                </FormGroup>
                                            </Col>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label for="cap">CAP</Label>
                                                    
                                                        <Input maxLength={50} type="text" name="cap" id="cap"
                                                        innerRef={cap} 
                                                        placeholder=""
                                                        defaultValue={capDefault}
                                                        onInput={(e)=>{handleCambiamentoMod(e);setCapDefault(cap.current.value)}}/>  
                                                    
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                
                                        <Row form>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label for="pec">Pec</Label>
                                                    
                                                        <Input maxLength={255} type="text" name="pec" id="pec"
                                                        innerRef={pec} 
                                                        placeholder=""
                                                        defaultValue={pecDefault}
                                                        onInput={(e)=>{handleCambiamentoMod(e);setPecDefault(pec.current.value)}}/>  
                                                    
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label for="telefono">Telefono</Label>
                                                    
                                                        <Input maxLength={50} type="text" name="telefono" id="telefono"
                                                        innerRef={telefono} 
                                                        placeholder=""
                                                        defaultValue={telefonoDefault}
                                                        onInput={(e)=>{handleCambiamentoMod(e);setTelefonoDefault(telefono.current.value)}}/>  
                                                    
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        {/*<Row form>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label for="data">Data Chiusura Esercizio</Label>
                                                    
                                                        <Input maxLength={10} type="date" name="data" id="data"
                                                        innerRef={data}
                                                        placeholder="" 
                                                        defaultValue={dataDefault}
                                                        onInput={(e)=>{handleCambiamentoMod(e);setDataDefault(data.current.value)}}/> 
                                                        
                                                </FormGroup>
                                            </Col>
                                        </Row>*/}
                                        <Row form>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label for="data">Data Chiusura Tesseramenti per Federazioni / Enti di Tesseramento</Label>
                                                    
                                                        <Input maxLength={10} type="date" name="dataTesseramenti" id="dataTesseramenti"
                                                        innerRef={dataTesseramenti}
                                                        placeholder="" 
                                                        defaultValue={dataTesseramentiDefault}
                                                        onInput={(e)=>{handleCambiamentoMod(e);setDataTesseramentiDefault(dataTesseramenti.current.value)}}/> 
                                                        
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row form>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <Label for="annullamentoPrenotazioni">Tempo Annullamento Prenotazioni</Label>
                                                    <img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => attivaModaleInfoAnnullamantoPrenotazioni(true)}/>
                                 
                                                        <Input maxLength={11} type="text" name="annullamentoPrenotazioni" id="annullamentoPrenotazioni"
                                                        innerRef={annullamentoPrenotazioni} 
                                                        placeholder=""
                                                        defaultValue={annullamentoPrenotazioniDefault}
                                                        onInput={(e)=>{handleCambiamentoMod(e);setAnnullamentoPrenotazioniDefault(annullamentoPrenotazioni.current.value)}}/>  
                                                    
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                            <Label for="unita">Unità di misura &nbsp;</Label>
                                            <select className="inputStandard" name="unita" id="unita" innerRef={unita}  onInput={(e)=>{handleCambiamentoMod(e);setUnitaDefault(e.target.value)}}>
                                                {unitaDefault === '1' || unitaDefault === '' ? <option selected value="1">Minuti</option> : <option value="1">Minuti</option> }
                                                {unitaDefault === '2' ? <option selected value="2">Ore</option> : <option value="2">Ore</option> }
                                                {unitaDefault === '3' ? <option selected value="3">Giorni</option> : <option value="3">Giorni</option> }
                                            </select>
                                            </Col>
                                        </Row>
                                    </Col>  
                                    <Col md={3}>
                                        <Row form>
                                            <Col md={12}>
                                            <div class="wrapper">

                                            <div style={{paddingLeft:10}}> {/* div contenente foto*/}
                                                <Label for="foto">
                                                    Logo della Società
                                                    <img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setFlagModaleInfoFoto(true)}/>
                                                </Label>
                                                <VisualizzaAllegato
                                                    cssContenitore="btnimg"
                                                    cssImmagine="immagineAnagrafica"
                                                    cssPdf="pdfAnagrafica"
                                                    cssTestoEstensione="testoEstensioneAllegatoAnagrafica"
                                                    scalaPdf={props.dimensioniApp.x/3000}
                                                    contenutoFile={contenutoFile}
                                                    tipoFile={typeFotoDefault}
                                                    estensioneFile={estensioneFileFoto}
                                                    estensioniAccettate={".jpg,.jpeg,.gif,.png,.webp"}
                                                    flagContenutoModificato={flagFotoModificata}
                                                    callbackAggiornaFile={(e) => {handleCambiamentoMod(e); aggiornaFile(e.target.files[0]);}}
                                                    callbackScarica={() => { handleCambiamentoMod(); scaricaFile("Foto." + estensioneFileFoto,contenutoFile)}}
                                                    callbackElimina={() => { handleCambiamentoMod(); setFlagModaleConfermaCancFoto(true)}}
                                                /> 
                                            </div>
                                            
                                            <center><Button color="primary" className="mt-5" onClick={() => {ricercaLayoutTessere();}}>Modifica Layout Tessere Sociali</Button>
                                            </center>


                                                {/*}
                                                <Fragment>
                                                <Label for="foto">Logo della Società</Label>
                                                <div class="btnimg">
                                
                                                    {typeFotoDefault === "application/pdf" || typeFotoDefault === "text/plain" ? 
                                                        <embed className="immagineM" src={"data:" + typeFotoDefault + ";base64," + fileFotoDefault}  type={typeFotoDefault}/>  
                                                    :""}
                                                    {typeFotoDefault.substring(0, 5) === "image" ?
                                                        <img className="immagineM" src={"data:" + typeFotoDefault + ";base64," + fileFotoDefault} />
                                                    :""}
                                         
                                                    {typeAnteprima.substring(0, 5) === "image" ?
                                                        <img className="immagineM" src={anteprima}/>
                                                    :""}
                                                    {typeAnteprima === "application/pdf" || typeAnteprima === "text/plain" ? 
                                                        <embed className="immagineM" src={anteprima}/>
                                                    :""}
                                                </div>
                                                <Label for="foto">(formati ammessi: jpg, jpeg, gif, png, webp)</Label>
                                                <input type='file' className='bottone' accept=".jpg,.jpeg,.gif,.png,.webp" 
                                                    
                                                    onChange={(e) => {handleCambiamentoMod(e);aggiornaFile(e.target.files[0]);}}/>
                                                </Fragment>
                                                */}
                                            </div>
                                            </Col>
                                        </Row>
                                        {/*
                                        <Row form> 
                                            {fotoDefault !== "" ? 
                                                <Col md={6}>
                                                <a download={"Logo "} href={"data:" + typeFotoDefault + ";base64," + fileFotoDefault}>Scarica file</a>
                                                </Col>
                                            :""}
                                        
                                            <Col md={6}>
                                                {fotoDefault !== '' || contenutoFile!=='' ?
                                                <Fragment>
                                                <Input className="ml-2 mt-2" type="checkbox" name="flagEliminaFoto" id="flagEliminaFoto"
                                                    innerRef={flagEliminaFotoRif}                                 
                                                    placeholder=""
                                                    onInput={(e)=>{handleCambiamentoMod(e);funzioneEliminaFoto(e.target.value)}}/>
                                                    <Label className="ml-4 mt-2">Elimina Logo</Label></Fragment>
                                                :""}
                                            </Col>
                                    </Row>
                                            */}
                                    </Col>
                                    
                                    
                                </Row>
                                
                                <Row form>
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label>Regime Fiscale (dato obbligatorio per Fatture Elettroniche)</Label>
                                            <br/>
                                            <select className="inputStandard" value={regimeFiscaleDefault} onChange={(e)=>{handleCambiamentoMod(e);setRegimeFiscaleDefault(e.target.value)}}>
                                                <option value=''></option>
                                                <option value='RF01'>RF01: Ordinario</option>
                                                <option value='RF02'>RF02: Contribuenti minimi (art.1, c.96-117, L. 244/07)</option>
                                                <option value='RF04'>RF04: Agricoltura e attività connesse e pesca (artt.34 e 34-bis, DPR 633/72)</option>
                                                <option value='RF05'>RF05: Vendita sali e tabacchi (art.74, c.1, DPR. 633/72)</option>
                                                <option value='RF06'>RF06: Commercio fiammiferi (art.74, c.1, DPR 633/72)</option>
                                                <option value='RF07'>RF07: Editoria (art.74, c.1, DPR 633/72)</option>
                                                <option value='RF08'>RF08: Gestione servizi telefonia pubblica (art.74, c.1, DPR 633/72)</option>
                                                <option value='RF09'>RF09: Rivendita documenti di trasporto pubblico e di sosta (art.74, c.1, DPR 633/72)</option>
                                                <option value='RF10'>RF10: Intrattenimenti, giochi e altre attività di cui alla tariffa allegata al DPR 640/72 (art.74, c.6, DPR 633/72)</option>
                                                <option value='RF11'>RF11: Agenzie viaggi e turismo (art.74-ter, DPR 633/72)</option>
                                                <option value='RF12'>RF12: Agriturismo (art.5, c.2, L. 413/91)</option>
                                                <option value='RF13'>RF13: Vendite a domicilio (art.25-bis, c.6, DPR 600/73)</option>
                                                <option value='RF14'>RF14: Rivendita beni usati, oggetti d’arte, d’antiquariato o da collezione (art.36, DL 41/95)</option>
                                                <option value='RF15'>RF15: Agenzie di vendite all’asta di oggetti d’arte, antiquariato o da collezione (art.40-bis, DL 41/95)</option>
                                                <option value='RF16'>RF16: IVA per cassa P.A. (art.6, c.5, DPR 633/72)</option>
                                                <option value='RF17'>RF17: IVA per cassa (art. 32-bis, DL 83/2012)</option>
                                                <option value='RF18'>RF18: Altro</option>
                                                <option value='RF19'>RF19: Regime forfettario (art.1, c.54-89, L. 190/2014)</option>
                                            </select>                                            
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <div className='schedaSottotitolo'>Dati Registro Imprese, obbligatori solo per società che emettono Fatture Elettroniche:</div>
                                <Row form>
                                    <Col md={4}>
                                        <Label>REA Ufficio (2 caratteri)</Label>
                                        <Input maxLength={100} type="text" name="citta" id="citta"
                                                        innerRef={reaUfficio} 
                                                        placeholder=""
                                                        defaultValue={reaUfficioDefault}
                                                        onInput={(e)=>{handleCambiamentoMod(e);setReaUfficioDefault(reaUfficio.current.value)}}/>  
                                    </Col>  
                                    <Col md={4}>
                                        <Label>REA Numero (max 20 caratteri)</Label>
                                        <Input maxLength={100} type="text" name="citta" id="citta"
                                                        innerRef={reaNumero} 
                                                        placeholder=""
                                                        defaultValue={reaNumeroDefault}
                                                        onInput={(e)=>{handleCambiamentoMod(e);setReaNumeroDefault(reaNumero.current.value)}}/>  
                                    </Col>  
                                </Row>
                                <Row form>
                                    <Col md={4}>
                                        <Label>Capitale Sociale</Label>
                                        <Input maxLength={100} type="text" name="citta" id="citta"
                                                        innerRef={capitaleSociale} 
                                                        placeholder=""
                                                        defaultValue={capitaleSocialeDefault}
                                                        onInput={(e)=>{handleCambiamentoMod(e);setCapitaleSocialeDefault(capitaleSociale.current.value)}}/>  
                                    </Col>
                                    <Col md={4}>
                                        <Label>Socio Unico</Label>
                                        <br/>
                                        <select className="inputStandard" value={socioUnicoDefault} onChange={(e)=>{handleCambiamentoMod(e);setSocioUnicoDefault(e.target.value)}}><option value=''></option><option value='SU'>Socio unico</option><option value='SM'>Più soci</option></select>
                                    </Col>  
                                    <Col md={4}>
                                        <Label>Stato Liquidazione</Label>
                                        <br/>
                                        <select className="inputStandard" value={statoLiquidazioneDefault} onChange={(e)=>{handleCambiamentoMod(e);setStatoLiquidazioneDefault(e.target.value)}}><option value=''></option><option value='LN'>Non in liquidazione</option><option value='LS'>In liquidazione</option></select>
                                    </Col>  
                                </Row>
                                
                                <div className='schedaSottotitolo'>Dati SMTP (facoltativi, per inviare mail dal proprio Mail Server):</div>
                                <Row form>
                                    <Col md={4}>
                                        <Label>Indirizzo Smtp Server</Label>
                                        <Input maxLength={100} type="text" name="host" id="host"
                                            innerRef={host} 
                                            placeholder=""
                                            defaultValue={hostDefault}
                                            onInput={(e)=>{handleCambiamentoMod(e);setHostDefault(host.current.value)}}/>  
                                    </Col>
                                    <Col md={4}>
                                        <Label>Porta</Label>
                                        <Input maxLength={100} type="text" name="port" id="port"
                                            innerRef={port} 
                                            placeholder=""
                                            defaultValue={portDefault}
                                            onInput={(e)=>{handleCambiamentoMod(e);setPortDefault(port.current.value)}}/>  
                                    </Col>
                                    <Col md={4}>
                                        <Label>Nome mittente</Label>
                                        <Input maxLength={100} type="text" name="senderName" id="senderName"
                                            innerRef={senderName} 
                                            placeholder=""
                                            defaultValue={senderNameDefault}
                                            onInput={(e)=>{handleCambiamentoMod(e);setSenderNameDefault(senderName.current.value)}}/>  
                                    </Col>
                                </Row>
                                
                                <Row form>    
                                    <Col md={4}>
                                        <Label>Email mittente</Label>
                                        <Input maxLength={100} type="text" name="senderMail" id="senderMail"
                                            innerRef={senderMail} 
                                            placeholder=""
                                            defaultValue={senderMailDefault}
                                            onInput={(e)=>{handleCambiamentoMod(e);setSenderMailDefault(senderMail.current.value)}}/>  
                                    </Col>
                                    <Col md={4}>
                                    <Label>Utente</Label>
                                        <Input maxLength={100} type="text" name="user" id="user"
                                            innerRef={user} 
                                            placeholder=""
                                            defaultValue={userDefault}
                                            onInput={(e)=>{handleCambiamentoMod(e);setUserDefault(user.current.value)}}/>  
                                    </Col>
                                    <Col md={4}>
                                    <Label>Password</Label>
                                        <Input maxLength={100} type="password" name="pass" id="pass"
                                            innerRef={pass} 
                                            placeholder=""
                                            defaultValue={passDefault}
                                            onInput={(e)=>{handleCambiamentoMod(e);setPassDefault(pass.current.value)}}/>  
                                    </Col>
                                </Row>
                                
                                <Row form> 
                                    <Col md={4}>
                                    <Label>Tipo enc (ssl, tls,...)</Label>
                                        <Input maxLength={100} type="text" name="tipoEnc" id="tipoEnc"
                                            innerRef={tipoEnc} 
                                            placeholder=""
                                            defaultValue={tipoEncDefault}
                                            onInput={(e)=>{handleCambiamentoMod(e);setTipoEncDefault(tipoEnc.current.value)}}/>  
                                    </Col>
                                    <Col md={4}>
                                    <Label>Auth</Label>
                                        <Input maxLength={100} type="text" name="auth" id="auth"
                                            innerRef={auth} 
                                            placeholder=""
                                            defaultValue={authDefault}
                                            onInput={(e)=>{handleCambiamentoMod(e);setAuthDefault(auth.current.value)}}/>  
                                    </Col>
                                    <Col md={4}>
                                    <Label>Autotls</Label>
                                        <Input maxLength={100} type="text" name="autotls" id="autotls"
                                            innerRef={autotls} 
                                            placeholder=""
                                            defaultValue={autotlsDefault}
                                            onInput={(e)=>{handleCambiamentoMod(e);setAutotlsDefault(autotls.current.value)}}/>  
                                    </Col>
                                </Row>

                                </div>

                                <div className="schedaSpazioInferiore">
                                
                                {variazioneCampi === 1? 
                                    <Button disabled={disabilitaBottoni} color="warning" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer()}}>Memorizza Modifiche</Button>
                                : 
                                    hostDefault!="" && <Button disabled={disabilitaBottoni} color="primary" className="mt-2 bottoneMargineADestra" onClick={() => {inviaMailTest()}}>Invia mail di test</Button>
                                }
                                
                                {variazioneCampi === 1 ?  
                                    <Fragment> 
                                    <Button disabled={disabilitaBottoni} color="primary" className="mt-2" onClick={() => {setStatoPagina(STATO_INSERISCI);setVariazioneCampi(0);tornaScheda()}} >
                                    Annulla 
                                    </Button>
                                    </Fragment>
                                : ""}
                                </div>
                            


                    </div>
                :""}
            </Fragment>  
            </div>
        </div>
    </Fragment>
        );
}
export default TablesImpostazioniSocieta;
