import React, {Fragment,useState,useEffect,useRef,Suspense} from 'react';
import { Table,Row, Col,
  Card, CardBody,
  CardTitle,Button } from 'reactstrap';
import Parametri from '../../parametri';

import FormGridFormRow from '../SchedaRimborsi/FormGridFormRow';


import TabellaRicerca from '../tabellaricerca.js';

import TrasformaDateAUmano from '../TrasformaDateAUmano.js';

import Clessidra from '../Clessidra.js';

import { calcolaAnnoOggi } from '../../funzioni.js';
import Interrogativo from '../../immagini/interrogativo-azzurro.png';

import Modale from '../Modale.js';

//const STATO_INDEFINITO = 0;
const STATO_RICERCA_DATI = 1;
const STATO_OPERAZIONE_CORRETTA= 2;
const STATO_ERRORE_CONNESSIONE= 3;
const STATO_VAI_IN_MODIFICA= 4;
const STATO_VAI_IN_ELIMINA= 5;
const STATO_VAI_IN_INSERISCI= 6;


function TabellaRimborsi(props) {
    
    const operazione = "elencoRimborsi";

    const  dataPartenza = useRef();

    const annoOggi = calcolaAnnoOggi(props.identitaSelezionata.data_chiusura_esercizio);
    const [dataPartenzaDefault,setDataPartenzaDefault]=useState(annoOggi);
    const [inizioPeriodo,setInizioPeriodo]=useState('');
    const [finePeriodo,setFinePeriodo]=useState('');
    
    let idPersona = '';
      
    const [risultatoServer,setRisultatoServer]=useState('');
    const [statoPagina,setStatoPagina]=useState(STATO_RICERCA_DATI);
    const [error,setError]=useState('');

    const [idModifica,setIdModifica]=useState('');
    
    const [datiPersonaFiltrata, setDatiPersonaFiltrata]=useState('');
    const [loading,setLoading]= useState(false);
    const [flagModaleInfoPopolamento, setFlagModaleInfoPopolamento] = React.useState(false);

    // nota: accessor DEVE chiamarsi così a causa di requisiti dei componenti usati, non fatti da me
    const colonne = [
      {
        etichetta: '',
        accessor: 'ID'
      },
      {
        etichetta: 'Cognome Iscritto',
        accessor: 'COGNOME_ISCRITTO',
        pdf: 'Cognome\nIscritto',
      },
      {
        etichetta: 'Nome Iscritto',
        accessor: 'NOME_ISCRITTO',
        pdf: 'Nome\nIscritto',
      },
      {
        etichetta: 'Numero Tessera Iscritto',
        accessor: 'NUMERO_TESSERA',
        pdf: 'Numero Tessera\nIscritto',
      },
      {
        etichetta: 'Descrizione',
        accessor: 'DESCRIZIONE',
        pdf: 1,
      },
      {
        etichetta: 'Importo',
        accessor: 'IMPORTO',
        pdf: 1,
      },
      {
        etichetta: 'Data',
        accessor: 'DATA_EMISSIONE',
        pdf: 1,
      },
    ];
    const arrayTipiDati=[];
    arrayTipiDati[5]="importo";
    arrayTipiDati[6]="data";

    const colonnePaginaFiltrata = [
      {
        etichetta: '',
        accessor: 'ID'
      },
      {
        etichetta: 'Descrizione',
        accessor: 'DESCRIZIONE'
      },
      {
        etichetta: 'Importo',
        accessor: 'IMPORTO'
      },
      {
        etichetta: 'Data',
        accessor: 'DATA_EMISSIONE'
      },
    ];
    const arrayTipiDatiPaginaFiltrata=[];
    arrayTipiDatiPaginaFiltrata[2]="importo";
    arrayTipiDatiPaginaFiltrata[3]="data";

    function VaiModifica(id) {
      setStatoPagina(STATO_VAI_IN_MODIFICA);
      setIdModifica(id);
    }

    function VaiElimina(id) {
      setStatoPagina(STATO_VAI_IN_ELIMINA);
      setIdModifica(id);
    }

    function callbackTermineModifica() {
      setStatoPagina(STATO_RICERCA_DATI);
    }
    function tornaElenco() {
      setFlagModaleInfoPopolamento(false);
    }
    useEffect(() => {
      if (props.flagResetPagine) {
        setStatoPagina(STATO_RICERCA_DATI);
        props.callbackFlagResetPagine(false);
      }
    }, [props.flagResetPagine])

    useEffect(() => {
      //resetto tutte le var di stato:
      setRisultatoServer(['']);
      setStatoPagina(STATO_RICERCA_DATI);    
    },[dataPartenzaDefault])
    
    useEffect(() => {
      if(statoPagina===STATO_RICERCA_DATI){

        if(props.idPersona !== undefined) idPersona = props.idPersona;
        // parametri per inviare i dati al server:
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({op:operazione,idElemento:idPersona,dataPartenza:dataPartenzaDefault,emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
          .then(risposta => risposta.json())
          .then(
            (valoreDiRitorno) => {
              if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
              }
              if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
              if(valoreDiRitorno.risultatoOperazione===1){
                setRisultatoServer(valoreDiRitorno.risultatoRitorno);
                setInizioPeriodo(valoreDiRitorno.periodo[0].DATA_INIZIO_PERIODO);
                setFinePeriodo(valoreDiRitorno.periodo[0].DATA_FINE_PERIODO);

                if(valoreDiRitorno.risultatoRitorno[0]!== undefined){
                  if(props.idPersona>0 && valoreDiRitorno.risultatoRitorno !== undefined && valoreDiRitorno.risultatoRitorno !== ""){
                    let componiPersona = "";
                    if(valoreDiRitorno.risultatoRitorno[0].COGNOME_ISCRITTO !== '' && valoreDiRitorno.risultatoRitorno[0].COGNOME_ISCRITTO !== undefined) componiPersona = componiPersona + valoreDiRitorno.risultatoRitorno[0].COGNOME_ISCRITTO;
                    if(valoreDiRitorno.risultatoRitorno[0].NOME_ISCRITTO !== '' && valoreDiRitorno.risultatoRitorno[0].NOME_ISCRITTO !== undefined && componiPersona !== "") componiPersona = componiPersona + " ";
                    if(valoreDiRitorno.risultatoRitorno[0].NOME_ISCRITTO !== '' && valoreDiRitorno.risultatoRitorno[0].NOME_ISCRITTO !== undefined) componiPersona = componiPersona + valoreDiRitorno.risultatoRitorno[0].NOME_ISCRITTO;
                    if(valoreDiRitorno.risultatoRitorno[0].NUMERO_TESSERA !== '' && valoreDiRitorno.risultatoRitorno[0].NUMERO_TESSERA !== undefined && componiPersona !== "") componiPersona = componiPersona + " ";
                    if(valoreDiRitorno.risultatoRitorno[0].NUMERO_TESSERA !== '' && valoreDiRitorno.risultatoRitorno[0].NUMERO_TESSERA !== undefined) componiPersona = componiPersona + "("+valoreDiRitorno.risultatoRitorno[0].NUMERO_TESSERA+")";
                    setDatiPersonaFiltrata(componiPersona);
                  }
                  setStatoPagina(STATO_OPERAZIONE_CORRETTA);
                }else{
                  ricercaDatiPersona(props.idPersona);
                }
                
              }
            },
          (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
          );
      } 
    },[statoPagina])
   
    function ricercaDatiPersona(persona){
      //ricerco i dati della persona della quale ho l'id nella props:
      setLoading(true);
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({op:"personeRecuperaDati",id:persona,recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
      };

      fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
          .then(risposta => risposta.json())
          .then(
          (valoreDiRitorno) => {
              setLoading(false);
              if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                  alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
              }
              if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
              if(valoreDiRitorno.risultatoOperazione===1){
              //setRisultatoServerDatiPersona(valoreDiRitorno.risultatoRitorno);
              //compongo quidi i dati della persona:
              let componiPersona = "";
              if(valoreDiRitorno.risultatoRitorno[0].COGNOME !== '' && valoreDiRitorno.risultatoRitorno[0].COGNOME !== undefined) componiPersona = componiPersona + valoreDiRitorno.risultatoRitorno[0].COGNOME;
              if(valoreDiRitorno.risultatoRitorno[0].NOME !== '' && valoreDiRitorno.risultatoRitorno[0].NOME !== undefined && componiPersona !== "" ) componiPersona = componiPersona + " ";
              if(valoreDiRitorno.risultatoRitorno[0].NOME !== '' && valoreDiRitorno.risultatoRitorno[0].NOME !== undefined ) componiPersona = componiPersona + valoreDiRitorno.risultatoRitorno[0].NOME;
              if(valoreDiRitorno.risultatoRitorno[0].NUMERO_TESSERA !== '' && valoreDiRitorno.risultatoRitorno[0].NUMERO_TESSERA !== undefined && componiPersona !== "" ) componiPersona = componiPersona + " ";
              if(valoreDiRitorno.risultatoRitorno[0].NUMERO_TESSERA !== '' && valoreDiRitorno.risultatoRitorno[0].NUMERO_TESSERA !== undefined ) componiPersona = componiPersona + "("+valoreDiRitorno.risultatoRitorno[0].NUMERO_TESSERA+")";
              setDatiPersonaFiltrata(componiPersona);
              setStatoPagina(STATO_OPERAZIONE_CORRETTA);
              }
          },
          (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
          );
  }

  return (
      <div>
      
      {statoPagina === STATO_VAI_IN_INSERISCI ? 
      
        <div>
        <Suspense fallback={
          <div className="loader-container">
          <div className="loader-container-inner"><h6 className="mt-5">Caricamento scheda rimborso in corso...</h6></div>
          </div>
        }>
        <FormGridFormRow {...props} ope={"inserisci"} idPersona={props.idPersona} callbackTermineModifica={callbackTermineModifica} sessionId={props.sessionId}></FormGridFormRow>
        </Suspense>
      </div>
      :""} 

      {statoPagina === STATO_VAI_IN_MODIFICA ? 
        <div>
          <Suspense fallback={
            <div className="loader-container">
            <div className="loader-container-inner"><h6 className="mt-5">Caricamento scheda rimborso in corso...</h6></div>
            </div>
          }>
          <FormGridFormRow {...props} id={idModifica} ope={"modifica"} callbackTermineModifica={callbackTermineModifica} sessionId={props.sessionId}></FormGridFormRow>
          </Suspense>
        </div>
      :""} 

      {statoPagina === STATO_VAI_IN_ELIMINA ? 
        <div>
          <Suspense fallback={
            <div className="loader-container">
            <div className="loader-container-inner"><h6 className="mt-5">Caricamento scheda rimborso in corso...</h6></div>
            </div>
          }>
          <FormGridFormRow {...props} id={idModifica} ope={"cancella"} callbackTermineModifica={callbackTermineModifica} sessionId={props.sessionId}></FormGridFormRow>
          </Suspense>
        </div>
      :""} 

      {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1E_Rim</div> :""}  
      {statoPagina === STATO_RICERCA_DATI ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}

      {statoPagina === STATO_OPERAZIONE_CORRETTA ?
        <div style={{height: props.dimensioniApp.yMain, overflow:"hidden", display:"flex", flexDirection:"column"}}>
          {flagModaleInfoPopolamento=== true ?
                    <Modale 
                        larghezza = "lg"
                        titolo="Informativa Rimborsi"
                        flagErrore={false}
                        contenuto={<div>Questo ambiente consente di gestire i rimborsi verso gli iscritti. Gli iscritti vengono gestiti e catalogati in Anagrafiche - Elenco Iscritti.<br></br><br></br>
                          Durante l'inserimento o la modifica di un rimborso, è possibile creare direttamente un nuovo iscritto.<br></br> 
                          Successivamente, accedendo a Anagrafiche - Elenco Iscritti, sarà possibile completare l'operazione aggiungendo i campi mancanti non inseriti nella fase iniziale.<br></br><br></br>
                          I pagamenti registrati in questo ambiente verranno visualizzati automaticamente anche in prima nota.<br></br><br></br>
                          Nella scheda del rimborso vi è un riepilogo del totale degli importi corrisposti all'iscritto in qualità di rimborsi, sia per l'anno in corso che per tutte le annate (calcolati su base annua tenendo come riferimento l'anno solare)

                        </div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {tornaElenco()}
                            }    
                        ]}
                    />
                :""}
                    <CardBody>
                            <CardTitle>Elenco Rimborsi
                            {props.idPersona>0 ? <div>(filtrato per l'iscritto {datiPersonaFiltrata})
                            </div> :""}

                            &nbsp;
                            <select
                              name="dataPartenza"
                              id="dataPartenza"
                              innerRef={dataPartenza}
                              onChange={(e) => setDataPartenzaDefault(e.target.value)}
                            >
                              {/* Significato di (_, i) => { ... }
                              Il primo parametro _ rappresenta l'elemento corrente dell'array. In questo caso, l'array è creato con [...Array(annoOggi - 2014 + 1)], quindi è un array di elementi "vuoti" (tipo [undefined, undefined, ...]). Poiché non ci interessa il valore degli elementi, usiamo _ per indicare che non lo utilizziamo.
                              Il secondo parametro i rappresenta l'indice corrente dell'iterazione (parte da 0 e cresce di 1 per ogni iterazione).*/}
                              {[...Array((annoOggi+1) - 2014 + 1)].map((_, i) => {
                                const anno = annoOggi+1  - i;
                                return (
                                  <option key={anno} value={anno} selected={parseInt(dataPartenzaDefault) === anno}>
                                    {anno}
                                  </option>
                                );
                              })}
                            </select>
                            {/*
                            <select name="dataPartenza" id="dataPartenza" innerRef={dataPartenza} onChange={(e)=>{setDataPartenzaDefault(e.target.value)}}>
                              {parseInt(dataPartenzaDefault) === annoOggi ? <option selected value={annoOggi}>{annoOggi}</option> : <option value={annoOggi}>{annoOggi}</option>}
                              {parseInt(dataPartenzaDefault) === annoOggi-1 ? <option selected value={annoOggi-1}>{annoOggi-1}</option> : <option value={annoOggi-1}>{annoOggi-1}</option>}
                              {parseInt(dataPartenzaDefault) === annoOggi-2 ? <option selected value={annoOggi-2}>{annoOggi-2}</option> : <option value={annoOggi-2}>{annoOggi-2}</option>}
                              {parseInt(dataPartenzaDefault) === annoOggi-3 ? <option selected value={annoOggi-3}>{annoOggi-3}</option> : <option value={annoOggi-3}>{annoOggi-3}</option>}
                              {parseInt(dataPartenzaDefault) === annoOggi-4 ? <option selected value={annoOggi-4}>{annoOggi-4}</option> : <option value={annoOggi-4}>{annoOggi-4}</option>}
                              {parseInt(dataPartenzaDefault) === annoOggi-5 ? <option selected value={annoOggi-5}>{annoOggi-5}</option> : <option value={annoOggi-5}>{annoOggi-5}</option>}
                              {parseInt(dataPartenzaDefault) === annoOggi-6 ? <option selected value={annoOggi-6}>{annoOggi-6}</option> : <option value={annoOggi-6}>{annoOggi-6}</option>}
                              {parseInt(dataPartenzaDefault) === annoOggi-7 ? <option selected value={annoOggi-7}>{annoOggi-7}</option> : <option value={annoOggi-7}>{annoOggi-7}</option>}
                              {parseInt(dataPartenzaDefault) === annoOggi-8 ? <option selected value={annoOggi-8}>{annoOggi-8}</option> : <option value={annoOggi-8}>{annoOggi-8}</option>}
                              {parseInt(dataPartenzaDefault) === annoOggi-9 ? <option selected value={annoOggi-9}>{annoOggi-9}</option> : <option value={annoOggi-9}>{annoOggi-9}</option>}
                              {parseInt(dataPartenzaDefault) === annoOggi-10 ? <option selected value={annoOggi-10}>{annoOggi-10}</option> : <option value={annoOggi-10}>{annoOggi-10}</option>}
                            </select>   */} 
                            &nbsp;Data dal <TrasformaDateAUmano data={inizioPeriodo}></TrasformaDateAUmano> al <TrasformaDateAUmano data={finePeriodo}></TrasformaDateAUmano>



                            </CardTitle>
                            <Button color="success" onClick={() => setStatoPagina(STATO_VAI_IN_INSERISCI)}>Nuovo</Button>
                            <img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setFlagModaleInfoPopolamento(true)}/>
                            </CardBody>
                        
                            {risultatoServer !== undefined && risultatoServer !== "" ?
                              props.idPersona>0 ?
                                <TabellaRicerca 
                                titolo={`Elenco Rimborsi ${parseInt(dataPartenzaDefault)}`} 
                                columns={colonnePaginaFiltrata} data={risultatoServer} arrayTipiDati={arrayTipiDatiPaginaFiltrata} campoChiave={0} campoOrdinamento={3} ordinamentoDecrescente={true} nascondiColonna={0} callbackSelezionaElemento={VaiModifica} callbackEliminaElemento={VaiElimina}/>
                                :
                                <TabellaRicerca 
                                titolo={`Elenco Rimborsi ${parseInt(dataPartenzaDefault)}`} 
                                columns={colonne} data={risultatoServer} arrayTipiDati={arrayTipiDati} campoChiave={0} nascondiColonna={0} campoOrdinamento={6} ordinamentoDecrescente={true} callbackSelezionaElemento={VaiModifica} callbackEliminaElemento={VaiElimina}/>
                              : 
                              <Table responsive bordered hover striped className="mb-0">
                              <thead>
                                <tr>
                                  <th>Nessun dato presente</th>
                                </tr>
                              </thead>
                              </Table>
                          }
                      
        </div>
      :""}
    </div>
   );
}
export default TabellaRimborsi;