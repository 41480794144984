
function controllaDataAAAAMMGG(d,annoMinimo = ''){
  if (d=="") return d;

  // Definisci il pattern per il formato AAAA-MM-DD
  const datePattern = /^\d{4}-\d{2}-\d{2}$/;

  // Verifica che il formato corrisponda al pattern
  if (!datePattern.test(d)) {
    return '';
  }

  // Estrai i componenti della data
  let [year, month, day] = d.split('-').map(Number);

  // Se l'anno è minore di 100, aggiungi 2000
  if (year < 100) {
    year += 2000;
  }

  if(annoMinimo!=''){
    if(year<annoMinimo) return 'errore'; // la data non è valida
  }


  // Ricrea la data con l'anno modificato (se necessario)
  const newDateStr = `${year.toString().padStart(4, '0')}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
  
  // Verifica che la data sia effettivamente valida
  const date = new Date(newDateStr);
  if (date.getFullYear() === year && date.getMonth() + 1 === month && date.getDate() === day) {
    return newDateStr; // Restituisci la data modificata o originale se valida
  } else {
    return 'errore'; // la data non è valida
  }
}

export default controllaDataAAAAMMGG;