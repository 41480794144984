
import React, {useState,useEffect} from 'react';
import {
    Col, Row, Card, CardBody,
    CardTitle, Button, Form, FormGroup, Label, Input
} from 'reactstrap';
import Parametri from './parametri';
import Clessidra from './Pagine/Clessidra';
//import Main from '../../../DemoPages/Main';
import './login.css';

const STATO_INDEFINITO = 0;
const STATO_INSERISCI_PASSWORD = 1;
const STATO_CODICE_RESET_NON_VALIDO = 2;
const STATO_FINE_KO = 3;
const STATO_FINE_OK = 4;
const STATO_FINE_CODICE_NON_VALIDO = 5;
const STATO_CONSULTAZIONE_BACKEND = 6;

function PaginaResetPassword(props) {
    const [statoPagina,setStatoPagina]=useState(STATO_INDEFINITO);
    const [codiceReset, setCodiceReset] = useState("");   
   
    useEffect(() => {
        const currentUrl = window.location.href;
        const url = new URL(currentUrl);
        const parametro = url.searchParams.get("djakgfh89724wygr");
        if (parametro) {
            setCodiceReset(parametro);
            setStatoPagina(STATO_INSERISCI_PASSWORD);
        } else {
            setStatoPagina(STATO_CODICE_RESET_NON_VALIDO);
        }
    },[]);

    function inviaNuovaPassword() {
        if (document.getElementById("nuovaPassword").value !== document.getElementById("nuovaPassword2").value) {
            alert("Attenzione: le due password inserite non coincidono");
            return;
        }
        if (document.getElementById("nuovaPassword").value.length<8) {
            alert("Attenzione: inserire una password che abbia almeno 8 caratteri");
            return;
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"resetPassword",
                codiceReset:codiceReset,                
                nuova:document.getElementById("nuovaPassword").value,
                emak:props.emak,
            })
        };

        setStatoPagina(STATO_CONSULTAZIONE_BACKEND);

        fetch(Parametri("serverURLPrimario"), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                if(valoreDiRitorno.risultatoOperazione===1){
                    setStatoPagina(STATO_FINE_OK);
                    return;    
                }
                if(valoreDiRitorno.risultatoOperazione===-2){
                    setStatoPagina(STATO_FINE_CODICE_NON_VALIDO);
                    return;    
                }
                alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                setStatoPagina(STATO_FINE_KO);
            },
            (error) => {setStatoPagina(STATO_FINE_KO);}
        );
        
    }

    if (statoPagina === STATO_FINE_KO) {
        return (
            <div className="contenitoreLogin">
                <Card className="main-card mb-3 larghezzaCardLogin">
                    <CardTitle>GESTIONE SPORTIVA</CardTitle>
                    <CardBody>
                        <br/>
                        <b>Non è stato possibile concludere l'operazione</b>
                        <br/><br/><br/>
                    </CardBody>
                </Card>
            </div>
        )
    }    

    if (statoPagina === STATO_FINE_OK) {
        return (
            <div className="contenitoreLogin">
                <Card className="main-card mb-3 larghezzaCardLogin">
                    <CardTitle>GESTIONE SPORTIVA</CardTitle>
                    <CardBody>
                        <center>
                        Password impostata correttamente.<br/><br/>

                        <Button color="primary" className="mt-2" onClick={() => window.location.href = "https://app.gestionesportiva.it"}>Prosegui</Button>
                        </center>

                        <br/>
                    </CardBody>
                </Card>
            </div>
        )
    }    

    if (statoPagina === STATO_FINE_CODICE_NON_VALIDO) {
        return (
            <div className="contenitoreLogin">
                <Card className="main-card mb-3 larghezzaCardLogin">
                    <CardTitle>GESTIONE SPORTIVA</CardTitle>
                    <CardBody>
                        <br/>
                        <b>LINK NON VALIDO, PASSWORD NON MODIFICATA</b><br/><br/>Verifica di aver cliccato il link che hai ricevuto nell'ultima mail.
                        <br/><br/><br/>
                    </CardBody>
                </Card>
            </div>
        )
    }    

    if (statoPagina === STATO_CONSULTAZIONE_BACKEND) {
        return (
            <div className="contenitoreLogin">
                <Card className="main-card mb-3 larghezzaCardLogin">
                    <CardTitle>GESTIONE SPORTIVA</CardTitle>
                    <CardBody>
                        <br/><br/><br/>
                        <Clessidra loading={true}/>
                    </CardBody>
                </Card>
            </div>
        )
    }

    if (statoPagina === STATO_INSERISCI_PASSWORD) {
        return (
            <div className="contenitoreLogin">
                <Card className="main-card mb-3 larghezzaCardLogin">
                    <CardBody>
                        <CardTitle>GESTIONE SPORTIVA</CardTitle>
                        <b>Impostazione di una nuova password</b><br/><br/>
                        <Form>
                            <Row form>  
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="email">Inserisci la nuova password:</Label>
                                        <Input type="password" name="email" id="nuovaPassword" placeholder=""/>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="password">Inserisci di nuovo la nuova password:</Label>
                                        <Input type="password" name="password" id="nuovaPassword2" placeholder=""/>
                                    </FormGroup>
                                </Col>
                            </Row>
                                
                            <Button color="primary" className="mt-2" onClick={() => inviaNuovaPassword()}>Memorizza la nuova password</Button>
                        </Form>

                    </CardBody>
                </Card>
            </div>
        )
    }

    // default
    return (<div className="contenitoreLogin"><Clessidra loading={true}/></div>)

};

export default PaginaResetPassword;