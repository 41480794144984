import React, {useState,useEffect} from 'react';
import { Table,Row, Col, Card, CardBody, CardTitle,Button,Input } from 'reactstrap';
import Parametri from '../../parametri';
import Clessidra from '../Clessidra.js';
import TabellaRicerca from '../tabellaricerca.js';
import TrasformaDateAUmano from '../TrasformaDateAUmano.js';

const STATO_RICERCA_DATI_TABELLA = 1;
const STATO_VISUALIZZA_TABELLA = 2;
const STATO_ERRORE_CONNESSIONE = 3;
const STATO_RICERCA_DATI_ORDINE = 4;
const STATO_DETTAGLIO_ORDINE = 5;

const colonne = [
    {
        etichetta: '',
        accessor: 'ID'
    },
    {
        etichetta: 'Data Ordine',
        accessor: 'DATAORDINE',
        pdf: 1,
    },
    {
        etichetta: 'Cognome',
        accessor: 'COGNOME',
        pdf: 1,
    },
    {
        etichetta: 'Nome',
        accessor: 'NOME',
        pdf: 1,
    },
    {
        etichetta: 'Email',
        accessor: 'EMAIL',
        pdf: 1,
    },
    {
        etichetta: 'Inizio Serv.',
        accessor: 'DATA_INIZIO_SERVIZIO',
        pdf: 1,
    },
    {
        etichetta: 'Fine Serv.',
        accessor: 'DATA_FINE_SERVIZIO',
        pdf: 1,
    },
    {
        etichetta: 'Data pagamento',
        accessor: 'DATA_PAGAMENTO',
        pdf: 1,
    },
    {
        etichetta: 'Importo',
        accessor: 'IMPORTO_LORDO',
        pdf: 1,
    },
  ];

  const arrayTipiDati=[];
  arrayTipiDati[1]="data";
  arrayTipiDati[5]="data";  
  arrayTipiDati[6]="data";
  arrayTipiDati[7]="data";
  arrayTipiDati[8]="importo";

function TabellaOrdiniMAS(props) {
    const [statoPagina, setStatoPagina] = useState(STATO_RICERCA_DATI_TABELLA);
    const [ordini, setOrdini] = useState("");
    const [datiOrdine, setDatiOrdine] = useState([]);
    
    useEffect(() => {
        if (statoPagina == STATO_RICERCA_DATI_TABELLA) ricercaDatiOrdini();
    }, [statoPagina])

    function dettaglioOrdine(id) {
        setStatoPagina(STATO_RICERCA_DATI_ORDINE);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"dettaglioOrdine_MAS",idOrdine:id, emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                    setDatiOrdine(valoreDiRitorno.risultatoRitorno[0]);
                    setStatoPagina(STATO_DETTAGLIO_ORDINE);
                }
            },
            (error) => {setStatoPagina(STATO_ERRORE_CONNESSIONE);}
        );      
    }

    function ricercaDatiOrdini() {
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({op:"ordiniMASRecuperaDati",emak: props.emak,sessionId:props.sessionId})
      };
  
      fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
          .then(risposta => risposta.json())
          .then(
          (valoreDiRitorno) => {
              if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                  alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
              }
              if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
              if(valoreDiRitorno.risultatoOperazione===1){
                  setOrdini(valoreDiRitorno.risultatoRitorno);
                  setStatoPagina(STATO_VISUALIZZA_TABELLA);
              }
          },
          (error) => {setStatoPagina(STATO_ERRORE_CONNESSIONE);}
          );
    }
    
    var coloreEvidenziato="#84b352";

    if (statoPagina==STATO_RICERCA_DATI_ORDINE || statoPagina==STATO_RICERCA_DATI_TABELLA) return (<div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/></div>)

    if (statoPagina==STATO_ERRORE_CONNESSIONE) return (<div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}>ErrOrdiniMAS#1</div>)

    if (statoPagina==STATO_DETTAGLIO_ORDINE) return (
        <div style={{height: props.dimensioniApp.yMain, overflow:"auto"}}>          
            <CardBody>
                <CardTitle>Dettaglio Ordine</CardTitle>
            </CardBody>
            <div style={{paddingLeft:10}}>
                <b>Ordine {datiOrdine.NUMEROORDINE} del <TrasformaDateAUmano data={datiOrdine.DATAORDINE}/></b>
                <br/><br/>
                <b>Effettuato da:</b><br/>
                Cognome e nome: {datiOrdine.COGNOME} {datiOrdine.NOME}<br/>                
                Email: {datiOrdine.EMAIL}<br/>                
                Codice Fiscale: {datiOrdine.CODICE_FISCALE}<br/>                
                Indirizzo: {datiOrdine.INDIRIZZO} {datiOrdine.CAP} {datiOrdine.CITTA} ({datiOrdine.PROVINCIA})<br/>
                Nato a: {datiOrdine.COMUNE_NASCITA} ({datiOrdine.PROVINCIA_NASCITA}) il <TrasformaDateAUmano data={datiOrdine.DATA_NASCITA}/><br/>
                <br/>
                <b>Persona di riferimento:</b><br/>
                Cognome e nome: {datiOrdine.RIFCOGNOME} {datiOrdine.RIFNOME}<br/>                
                Email: {datiOrdine.RIFEMAIL}<br/>                
                Codice Fiscale: {datiOrdine.RIFCODICE_FISCALE}<br/>                
                Indirizzo: {datiOrdine.RIFINDIRIZZO} {datiOrdine.RIFCAP} {datiOrdine.RIFCITTA} ({datiOrdine.RIFPROVINCIA})<br/>
                Nato a: {datiOrdine.RIFLUOGO_NASCITA} il <TrasformaDateAUmano data={datiOrdine.RIFDATA_NASCITA}/><br/>
                <br/>
                <b>Oggetto:</b><br/>
                Articolo: {datiOrdine.CODICE_ARTICOLO}<br/>
                Inizio servizio: <TrasformaDateAUmano data={datiOrdine.DATA_INIZIO_SERVIZIO}/><br/>
                Fine servizio: <TrasformaDateAUmano data={datiOrdine.DATA_FINE_SERVIZIO}/><br/>
                <br/>
                <b>Importo e pagamento:</b><br/>
                Importo: {datiOrdine.IMPORTO_NETTO} + {datiOrdine.IVA} IVA = {datiOrdine.IMPORTO_LORDO} euro<br/>
                Data pagamento: <TrasformaDateAUmano data={datiOrdine.DATA_PAGAMENTO}/><br/>
                Paypal ID: {datiOrdine.PAYPAL_ID}<br/>
                Paypal ESITO: <pre style={{backgroundColor: "#FFFFFF", padding:5, wordWrap: 'break-word'}}>
{/* tienilo come esempio per far vedere meglio il json:
                {JSON.stringify(JSON.parse(datiOrdine.PAYPAL_ESITO.substring(datiOrdine.PAYPAL_ESITO.indexOf("{"))), null, 2)}
*/}                 
                {datiOrdine.PAYPAL_ESITO}
                </pre><br/>                     
                <Button color="success" onClick={() => setStatoPagina(STATO_RICERCA_DATI_TABELLA)}>Torna all'elenco</Button>
            </div>
        </div>
    )

    return (
        <div style={{height: props.dimensioniApp.yMain, overflow:"hidden", display:"flex", flexDirection:"column"}}>          
          <CardBody>
            <CardTitle>Elenco Ordini</CardTitle>
          </CardBody>
          {ordini !== undefined && ordini !== "" ?
            <TabellaRicerca {...props} titolo={'Elenco Ordini'} columns={colonne} data={ordini} campoChiave={0} campoOrdinamento={1} nascondiColonna={0} arrayTipiDati={arrayTipiDati} callbackSelezionaElemento={dettaglioOrdine}/>
          : 
            <Table responsive bordered hover striped className="mb-0">
                <thead>
                <tr>
                    <th>Nessun dato presente</th>
                </tr>
                </thead>
            </Table>
          }
          
        </div>
   );
}
export default TabellaOrdiniMAS