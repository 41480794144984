/* TabellaRicerca.js 1.3 */

/* NOTA: nella div dentro la TableCell dei titoli delle colonne c'è uno stile con un backgroundColor vuoto apparentemente inutile,
ma senza quello ci sono due problemi:
- le div non occupano l'intero spazio e quindi i titoli delle colonne non sono centrati
- le colonne sono tutte larghe uguali, con una distribuzione non ottimale dello spazio orizzontale.
Non è chiaro perché mettere questo stile risolve i due problemi.
*/

import React, {useEffect, useState } from 'react';
import { useTable, useSortBy, useBlockLayout, useFilters } from 'react-table';
import {Button} from 'reactstrap';

import Table from '@material-ui/core/Table'
import styled from "styled-components";
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';

import TrasformaDateAUmano from './TrasformaDateAUmano.js';
import TrasformaDateDaUmano from './TrasformaDateDaUmano.js';
import FormattaImportoVideo from './FormattaImportoVideo.js';
import FormattaNumeroVideo from './FormattaNumeroVideo.js';
import FormattaVincoloVideo from './FormattaVincoloVideo.js';
import FormattaFlagVideo from './FormattaFlagVideo.js';

import Cestino from "../immagini/cestino.png";

import { Page, Text, View, Document, StyleSheet,Image,pdf } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import styles from './stiliPdf.js'

import * as XLSX from 'xlsx';//richiede npm i --save xlsx --force

const STATO_VISUALIZZA_TABELLA = 0;
const STATO_SCARICA_PDF = 1;
const STATO_PREPARA_PDF = 2;

function FiltroColonnaNormale( {column: { filterValue, preFilteredRows, setFilter }} )  {
  const count = preFilteredRows.length

  return (
    <input
      style={{width:"100%"}}
      value={filterValue || ''}
      onChange={e => {
        setFilter(e.target.value || undefined) // undefined elimina il filtro
      }}
      placeholder={`Cerca (${count} el.)`}
      size="12"      
    />
  )
}

function FiltroColonnaData( {column: { filterValue, preFilteredRows, setFilter }} )  {
  const count = preFilteredRows.length

  return (
    <input
      style={{width:"100%"}}
      
      onChange={e => {       
        setFilter(TrasformaDateDaUmano({"data":e.target.value}) || undefined) // undefined elimina il filtro
      }}
      placeholder={`Cerca (${count} el.)`}
      size="12"      
    />
  )
  
}

function TabellaRicerca (props) {
  const columns = props.columns;
  const data = props.data;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [elementiCliccabili, setElementiCliccabili] = useState(false);
  const [flagAttesaGenerazioneFile, setFlagAttesaGenerazioneFile] = useState(false);
  const [tipo, setTipo] = useState("");
  const [statoPagina, setStatoPagina] = useState(STATO_VISUALIZZA_TABELLA);
  const [fileLogo,setFileLogo]=useState('');
  const [typeLogo,setTypeLogo]=useState('');
  const [nomeSocieta,setNomeSocieta]=useState('');

  useEffect(() => {
    const e = localStorage.getItem('emaElementiPerPagina');
    if (e!=null) setRowsPerPage(e);
  }, [])

  useEffect(() => {
    if (flagAttesaGenerazioneFile) {
      estraiExcelPDF();
    }
  }, [flagAttesaGenerazioneFile])

  useEffect(() => {
    if (props.callbackSelezionaElemento === undefined) {
      setElementiCliccabili(false);
    } else {
      setElementiCliccabili(true);
    }
  }, [props]) 
  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    localStorage.setItem("emaElementiPerPagina", event.target.value);
    setPage(0);
  };  

  const defaultColumn = {
    // interfaccia di default per l'impostazione del filtro
    FiltroNormale: FiltroColonnaNormale,
    FiltroSuData: FiltroColonnaData
  };

  const ordinaDati = (prev, curr, columnId) => {
    if (prev.original[columnId]===null) return -1;
    if (curr.original[columnId]===null) return 1;
    if (isNaN(curr.original[columnId])) {
      if (prev.original[columnId].toLowerCase() > curr.original[columnId].toLowerCase()) {
        return 1;
      } else if (prev.original[columnId].toLowerCase() < curr.original[columnId].toLowerCase()) {
        return -1;
      } else {
        return 0;
      }
    } else {
      if (prev.original[columnId] > curr.original[columnId]) {
        return 1;
      } else if (prev.original[columnId] < curr.original[columnId]) {
        return -1;
      } else {
        return 0;
      }
    }
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      sortTypes: { alphanumeric: ordinaDati },
      initialState: {
        sortBy: [{ id: props.campoOrdinamento!==undefined?columns[props.campoOrdinamento].accessor:"", desc: props.ordinamentoDecrescente }]
      },
    },
    useFilters,
    useSortBy,
    useBlockLayout
  );

  /* 
  // Questo non si può fare, altrimenti quando l'utente mette in un campo di ricerca una stringa che fa trovare zero record, sparisce il campo
  // dove sta scrivendo e non ha modo di tornare indietro:
  if (rows.length==0) {    
    return (<>Non ci sono elementi</>)
  }
  */

 // Funzione per calcolare larghezze proporzionali
 const calculateColumnWidths = (data) => {
  const keys = Object.keys(data[0] || {});
  const columnLengths = keys.map((key) => {
    // Suddivide l'intestazione in righe e calcola la lunghezza della riga più lunga
    const headerLines = key.split('\n');
    const headerLength = Math.max(...headerLines.map((line) => line.length)) * 1.2;

    // Suddivide i valori delle celle in righe e calcola la lunghezza della riga più lunga
    const maxValueLength = Math.max(
      ...data.map((row) => {
        if (!row[key]) return 0;
        const cellLines = String(row[key]).split('\n');
        return Math.max(...cellLines.map((line) => line.length));
      })
    );

    return Math.max(headerLength, maxValueLength); // Ritorna la lunghezza massima tra intestazione e celle
  });

  const totalLength = columnLengths.reduce((sum, length) => sum + length, 0);
  return columnLengths.map((length) => length / totalLength); // Ritorna le proporzioni
};

/*
// Stili base
const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  table: {
    display: 'table',
    width: '100%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#000',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderColor: '#000',
  },
  tableCellHeader: {
    padding: 5,
    fontSize: 10,
    fontWeight: 'bold',
    textAlign: 'center',
    borderRightWidth: 1,
    borderColor: '#000',
  },
  tableCell: {
    padding: 5,
    fontSize: 8,
    textAlign: 'center',
    borderRightWidth: 1,
    borderColor: '#000',
    wordBreak: 'break-word', // Gestisce stringhe lunghe
  },
  lastCell: {
    borderRightWidth: 0, // Rimuove il bordo destro per l'ultima cella
  },
});
*/

// Componente principale
const DocumentoPDF = ({ data }) => {
  const columnWidths = calculateColumnWidths(data); // Calcola le larghezze proporzionali
  const keys = Object.keys(data[0] || {});

  var testoIntestazione = "";
  if (props.societa) testoIntestazione += props.societa;
  const dataDocumento = new Intl.DateTimeFormat('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(new Date());

  return (
<Document>
  <Page size="A4" orientation="landscape" style={styles.page}>
    <View style={styles.tabellaRicercaContainer}>
      {/* Intestazione */}
      <View style={styles.tableIntestazione}>
        <View style={{ alignItems: 'center' }}>
          <Text style={{ fontFamily: 'Helvetica-Bold', fontSize: 14 }}>{props.titolo}</Text>
          <Text style={{ fontFamily: 'Helvetica-Oblique', fontSize: 10, marginTop: 2 }}>{testoIntestazione}</Text>
        </View>
      </View>

      {/* Tabella */}
      <View style={styles.tabellaRicerca}>
        {/* Intestazione della tabella */}
        <View style={styles.tabellaRicercaRow}>
          {keys.map((key, index) => (
            <Text
              key={key}
              style={[
                styles.tabellaRicercaCellHeader,
                { flex: columnWidths[index] },
                index === keys.length - 1 && styles.tabellaRicercaLastCell,
                { 
                  whiteSpace: 'normal', // Adatta al contenitore
                  overflow: 'hidden', // Evita fuoriuscite
                  wordBreak: 'break-all'
                }
              ]}
            >
              {key}
            </Text>
          ))}
        </View>

        {/* Righe della tabella */}
        {data.map((row, rowIndex) => (
          <View key={rowIndex} style={styles.tabellaRicercaRow}>
            {keys.map((key, cellIndex) => (
              <Text
                key={cellIndex}
                style={[
                  styles.tabellaRicercaCell,
                  { flex: columnWidths[cellIndex] },
                  cellIndex === keys.length - 1 && styles.tabellaRicercaLastCell,
                  {
                    flexWrap: 'wrap', // Permette il testo di andare a capo
                    whiteSpace: 'normal', // Gestisce spazi e righe
                    overflow: 'hidden', // Evita fuoriuscite dalla cella
                    wordBreak: 'break-all'
                  }
                ]}
                numberOfLines={1} // Tronca dopo una riga
                ellipsizeMode="tail" // Aggiunge "..." alla fine del testo troncato
              >
                {row[key] || ''}
              </Text>
            ))}
          </View>
        ))}
      </View>
    </View>

    <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => `${dataDocumento} - Pagina ${pageNumber} di ${totalPages}`} fixed />
  </Page>
</Document>
  )};

  //async function estraiExcelPDF(columns, rows, nascondiColonne, nascondiColonna, arrayTipiDati, tipo) {    

  async function estraiExcelPDF() {

    var nascondiColonne = props.nascondiColonne;
    var nascondiColonna = props.nascondiColonna;
    var arrayTipiDati = props.arrayTipiDati;

    //console.log(rows); // Utilizza `rows` invece di `data`
    let json = [];
    let elemento = [];
    let descrizioneCampo = "";
    let valoreCampoIniziale = "";
    let valoreCampoFormattato = "";
    var nomeFile = new Date().toISOString().split('T')[0];
    if (props.titolo) {
      nomeFile += " " + props.titolo;    
    } else {
      nomeFile += " Estrazione_GestioneSportiva";
    }
    if (tipo == "XLSX") nomeFile += ".xlsx";
    if (tipo == "PDF") nomeFile += ".pdf";
    
    await new Promise(resolve => setTimeout(resolve, 1000)); // ritardo di 1 secondi

    rows.forEach(row => {
      elemento = {};
  
      columns.forEach((col, i) => {
        let visualizzaColonna = 1;
  
        if (nascondiColonna !== undefined && nascondiColonna === i) {
          visualizzaColonna = 0;
        }
  
        if (nascondiColonne !== undefined && nascondiColonne.includes(i)) {
          visualizzaColonna = 0;
        }

        if (tipo=="PDF" && !col.pdf) {
          visualizzaColonna = 0;
        }
  
        if (visualizzaColonna === 1) {
          descrizioneCampo = col["etichetta"];
          if (col["pdf"] && col["pdf"]!=1) descrizioneCampo = col["pdf"];
          valoreCampoIniziale = row.values[col.accessor];
  
          valoreCampoFormattato = valoreCampoIniziale;
  
          if (arrayTipiDati !== undefined) {
                      
            if (arrayTipiDati[i] == 'data') {
              if (tipo == "XLSX") {
                if (typeof valoreCampoIniziale === 'string') {
                  const [year, month, day] = valoreCampoIniziale.split('-');
                  if (day) valoreCampoFormattato = new Date(year, month - 1, day);
                } else if (valoreCampoIniziale instanceof Date) {
                  valoreCampoFormattato = new Date(valoreCampoIniziale.getFullYear(), valoreCampoIniziale.getMonth(), valoreCampoIniziale.getDate());
                } else {
                  const tempDate = new Date(valoreCampoIniziale);
                  valoreCampoFormattato = new Date(tempDate.getFullYear(), tempDate.getMonth(), tempDate.getDate());
                }
              }
              if (tipo == "PDF") {
                if (typeof valoreCampoIniziale === 'string') {
                  const [year, month, day] = valoreCampoIniziale.split('-');
                  if (day) valoreCampoFormattato = day+"/"+month+"/"+year;
                } else if (valoreCampoIniziale instanceof Date) {
                  valoreCampoFormattato = valoreCampoIniziale.getDate()+"/"+valoreCampoIniziale.getMonth()+"/"+valoreCampoIniziale.getFullYear();
                } else {
                  valoreCampoFormattato = valoreCampoIniziale.getDate()+"/"+valoreCampoIniziale.getMonth()+"/"+valoreCampoIniziale.getFullYear();
                }
              }
            }

            if (arrayTipiDati[i] === 'importo') valoreCampoFormattato = FormattaImportoVideo(valoreCampoIniziale);
            if (arrayTipiDati[i] === 'numero') valoreCampoFormattato = FormattaNumeroVideo(valoreCampoIniziale);
            if (arrayTipiDati[i] === 'vincolo') valoreCampoFormattato = FormattaVincoloVideo(valoreCampoIniziale);
            if (arrayTipiDati[i] === 'flag') valoreCampoFormattato = FormattaFlagVideo(valoreCampoIniziale);
            if (arrayTipiDati[i] === 'statoAdempimento') {
              if (valoreCampoIniziale === 0) valoreCampoFormattato = "Da Eseguire";
              if (valoreCampoIniziale === 1) valoreCampoFormattato = "Eseguito";
            }
            if (arrayTipiDati[i] === 'accessi') {
              if (valoreCampoIniziale == -1) valoreCampoFormattato = "Illimitato";
              else valoreCampoFormattato = valoreCampoIniziale;
            }
            if (arrayTipiDati[i] === 'report_azione') {
              if (valoreCampoIniziale == 'IViscritto') valoreCampoFormattato = "Inserimento con inserimento privilegi";
              if (valoreCampoIniziale == 'MViscritto') valoreCampoFormattato = "Modifica con variazione dei privilegi";
              if (valoreCampoIniziale == 'I') valoreCampoFormattato = "Inserimento";
              if (valoreCampoIniziale == 'IVabb') valoreCampoFormattato = "Inserimento con variazione del valore dell'importo";
              if (valoreCampoIniziale == 'M') valoreCampoFormattato = "Modifica";
              if (valoreCampoIniziale == 'MVabb') valoreCampoFormattato = "Modifica con modifica del valore dell'importo";
              if (valoreCampoIniziale == 'C') valoreCampoFormattato = "Eliminazione";
            }
          }
          elemento[descrizioneCampo] = valoreCampoFormattato;
        }
      });
  
      json.push(elemento);
    });
  
    if (tipo == "XLSX") {
      const worksheet = XLSX.utils.json_to_sheet(json);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, nomeFile);
    }

    if (tipo == "PDF") {
      //console.log(JSON.stringify(json, null, 2))
      const blob = await pdf(<DocumentoPDF data={json} />).toBlob(); // Convert the document to a Blob
      const url = URL.createObjectURL(blob); // Create a temporary URL
      const link = document.createElement("a"); // Create a link element
      link.href = url; // Assign the Blob URL
      link.download = nomeFile; // Set the download filename
      document.body.appendChild(link); // Append the link to the document
      link.click(); // Simulate a click to trigger the download
      document.body.removeChild(link); // Clean up
      URL.revokeObjectURL(url); // Release the memory
    }

    setFlagAttesaGenerazioneFile(false);
  } 

  return (
    <>

      {flagAttesaGenerazioneFile ?
      <div style={{color:"#FFFFFF", backgroundColor:"#008055", margin:20, padding:10, border: "1px solid black"}}>
      <span style={{fontSize:20}}>GENERAZIONE DEL FILE RICHIESTO</span>
      <br />
      POSSONO ESSERE NECESSARI ALCUNI MINUTI, ATTENDERE
      <span style={{
        animation: 'lampeggiaPuntini 1s infinite',
        fontWeight: 'bold'
      }}>
        ...
      </span>
    </div>
      :
      <TableContainer style={{backgroundColor: "#a5a5a5", height:"100%"}}>
      <StileTabella>
      <Table stickyHeader>
        <TableHead>
          
          {headerGroups.map((headerGroup) => (
            <TableRow>
              {props.callbackEliminaElemento !== undefined ? <TableCell><div></div></TableCell> :""}
              {props.callbackDettaglioElemento !== undefined ? <TableCell><div></div></TableCell> :""}
              { headerGroup.headers.map((titoloColonna, numeroColonna) => 
                { 
                  if (props.nascondiColonna === numeroColonna) return "";
                  if (props.nascondiColonne !== undefined) {
                    if (props.nascondiColonne.includes(numeroColonna)) return "";
                  }
                  return (
                    <TableCell> 
                      <div  {...titoloColonna.getHeaderProps(titoloColonna.getSortByToggleProps())} style={{backgroundColor:""}}>
                        <div style={{textAlign:'center'}}>{titoloColonna.render("etichetta")}
                          <span>{titoloColonna.isSorted ? (titoloColonna.isSortedDesc ? " 🔽" : " 🔼") : ""}</span>
                        </div>
                      </div>
                      <div>{titoloColonna.canFilter ? 
                        props.arrayTipiDati && props.arrayTipiDati[numeroColonna]==='data' ? titoloColonna.render("FiltroSuData") : titoloColonna.render("FiltroNormale")
                      : ""}</div>
                    </TableCell>
                  )
                }
              )}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
              prepareRow(row);
              var flagAllarme=false;
              var flagAvviso=false;              
              if (props.campoAllarme!==undefined) {
                switch (props.condizioneAllarme) {
                  case ">": if (row.cells[props.campoAllarme].value>props.valoreAllarme) flagAllarme=true; break;
                  case ">=": if (row.cells[props.campoAllarme].value>=props.valoreAllarme) flagAllarme=true; break;
                  case "==": if (row.cells[props.campoAllarme].value==props.valoreAllarme) flagAllarme=true; break;
                  case "<": if (row.cells[props.campoAllarme].value<props.valoreAllarme) flagAllarme=true; break;
                  case "<=": if (row.cells[props.campoAllarme].value<=props.valoreAllarme) flagAllarme=true; break;
                } 
              }
              if (!flagAllarme && props.campoAvviso!==undefined) {     
                switch (props.condizioneAvviso) {
                  case ">": if (row.cells[props.campoAvviso].value>props.valoreAvviso) flagAvviso=true; break;
                  case ">=": if (row.cells[props.campoAvviso].value>=props.valoreAvviso) flagAvviso=true; break;
                  case "==": if (row.cells[props.campoAvviso].value==props.valoreAvviso) flagAvviso=true; break;
                  case "<": if (row.cells[props.campoAvviso].value<props.valoreAvviso) flagAvviso=true; break;
                  case "<=": if (row.cells[props.campoAvviso].value<=props.valoreAvviso) flagAvviso=true; break;
                } 
              }
              var classeSfondo="";
              var classeCarattere="carattereRigaNormale";
              if (flagAllarme) {
                classeSfondo="sfondoRigaAllarme"; 
                classeCarattere="carattereRigaAllarme";
              } else {
                if (flagAvviso) {
                  classeSfondo="sfondoRigaAvviso"; 
                  classeCarattere="carattereRigaAvviso";
                } else {
                  if (index % 2) classeSfondo="sfondoRigaPari"; else classeSfondo="sfondoRigaDispari";
                }
              }

              // questi sovascrivono classeSfondo e classeCarattere se esiste la condizione che li attiva
              var coloreSfondo = "";
              var coloreCarattere = "";
              if (props.coloriPersonalizzati!==undefined) {
                var flag;
                for (var i=0; i<props.coloriPersonalizzati.length; i++) {
                  flag=false;

                  //console.log(props.coloriPersonalizzati[i].campo)

                  switch (props.coloriPersonalizzati[i].condizione) {
                    case ">": if (row.cells[props.coloriPersonalizzati[i].campo].value>props.coloriPersonalizzati[i].valore) flag=true; break;
                    case ">=": if (row.cells[props.coloriPersonalizzati[i].campo].value>=props.coloriPersonalizzati[i].valore) flag=true; break;
                    case "==": if (row.cells[props.coloriPersonalizzati[i].campo].value==props.coloriPersonalizzati[i].valore) flag=true; break;
                    case "<": if (row.cells[props.coloriPersonalizzati[i].campo].value<props.coloriPersonalizzati[i].valore) flag=true; break;
                    case "<=": if (row.cells[props.coloriPersonalizzati[i].campo].value<=props.coloriPersonalizzati[i].valore) flag=true; break;
                  }

                  if (flag) {
                    if (index % 2) {
                      coloreSfondo=props.coloriPersonalizzati[i].sfondoRigaPari;
                      coloreCarattere=props.coloriPersonalizzati[i].carattereRigaPari;
                    } else {
                      coloreSfondo=props.coloriPersonalizzati[i].sfondoRigaDispari;
                      coloreCarattere=props.coloriPersonalizzati[i].carattereRigaDispari;
                    }
                  }

                }              
              }

              var coloriPallini = [];

              if (props.palliniColorati!==undefined) {
                var flag;
                for (var i=0; i<props.palliniColorati.length; i++) {
                  flag=false;

                  //console.log(props.coloriPersonalizzati[i].campo)
                  //console.log("CONFRONTO"+row.cells[props.palliniColorati[i].campo].value+"CON"+props.palliniColorati[i].valore);
                  //console.log(i+"CONFRONTO"+row.cells[props.palliniColorati[i].campo].value+"CON"+props.palliniColorati[i].valore);
                  //console.log("CONDIZIONE"+props.palliniColorati[i].condizione);
                  switch (props.palliniColorati[i].condizione) {
                    case ">": if (row.cells[props.palliniColorati[i].campo].value>props.palliniColorati[i].valore) flag=true; break;
                    case ">=": if (row.cells[props.palliniColorati[i].campo].value>=props.palliniColorati[i].valore) flag=true; break;
                    case "==": if (row.cells[props.palliniColorati[i].campo].value==props.palliniColorati[i].valore) flag=true; break;
                    case "<": if (row.cells[props.palliniColorati[i].campo].value<props.palliniColorati[i].valore) flag=true; break;
                    case "<=": if (row.cells[props.palliniColorati[i].campo].value<=props.palliniColorati[i].valore) flag=true; break;
                  }
                
                  if (flag) {
                    if (index % 2) {
                      coloriPallini[i]=props.palliniColorati[i].colorePallinoRigaPari;
                    } else {
                      coloriPallini[i]=props.palliniColorati[i].colorePallinoRigaDispari;
                    }
                  }else{
                    //qui entro se lacondizione non è soddisfatta (metto un pallino del colore dello sfondo)
                    if (index % 2) {
                      coloriPallini[i]= 'rgba(0, 0, 0, 0)';//pallino trasparente
                    } else {
                      coloriPallini[i]= 'rgba(0, 0, 0, 0)';//pallino trasparente
                    }
                  }
                }              
              }

              return (
                <TableRow
                  key={index}
                  //onClick={()=> { if (elementiCliccabili) props.callbackSelezionaElemento(row.cells[props.campoChiave].value); }} 
                  className={classeSfondo + (elementiCliccabili ? " puntatoreMano" : "")}
                  style={{backgroundColor:coloreSfondo}}
                >

                {props.callbackEliminaElemento !== undefined ? 
                <TableCell style={{width:1}}>
                  <div style={{ display: "inline-flex", alignItems: "center" }}>
                  <img onClick={() => props.callbackEliminaElemento(row.cells[props.campoChiave].value)} src={Cestino}/>

                  {props.palliniColorati !== undefined ? 
                    <>
                    {coloriPallini.map((colore, index) => (
                      <>
                      <span
                        onClick={()=> {if (elementiCliccabili) props.callbackSelezionaElemento(row.cells[props.campoChiave].value);}} 
                        key={index} 
                        style={{
                          display: "inline-block",
                          width: 10,
                          height: 10,
                          backgroundColor: colore,
                          borderRadius: "50%",
                          marginRight: 5,
                        }}
                      ></span>
                      </>
                    ))}

                    </>
                  :""}
                  </div>
                  </TableCell> 
                :""}

                {props.callbackDettaglioElemento !== undefined ? 
                <TableCell style={{width:1}}>
                  <Button style={{fullWidth:'true', maxHeight: '25px', padding: '2px',margin: "2px",marginRight:'4px',fontSize: '12px'}}//riducendo il bottone si sono avvicinate le righe tabella nel modo corretto
                  color="success" onClick={() => props.callbackDettaglioElemento(row.cells[props.campoChiave].value)}>Dettaglio</Button>
                  </TableCell> 
                :""}

                  {row.cells.map((contenutoCella, numeroColonna) => {
                    if (props.nascondiColonna === numeroColonna) return "";
                    if (props.nascondiColonne !== undefined) {
                      if (props.nascondiColonne.includes(numeroColonna)) return "";
                    }                    
                    return (
                        <TableCell
                          key={numeroColonna}                          
                          onClick={()=> { 
                            if (elementiCliccabili) props.callbackSelezionaElemento(row.cells[props.campoChiave].value);}} 
                        >
                        {props.arrayTipiDati ?
                          props.arrayTipiDati[numeroColonna] === 'data' ?
                              <div align="right" className={classeCarattere} style={{color:coloreCarattere}}><TrasformaDateAUmano data={contenutoCella.value}></TrasformaDateAUmano></div>
                          : props.arrayTipiDati[numeroColonna] === 'importo' ?
                            <div align="right" className={classeCarattere} style={{color:coloreCarattere}}>{FormattaImportoVideo(contenutoCella.value)}</div>
                          : props.arrayTipiDati[numeroColonna] === 'numero' ?
                            <div align="right" className={classeCarattere} style={{color:coloreCarattere}}>{FormattaNumeroVideo(contenutoCella.value)}</div>
                          : props.arrayTipiDati[numeroColonna] === 'vincolo' ?
                            <div align="left" className={classeCarattere} style={{color:coloreCarattere}}>{FormattaVincoloVideo(contenutoCella.value)}</div>
                          : props.arrayTipiDati[numeroColonna] === 'flag' ?
                            <div align="left" className={classeCarattere} style={{color:coloreCarattere}}>{FormattaFlagVideo(contenutoCella.value)}</div>
                          : props.arrayTipiDati[numeroColonna] === 'statoAdempimento' ?
                          <div align="left" className={classeCarattere} style={{color:coloreCarattere}}>
                            {contenutoCella.value === '0' ? <>Da Eseguire</> : ""}
                            {contenutoCella.value === '1' ? <>Eseguito</> : ""}
                          </div>
                          : props.arrayTipiDati[numeroColonna] === 'accessi' ?
                          <div align="left" className={classeCarattere} style={{color:coloreCarattere}}>
                            {contenutoCella.value == '-1' ? <>Illimitato</> : <>{contenutoCella.value}</>}
                          </div>
                          : props.arrayTipiDati[numeroColonna] === 'report_azione' ?
                          <div align="left" className={classeCarattere} style={{color:coloreCarattere}}>
                            {contenutoCella.value == 'I' ? <>Inserimento</> : ""}
                            {contenutoCella.value == 'IVabb' ? <>Inserimento con variazione del valore dell'importo</> : ""}
                            {contenutoCella.value == 'M' ? <>Modifica</> : ""}
                            {contenutoCella.value == 'MVabb' ? <>Modifica con variazione del valore dell'importo</> : ""}
                            {contenutoCella.value == 'C' ? <>Eliminazione</> : ""}
                            {contenutoCella.value == 'IViscritto' ? <>Inserimento con inserimento privilegi</> : ""}
                            {contenutoCella.value == 'MViscritto' ? <>Modifica con variazione dei privilegi</> : ""}
                          </div>
                          : <div align="left" className={classeCarattere} style={{color:coloreCarattere}}>{contenutoCella.value}</div>
                        : <div align="left" className={classeCarattere} style={{color:coloreCarattere}}>{contenutoCella.value}</div>
                        }
                        
                        </TableCell>
                    );
                  })}
                </TableRow>
              );
          })}
        </TableBody>
      </Table>
      </StileTabella>
      </TableContainer>
      }
    
      <div style={{display:"flex"}}>
      <div className="schedaSpazioInferiore" style={{flex:1, display:'flex', flexDirection: 'row'}}>        
        <div style={{marginTop:10}}>
          <Button color="success" onClick={() => {{                    
              setTipo("XLSX"); 
              setFlagAttesaGenerazioneFile(true);
            }}
          }>
            Scarica XSLX
          </Button>
        </div>
      {props.titolo && <div style={{marginTop:10, marginLeft:10}}><Button color="success"  onClick={() => {{
          setTipo("PDF");
          setFlagAttesaGenerazioneFile(true);          
        }}}>Scarica PDF</Button></div>}
      </div>        

      <div style={{flex:1}}>
        <TablePagination
            rowsPerPageOptions={[20, 50, 100, 200, 500]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Elementi per pagina"
            labelDisplayedRows={
              ({ from, to, count }) => {
                //return 'Da ' + from + ' a ' + to + ' di ' + count
                return 'Pagina ' + (page+1) + ' di ' + Math.ceil(count/rowsPerPage)
              }
            }
        />
      </div>

      </div>



    </>
  )
}

const StileTabella = styled.div `
  table {    
    border-spacing: 0;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th {
        font-weight: bold;
        border-top: 1px solid #4488CC;
        border-bottom: 1px solid #4488CC;
        background-color: #DDEEFF;
        white-space: nowrap;              
    }

    th {
      padding-top: 0.0rem;
      padding-bottom: 0.0rem;
      padding-left: 0.1rem;
      padding-right: 0.1rem;
      border-right: 0;
      border-left: 0;

      :last-child {
        border-right: 0;
      }
    }

    td {
      padding-top: 0.0rem;
      padding-bottom: 0.0rem;
      padding-left: 0.1rem;
      padding-right: 0.1rem;
      border-right: 1px solid #d0d0d0;
      border-left: 0;

      :last-child {
        border-right: 0;
      }
    }    
  }
`;

export default TabellaRicerca;