import React, {Fragment,useState,useRef} from 'react';
import { useEffect } from 'react';

import {
    Table,Col, Row, Card, CardBody,
    CardTitle, Button, Form, FormGroup, Label, Input,Modal
} from 'reactstrap';
import Parametri from '../../parametri';
import TendinaEditabile from '../TendinaEditabile.js';
import TrasformaDateAUmano from '../TrasformaDateAUmano.js';

import moment from 'moment';//npm install moment

import Clessidra from '../Clessidra.js';
import Modale from '../Modale.js';
import FinestraDialogo from '../FinestraDialogo.js';
import Interrogativo from '../../immagini/interrogativo-azzurro.png';
import controllaDataAAAAMMGG from '../controllaDataAAAAMMGG.js';

const STATO_INDEFINITO = 0;
const STATO_INSERISCI = 1;
const STATO_MEMORIZZAZIONE = 2;//utilizzato per eseguire le operazioni di inserimento/modifica/eliminazione record
const STATO_ERRORE_CONNESSIONE = 3;
const STATO_OPERAZIONE_FALLITA = 4;
const STATO_RICERCA_DATI = 5;//utilizzato nel caso modifica e cancella quando vado a ricercare i dati per popolare i campi della scheda
const STATO_RICERCA_DATI_CORRETTA = 6;//utilizzato a valle della ricerca dati record in caso di modifica o cancellazione di un record


function FormGridFormRow(props) {

    var today = new Date();
    let sMese = String(today.getMonth()+1);
    if(sMese.length === 1) sMese = "0"+sMese;
    let sGiorno = String(today.getDate());
    if(sGiorno.length === 1) sGiorno = "0"+sGiorno;
    
    const dataOggi = today.getFullYear()+'-'+sMese+'-'+sGiorno;
    const dataOggiPiuUnAnno = moment(dataOggi).add(1, "years").format("YYYY-MM-DD");
    
    const [operazione,setOperazione] = useState('');
    const [variazioneCampi,setVariazioneCampi]=useState(0);

    const [flagAttivaModaleRichiediPermessoInserire, setFlagAttivaModaleRichiediPermessoInserire] = useState(0);
    const [flagAttivaModaleInserisciEnte, setFlagAttivaModaleInserisciEnte] = useState(0);
    const [flagAttivaModaleInserisciIscritto, setFlagAttivaModaleInserisciIscritto] = useState(0);
    const [flagAttivaModaleRichiediNumeroTessera, setFlagAttivaModaleRichiediNumeroTessera] = useState(0);
    const [flagAttivaModaleInserisciCategoria, setFlagAttivaModaleInserisciCategoria] = useState(0);
    const [flagAttivaModaleInserisciTipo, setFlagAttivaModaleInserisciTipo] = useState(0);
    const [flagAttivaModaleInserisciDataInizio, setFlagAttivaModaleInserisciDataInizio] = useState(0);
    const [flagAttivaModaleInfoDataInizio, setFlagAttivaModaleInfoDataInizio] = useState(0);


    //const sponsor   = useRef();
    const dataInizio   = useRef();
    const dataFine   = useRef();
    const numeroTessera   = useRef();
    const tipoVincolo   = useRef();
    const dataInizioVincolo   = useRef();
    const dataFineVincolo   = useRef();
    const note   = useRef();
    //utile per indicazione del cognome in caso di iscritti con più cognomi (o cognomi composti) e/o più nomi:
    const cognome = useRef();

    const [statoPagina,setStatoPagina]=useState(STATO_INDEFINITO);
    const [statoTendinaEnti,setStatoTendinaEnti]=useState(false);
    const [statoTendinaPersone,setStatoTendinaPersone]=useState(false);
    const [statoTendinaCategorie,setStatoTendinaCategorie]=useState(false);
    const [statoTendinaTipi,setStatoTendinaTipi]=useState(false);
    const [statoSettaggioTipoVincolo,setStatoSettaggioTipoVincolo]=useState(false);
    const [flagRicercaDatiPersona,setFlagRicercaDatiPersona]=useState(false);
    

    const [error,setError]=useState('');
    //const [risultatoServer,setRisultatoServer]=useState('');

    const [risultatoServerDatiEnti,setRisultatoServerDatiEnti]=useState('');
    const [risultatoServerDatiPersone,setRisultatoServerDatiPersone]=useState('');
    const [risultatoServerDatiCategorie,setRisultatoServerDatiCategorie]=useState('');
    const [risultatoServerDatiTipi,setRisultatoServerDatiTipi]=useState('');

    const [richiediDistinzCognomeNome,setRichiediDistinzCognomeNome]=useState(0);
    const [permessoInserirePersona,setPermessoInserirePersona]=useState(0);
    const [permessoInserireEnte,setPermessoInserireEnte]=useState(0);
    const [permessoInserireCatTess,setPermessoInserireCatTess]=useState(0);
    const [permessoInserireTipoTess,setPermessoInserireTipoTess]=useState(0);
    
    //const [defaultEnte,setDefaultEnte]=useState('');
    //const [defaultCatTess,setDefaultCatTess]=useState('');
    //const [defaultTipoTess,setDefaultTipoTess]=useState('');
    //const [defaultPersona,setDefaultPersona]=useState('');

    const [dataInizioDefault,setDataInizioDefault]=useState(dataOggi);
    const [dataFineDefault,setDataFineDefault]=useState(dataOggiPiuUnAnno);
    const [numeroTesseraDefault,setNumeroTesseraDefault]=useState('');
    const [tipoVincoloDefault,setTipoVincoloDefault]=useState('');
    const [dataInizioVincoloDefault,setDataInizioVincoloDefault]=useState('');
    const [dataFineVincoloDefault,setDataFineVincoloDefault]=useState('');
    const [noteDefault,setNoteDefault]=useState('');

    const [insTipoVincolo,setInsTipoVincolo]=useState('1');

    const [insEntiEdi,setInsEntiEdi]=useState('');
    const [insPersoneEdi,setInsPersoneEdi]=useState('');
    const [insCategorieEdi,setInsCategorieEdi]=useState('');
    const [insTipiEdi,setInsTipiEdi]=useState('');

    const [disabilitaBottoni,setDisabilitaBottoni]= useState(false);
    const [loading,setLoading]= useState(false);

    const [fineIscritti, setFineIscritti] = useState(0);

    //const [datiPersonaFiltrata,setDatiPersonaFiltrata]=useState('');
    
    useEffect(() => {
        setStatoPagina(STATO_INSERISCI);
        setStatoTendinaEnti(false);
        setStatoTendinaPersone(false);
        setStatoTendinaCategorie(false);
        setStatoTendinaTipi(false);
        setInsTipoVincolo("1");//default del radio in caso di insert senza click differenti rispetto al default da parte dell'utente
        setPermessoInserirePersona(0);
        //setDatiPersonaFiltrata('');
    },[]);

    useEffect(() => {
        if(statoTendinaEnti === true 
            && statoTendinaPersone === true 
            && statoTendinaCategorie === true 
            && statoTendinaTipi === true 
            && statoSettaggioTipoVincolo === true
            && flagRicercaDatiPersona === true){
            setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
        }
    },[statoTendinaEnti,statoTendinaPersone,statoTendinaCategorie,statoTendinaTipi,statoSettaggioTipoVincolo,flagRicercaDatiPersona]);

    useEffect(() => {
        if(permessoInserirePersona===1 && permessoInserireEnte===1 && permessoInserireCatTess===1 && permessoInserireTipoTess===1){
            if(richiediDistinzCognomeNome!==1 && richiediDistinzCognomeNome!==2){
                setDisabilitaBottoni(true);
                fetchOperazioneServer();
            }
        }
    },[permessoInserirePersona,permessoInserireEnte,permessoInserireCatTess,permessoInserireTipoTess]);

    useEffect(() => {
        if(props.ope==='inserisci') setOperazione("tesseramIns");
        if(props.ope==='modifica') setOperazione("tesseramMod");
        if(props.ope==='cancella') setOperazione("tesseramCanc");

        if(props.ope==="modifica" || props.ope==="cancella"){
            //devo eseguire una fetch per popolare i campi della scheda con i dati presi da db per il record in esame:
            // parametri per inviare i dati al server:
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({op:"tesseramRecuperaDati",
                    id:props.id,
                    emak: props.emak,
                    sessionId:props.sessionId
                })
            };
            setStatoPagina(STATO_RICERCA_DATI);

            fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valoreDiRitorno) => {
                    if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                        alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                    }
                    if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                    if(valoreDiRitorno.risultatoOperazione===1){
                    ricercaDatiEnti(props.ope);
                    ricercaDatiPersone(props.ope);
                    ricercaDatiCategorie(props.ope);
                    ricercaDatiTipi(props.ope);
                    settaggioTipoVincolo(valoreDiRitorno.risultatoRitorno[0].TIPO_VINCOLO);
                    setInsEntiEdi(valoreDiRitorno.risultatoRitorno[0].ENTE);
                    //setDefaultEnte(valoreDiRitorno.risultatoRitorno[0].ENTE);
                    let componiPersona="";
                    if(valoreDiRitorno.risultatoRitorno[0].COGNOME !== '' && valoreDiRitorno.risultatoRitorno[0].COGNOME !== undefined) componiPersona = componiPersona + valoreDiRitorno.risultatoRitorno[0].COGNOME;
                    if(valoreDiRitorno.risultatoRitorno[0].NOME !== '' && valoreDiRitorno.risultatoRitorno[0].NOME !== undefined && componiPersona !== "")  componiPersona = componiPersona + " ";
                    if(valoreDiRitorno.risultatoRitorno[0].NOME !== '' && valoreDiRitorno.risultatoRitorno[0].NOME !== undefined)  componiPersona = componiPersona + valoreDiRitorno.risultatoRitorno[0].NOME;
                    if(valoreDiRitorno.risultatoRitorno[0].NUMERO_TESSERA !== '' && valoreDiRitorno.risultatoRitorno[0].NUMERO_TESSERA !== undefined && componiPersona !== "")  componiPersona = componiPersona + " ";
                    if(valoreDiRitorno.risultatoRitorno[0].NUMERO_TESSERA !== '' && valoreDiRitorno.risultatoRitorno[0].NUMERO_TESSERA !== undefined)  componiPersona = componiPersona + "("+valoreDiRitorno.risultatoRitorno[0].NUMERO_TESSERA+")";
                    setInsPersoneEdi(componiPersona);//serve per settare la var di stato se non cambi valore nella tendina in caso di modifica
                    //setDefaultPersona(componiPersona);
                    setInsCategorieEdi(valoreDiRitorno.risultatoRitorno[0].DESCRIZIONE_CATEGORIA);
                    //setDefaultCatTess(valoreDiRitorno.risultatoRitorno[0].DESCRIZIONE_CATEGORIA);
                    setInsTipiEdi(valoreDiRitorno.risultatoRitorno[0].DESCRIZIONE_TIPO);
                    //setDefaultTipoTess(valoreDiRitorno.risultatoRitorno[0].DESCRIZIONE_TIPO);

                    setDataInizioDefault(valoreDiRitorno.risultatoRitorno[0].DATA_INIZIO);
                    setDataFineDefault(valoreDiRitorno.risultatoRitorno[0].DATA_FINE);
                    setDataInizioVincoloDefault(valoreDiRitorno.risultatoRitorno[0].DATA_INIZIO_VINCOLO);
                    setDataFineVincoloDefault(valoreDiRitorno.risultatoRitorno[0].DATA_FINE_VINCOLO);
                    setTipoVincoloDefault(valoreDiRitorno.risultatoRitorno[0].TIPO_VINCOLO);
                    setNumeroTesseraDefault(valoreDiRitorno.risultatoRitorno[0].NUMERO_TESSERA_ENTE);
                    setNoteDefault(valoreDiRitorno.risultatoRitorno[0].NOTE);
                    setFlagRicercaDatiPersona(true);//lo pongo a true perchè nel caso di modifica o cancella non ho bisogno di ricercare i dati della persona
                    }
                    },
                (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            ); 
        }else{
            setStatoPagina(STATO_RICERCA_DATI);
            ricercaDatiEnti(props.ope);
            ricercaDatiCategorie(props.ope);
            ricercaDatiTipi(props.ope);
            if(props.idPersona>0){
                ricercaDatiPersona(props.idPersona);//con l'inserimento di un nuovo tesseramento in ambiente filtrato per persona (props.idPersona) ho necessità di conoscere i dati della persona stessa 
                setStatoTendinaPersone(true);//sblocco la tendina delle persone(in questo caso non mi serve tendina e non faccio ricerca ma lo stato mi serve per sbloccare lo stato pagina)
            }else{
                ricercaDatiPersone(props.ope);
                setFlagRicercaDatiPersona(true);//lo pongo a true perchè nel caso di inserimento senza vincolo fornito da props.idPersona, non ho bisogno di ricercare i dati della persona
            }
            setStatoSettaggioTipoVincolo(true);
        }
    },[props.ope]);


    function settaggioTipoVincolo(valore) {
        setInsTipoVincolo(valore);
        setStatoSettaggioTipoVincolo(true);
    }

    function ricercaDatiPersona(persona){
        //ricerco i dati della persona della quale ho l'id nella props:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"personeRecuperaDati",id:persona,recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
        };

        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                //setRisultatoServerDatiPersona(valoreDiRitorno.risultatoRitorno);
                //compongo quidi i dati della persona:
                let componiPersona = "";
                if(valoreDiRitorno.risultatoRitorno[0].COGNOME !== '' && valoreDiRitorno.risultatoRitorno[0].COGNOME !== undefined) componiPersona = componiPersona + valoreDiRitorno.risultatoRitorno[0].COGNOME;
                if(valoreDiRitorno.risultatoRitorno[0].NOME !== '' && valoreDiRitorno.risultatoRitorno[0].NOME !== undefined && componiPersona !== "") componiPersona = componiPersona + " ";
                if(valoreDiRitorno.risultatoRitorno[0].NOME !== '' && valoreDiRitorno.risultatoRitorno[0].NOME !== undefined) componiPersona = componiPersona + valoreDiRitorno.risultatoRitorno[0].NOME;
                if(valoreDiRitorno.risultatoRitorno[0].NUMERO_TESSERA !== '' && valoreDiRitorno.risultatoRitorno[0].NUMERO_TESSERA !== undefined && componiPersona !== "") componiPersona = componiPersona + " ";
                if(valoreDiRitorno.risultatoRitorno[0].NUMERO_TESSERA !== '' && valoreDiRitorno.risultatoRitorno[0].NUMERO_TESSERA !== undefined) componiPersona = componiPersona + "("+valoreDiRitorno.risultatoRitorno[0].NUMERO_TESSERA+")";
                //setDatiPersonaFiltrata(componiPersona);
                setInsPersoneEdi(componiPersona);
                setFlagRicercaDatiPersona(true);
                }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }

    function ricercaDatiEnti(operazione) {
        //ricerco gli enti e le federazioni che mi servono per popolare le tendine:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoFederEntiTesser",recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                setRisultatoServerDatiEnti(valoreDiRitorno.risultatoRitorno);
                setStatoTendinaEnti(true);
                }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }

    function ricercaDatiPersone(operazione) {
        //ricerco gli enti e le federazioni che mi servono per popolare le tendine:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoPersone",ridotto:1,recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                setRisultatoServerDatiPersone(valoreDiRitorno.risultatoRitorno);
                setStatoTendinaPersone(true);
                }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }

    function ricercaDatiCategorie(operazione) {
        //ricerco gli enti e le federazioni che mi servono per popolare le tendine:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoCatTesseramento",recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                setRisultatoServerDatiCategorie(valoreDiRitorno.risultatoRitorno);
                setStatoTendinaCategorie(true);
                }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }

    function ricercaDatiTipi(operazione) {
        //ricerco gli enti e le federazioni che mi servono per popolare le tendine:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoTipiTesseram",recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                setRisultatoServerDatiTipi(valoreDiRitorno.risultatoRitorno);
                setStatoTendinaTipi(true);
                }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }
    function tornaElenco() {
        props.callbackTermineModifica();
    }

    function handleCambiamentoMod(event) {
        //event.preventDefault();
        setVariazioneCampi(1);
    }

    function annullaInserimentoDatiNuovi(){
        setPermessoInserirePersona(0);
        setPermessoInserireEnte(0);
        setPermessoInserireTipoTess(0);
        setPermessoInserireCatTess(0);

        setFlagAttivaModaleRichiediPermessoInserire(0);
        setFlagAttivaModaleInserisciEnte(0);
        setFlagAttivaModaleInserisciIscritto(0);
        setFlagAttivaModaleRichiediNumeroTessera(0);
        setFlagAttivaModaleInserisciCategoria(0);
        setFlagAttivaModaleInserisciTipo(0);
        setFlagAttivaModaleInserisciDataInizio(0);
        setFlagAttivaModaleInfoDataInizio(0);
        setFineIscritti(0);
        //setStatoPagina(STATO_INSERISCI);
    }

    function attivaModaleInfoDataInizio(){
        setFlagAttivaModaleInfoDataInizio(1);
    }

    function confermaInserimentoDatiNuovi(){
        //setStatoPagina(STATO_INSERISCI);
        setPermessoInserirePersona(1);
        setPermessoInserireEnte(1);
        setPermessoInserireTipoTess(1);
        setPermessoInserireCatTess(1);
        //fetchOperazioneServer();
    }

    function aggiornaDataFine(dataInizio){
        let dataInizioPiuUnAnnoFinale = moment(dataInizio).add(1, "years").format("YYYY-MM-DD");
        dataFine.current.value=dataInizioPiuUnAnnoFinale;
    }

    function fetchOperazioneServer(bypassaControlli=0) {
        //invio l'azione da fare sul server (aggiungi/togli) e l'elemento in esame al server che mi ritornerà l'elenco aggiornato
        let controlli=0;

        //cancello comunque;
        if(props.ope!=='cancella' && bypassaControlli===0){
            var dInizio = controllaDataAAAAMMGG(dataInizio.current.value,2015);
            var dFine = controllaDataAAAAMMGG(dataFine.current.value,2015);

            if(dInizio == ''){
                controlli = 1;
                setFlagAttivaModaleInserisciDataInizio(1);
            }
            if (dInizio == "errore") {
                controlli = 1;
                alert("Data di inizio tesseramento non valida (anno minimo 2015)");
                setDisabilitaBottoni(false);
                annullaInserimentoDatiNuovi();
            }
            if (dFine == "errore") {
                controlli = 1;
                alert("Data di fine tesseramento non valida (anno minimo 2015)");
                setDisabilitaBottoni(false);
                annullaInserimentoDatiNuovi()
            }

            if(note.current.value.length>500){
                controlli = 1;
                alert("Il campo Note contiene troppi caratteri (massimo accettato 500)");
                setDisabilitaBottoni(false);
            }
            
            if(insEntiEdi=== ''){
                controlli = 1;
                setFlagAttivaModaleInserisciEnte(1);
            }
            if(insPersoneEdi === ''){
                controlli = 1;
                setFlagAttivaModaleInserisciIscritto(1);
            }
            if(insCategorieEdi === ''){
                controlli = 1;
                setFlagAttivaModaleInserisciCategoria(1);
            }
            if(insTipiEdi === ''){
                controlli = 1;
                setFlagAttivaModaleInserisciTipo(1);
            }
        }

        if(controlli===0){ 
            if(props.ope==='modifica' && variazioneCampi===0){
                //in relatà non e stata fatta alcuna variazione nei campi originali per cui torno ad elenco:
                tornaElenco();
            }else{
                // parametri per inviare i dati al server:
                let iVincolo ='';
                let fVincolo ='';
                let pers ='';
                if(dataInizioVincolo.current !== '' && dataInizioVincolo.current !== undefined && dataInizioVincolo.current !== null) iVincolo = dataInizioVincolo.current.value; 
                else iVincolo = '';
                if(dataFineVincolo.current  !== '' && dataFineVincolo.current !== undefined && dataFineVincolo.current !== null) fVincolo = dataFineVincolo.current.value;
                else fVincolo = '';

                pers=insPersoneEdi;
                let cog="";
                if(richiediDistinzCognomeNome===1 && cognome.current !== undefined && cognome.current !== null) cog = cognome.current.value;

                setLoading(true);
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: props.ope!=='cancella' ?  
                    JSON.stringify({op:operazione,
                        ente:insEntiEdi,
                        persona:pers,
                        cognome:cog,
                        permessoInserirePersona:permessoInserirePersona,
                        permessoInserireEnte:permessoInserireEnte,
                        permessoInserireCatTess:permessoInserireCatTess,
                        permessoInserireTipoTess:permessoInserireTipoTess,
                        categoria:insCategorieEdi,
                        tipo:insTipiEdi,
                        dataInizio:dInizio,
                        dataFine:dFine,
                        numeroTessera:numeroTessera.current.value,
                        dataInizioVincolo:iVincolo,
                        dataFineVincolo:fVincolo,
                        tipoVincolo:insTipoVincolo,
                        note:note.current.value,
                        idElemento:props.id,
                        emak: props.emak,
                        sessionId:props.sessionId
                    })
                    : 
                        JSON.stringify({op:operazione,
                        idElemento:props.id,
                        emak: props.emak,
                        sessionId:props.sessionId})
                };
            
                // qui faccio la chiamata alla pagina sul server, quindi ne leggo la risposta che io trasformo in oggetto json 
                // con .json() ottenendo "valore_di_ritorno": in questo oggetto json ho "risultato_ritorno" che metto nella 
                // variabile di stato "risultato_server"
                //setStatoPagina(STATO_MEMORIZZAZIONE);

                fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                    .then(risposta => risposta.json())
                    .then(
                    (valoreDiRitorno) => {
                        setLoading(false);
                        if(valoreDiRitorno.risultatoOperazione===1){
                            //setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                            tornaElenco();
                        }

                        if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                        if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                        
                            if(valoreDiRitorno.mess !== undefined){
                                //if(valoreDiRitorno.mess==="Indicare numero di tessera per individuazione") setStatoPagina(STATO_RICHIEDI_NUMERO_TESSERA);
                                if(valoreDiRitorno.mess==="Indicare numero di tessera per individuazione"){
                                    setFlagAttivaModaleRichiediNumeroTessera(1);
                                }
                                //if(valoreDiRitorno.mess.indexOf("Richiedi permesso per inserire iscritto") !== -1) setStatoPagina(STATO_RICHIEDI_PERMESSO_INSERIRE);
                                if(valoreDiRitorno.mess==="Richiedi permesso per inserire iscritto"){
                                    setFlagAttivaModaleRichiediPermessoInserire(1);
                                    setPermessoInserirePersona(-1);
                                } 
                                if(valoreDiRitorno.mess.indexOf("Indica quale è cognome e quale nome") !== -1){
                                    setFlagAttivaModaleRichiediPermessoInserire(1);
                                    setRichiediDistinzCognomeNome(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Reindica quale è cognome e quale nome") !== -1){
                                    setFlagAttivaModaleRichiediPermessoInserire(1);
                                    setRichiediDistinzCognomeNome(2);
                                } 
                                if(valoreDiRitorno.mess.indexOf("Richiedi permesso per inserire ente fed") !== -1){
                                    setPermessoInserireEnte(-1);
                                    setFlagAttivaModaleRichiediPermessoInserire(1);
                                } 
                                if(valoreDiRitorno.mess.indexOf("Richiedi permesso per inserire cat tess") !== -1){
                                    setPermessoInserireCatTess(-1);
                                    setFlagAttivaModaleRichiediPermessoInserire(1);
                                } 
                                if(valoreDiRitorno.mess.indexOf("Richiedi permesso per inserire tipo tess") !== -1){
                                    setPermessoInserireTipoTess(-1);
                                    setFlagAttivaModaleRichiediPermessoInserire(1);
                                } 
                                if(valoreDiRitorno.mess.indexOf("Indicare l'iscritto da tendina") !== -1){
                                    setFlagAttivaModaleInserisciIscritto(1);
                                }  
                                if(valoreDiRitorno.mess.indexOf("Indicare l'ente da tendina") !== -1){
                                    setFlagAttivaModaleInserisciEnte(1);
                                }  
                                if(valoreDiRitorno.mess.indexOf("Indicare la categoria da tendina") !== -1){
                                    setFlagAttivaModaleInserisciCategoria(1);
                                }  
                                if(valoreDiRitorno.mess.indexOf("Indicare il tipo da tendina") !== -1){
                                    setFlagAttivaModaleInserisciTipo(1);
                                }  
                                if(valoreDiRitorno.mess.indexOf("Data inizio non valorizzata") !== -1){
                                    setFlagAttivaModaleInserisciDataInizio(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Iscritti Terminati") !== -1){
                                    setFineIscritti(1);
                                }
                            }
                            //altrimenti vado in errore generico
                            if(!valoreDiRitorno.mess) setStatoPagina(STATO_OPERAZIONE_FALLITA);
                        } 
                    },
                    (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
                );
            }
        }
    }

    //JSON.stringify(risultatoServer)
        return (
            

            <Fragment>
               
          
            
                {/*<CardTitle>valore :{insSponsorEdi}</CardTitle>*/}
                {/*eccola {props.sessionId}*/}
            
                {statoPagina === STATO_MEMORIZZAZIONE ?  <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}
                {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1C_Tesse {error.message}</div> :""}
                {statoPagina === STATO_OPERAZIONE_FALLITA ?  <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}
                {statoPagina === STATO_RICERCA_DATI ? 
                <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}

                {statoPagina === STATO_RICERCA_DATI_CORRETTA ?
                
                <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>

                    {flagAttivaModaleInfoDataInizio=== 1 ?
                            <Modale 
                            titolo="Informativa date"
                            flagErrore={true}
                            contenuto={<div>Selezionando la data di inizio tesseramento verrà di volta in volta suggerita la data di fine tesseramento distanziata di un anno rispetto al dato scelto. E' comunque possibile procedere successivamente alla modifica della data di fine tesseramento</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModaleInserisciDataInizio===1 ?
                        <Modale 
                            titolo="Richiesta data inizio"
                            flagErrore={true}
                            contenuto={<div>Non è stata indicata la data inizio, che è necessaria</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModaleInserisciTipo===1 ?
                        <Modale 
                            titolo="Richiesta tipo"
                            flagErrore={true}
                            contenuto={<div>Non è stato indicato il tipo di tesseramento, che è necessario</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModaleInserisciCategoria===1 ?
                        <Modale 
                            titolo="Richiesta categoria"
                            flagErrore={true}
                            contenuto={<div>Non è stata indicata la categoria, che è necessaria</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                                }    
                            ]}
                        />
                    :""}

                    {fineIscritti=== 1 ?
                        <Modale 
                        titolo="Informativa numero massimo iscritti"
                        flagErrore={true}
                        contenuto={<div>E' stato raggiunto il numero massimo di iscritti</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                            }    
                        ]}
                    />
                    :""}

                    {flagAttivaModaleInserisciIscritto===1 ?
                        <Modale 
                            titolo="Richiesta iscritto"
                            flagErrore={true}
                            contenuto={<div>Non è stato indicato l'iscritto, che è necessario</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModaleInserisciEnte===1 ?
                        <Modale 
                            titolo="Richiesta ente"
                            flagErrore={true}
                            contenuto={<div>Non è stato indicato l'ente, che è necessario</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModaleRichiediPermessoInserire === 1 ?
                        
                        <Modale 
                        titolo="Richiesta permesso inserimento"
                        flagErrore={true}
                        contenuto={<div>
                            {permessoInserirePersona===-1 ? <>Vuoi davvero inserire {insPersoneEdi} nell'elenco degli iscritti?<br/></> :""}
                            {permessoInserireEnte===-1 ? <>Vuoi davvero inserire {insEntiEdi} nell'elenco degli enti/federazioni?<br/></> :""}
                            {permessoInserireCatTess===-1 ? <>Vuoi davvero inserire {insCategorieEdi} nell'elenco delle categorie di tesseramento?<br/></> :""}
                            {permessoInserireTipoTess===-1 ? <>Vuoi davvero inserire {insTipiEdi} nell'elenco dei tipi di tesseramento?<br/></> :""}

                            {richiediDistinzCognomeNome===1 && permessoInserirePersona!==-1 ? <>Indica qual'è il Cognome dell'Iscritto che intendi inserire:<br/></> :""} 
                            {richiediDistinzCognomeNome===2 && permessoInserirePersona!==-1 ? <>Indicazione del Cognome non ritenuta valida. Indicare nuovamente qual'è il Cognome dell'Iscritto che intendi inserire:<br/></> :""}
                        
                            {(richiediDistinzCognomeNome===1 || richiediDistinzCognomeNome===2) && permessoInserirePersona!==-1?
                                <FormGroup>
                                <Label for="cognome">Cognome</Label>
                                <Input  maxLength={50} type="text" name="cognome" id="cognome"
                                        innerRef={cognome} 
                                         placeholder=""
                                        defaultValue={insPersoneEdi}
                                /> 
                                </FormGroup>
                            :""}
                        </div>}      

                        bottoni={
                            permessoInserirePersona===-1 ? 
                                [{
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);confermaInserimentoDatiNuovi()}
                                },  
                                {
                                "etichetta":"Annulla",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                                }]
                            :[{
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);confermaInserimentoDatiNuovi()}
                                },  
                                {
                                "etichetta":"Annulla",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                                } ]
                        }
                        />
                    :""}

                    {flagAttivaModaleRichiediNumeroTessera===1 ?
                        <Modale 
                            titolo="Richiesta numero tessera"
                            flagErrore={true}
                            contenuto={<div>Nel database ci sono più iscritti con il Cognome ed il Nome: {insPersoneEdi} Indicare il numero di tessera in modo da poter individuare quello desiderato oppure per procedere con l'inserimento di un nuovo iscritto qualora il numero di tessera non corrispondesse a nessuno degli iscritti individuati</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                                }    
                            ]}
                        />
                    :""}

                    <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}>
                            {props.ope === "inserisci" && props.idPersona!==undefined ? <CardTitle>Inserimento Tesseramento di {insPersoneEdi}</CardTitle> :""}
                            {props.ope === "inserisci" && props.idPersona===undefined ? <CardTitle>Inserimento Tesseramento</CardTitle> :""}
                            {props.ope === "modifica" && props.idPersona!==undefined ? <CardTitle>Modifica Tesseramento di {insPersoneEdi}</CardTitle> :""}
                            {props.ope === "modifica" && props.idPersona===undefined ? <CardTitle>Modifica Tesseramento</CardTitle> :""}

                            
                            <Clessidra loading={loading}/>
                            {props.ope === "cancella" ?
                                <FinestraDialogo 
                                    titolo="Conferma eliminazione"
                                    flagErrore={true}                                
                                    contenuto={<div>
                                        Confermi l'eliminazione del tesseramento di <b>{insPersoneEdi}</b> presso <b>{insEntiEdi}</b> con data di inizio tesseramento <b><TrasformaDateAUmano data={dataInizioDefault}></TrasformaDateAUmano></b> ?<br/>                                    
                                    </div>}
                                    bottoni={[
                                        {
                                            "etichetta":"Conferma",
                                            "tipo":"primary",
                                            callback:() => {setDisabilitaBottoni(true);fetchOperazioneServer()}
                                        },
                                        {
                                            "etichetta":"Annulla",
                                            "tipo":"primary",
                                            callback: () => {tornaElenco()}
                                        }
                                    ]}
                                />                        

                            :


                                <>
                                <Row form>  
                                <Col md={6}><FormGroup>
                                <Label>Feder. / Ente di Tesseramento*</Label>
                                <TendinaEditabile
                                    //titolo={"Feder. / Ente di Tesseramento*"}
                                    nomeCampo="COGNOME"
                                    valoreDefault={insEntiEdi}
                                    arrayValori={risultatoServerDatiEnti}
                                    callbackSetVariazioneCampi={setVariazioneCampi}
                                    callbackSetValoreTendEditabile={setInsEntiEdi}
                                ></TendinaEditabile>
                                </FormGroup></Col>
                                {props.idPersona===undefined ?
                                    <Col md={6}><FormGroup>
                                    <Label>Iscritto*</Label>
                                    {props.ope === "inserisci" ?
                                        <TendinaEditabile
                                            //titolo={"Iscritto*"}
                                            nomeCampo="COGNOME"
                                            nomeCampo2="NOME"
                                            nomeCampoParentesi="NUMERO_TESSERA"
                                            valoreDefault={insPersoneEdi}
                                            arrayValori={risultatoServerDatiPersone}
                                            callbackSetVariazioneCampi={setVariazioneCampi}
                                            callbackSetValoreTendEditabile={setInsPersoneEdi}
                                     ></TendinaEditabile>
                                    :""}
                                    {props.ope === "modifica" ? <Label>{insPersoneEdi}</Label> : ""}
                                    </FormGroup></Col>
                                :<Col md={6}><Label>{insPersoneEdi}</Label></Col>}
                                </Row>
                                <Row form>  
                                <Col md={6}><FormGroup>
                                <Label>Categoria di Tesseramento*</Label>
                                
                                <Fragment>
                                <TendinaEditabile
                                    //titolo={"Categoria di Tesseramento*"}
                                    nomeCampo="DESCRIZIONE"
                                    valoreDefault={insCategorieEdi}
                                    arrayValori={risultatoServerDatiCategorie}
                                    callbackSetVariazioneCampi={setVariazioneCampi}
                                    callbackSetValoreTendEditabile={setInsCategorieEdi}
                                ></TendinaEditabile>
                                {risultatoServerDatiCategorie.length===0 ? <Fragment> Esempi di Categorie di Tesseramento: Esordienti, Ragazzi, Cadetti ecc... oppure Pallanuoto Under 12, Pallanuoto Under 14, Nuoto Esordienti A, Nuoto Esordienti B ecc...</Fragment>:""}
                                </Fragment>

                                </FormGroup></Col>
                                <Col md={6}><FormGroup>
                                <Label>Tipo di Tesseramento*</Label>
                                <Fragment>
                                <TendinaEditabile
                                    //titolo={"Tipo di Tesseramento*"}
                                    nomeCampo="DESCRIZIONE"
                                    valoreDefault={insTipiEdi}
                                    arrayValori={risultatoServerDatiTipi}
                                    callbackSetVariazioneCampi={setVariazioneCampi}
                                    callbackSetValoreTendEditabile={setInsTipiEdi}
                                ></TendinaEditabile>
                                {risultatoServerDatiTipi.length===0 ? <Fragment> Esempi di Tipi di Tesseramento: Propaganda, Preagonista, Agonista ecc...</Fragment>:""}
                                </Fragment>
                                </FormGroup></Col>    
                                </Row>
                                <Row form>  
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="numeroTessera">Numero Tessera</Label>
                                                <Input maxLength={100} type="text" name="numeroTessera" id="numeroTessera"
                                                innerRef={numeroTessera} 
                                                placeholder=""
                                                defaultValue={numeroTesseraDefault}
                                                onInput={(e)=>{handleCambiamentoMod(e);setNumeroTesseraDefault(numeroTessera.current.value)}}/> 
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="dataInizio">Data Inizio Tesseramento*
                                            <img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => attivaModaleInfoDataInizio()}/>
                                            </Label>
                                                <Input maxLength={10} type="date" name="dataInizio" id="dataInizio"
                                                innerRef={dataInizio} 
                                                placeholder=""
                                                defaultValue={dataInizioDefault}
                                                onInput={(e)=>{handleCambiamentoMod(e);setDataInizioDefault(dataInizio.current.value);aggiornaDataFine(dataInizio.current.value)}}/> 
                                        </FormGroup>
                                    </Col>
                                    <Col md={4}>
                                        <FormGroup>
                                            <Label for="dataFine">Data Fine Tesseramento</Label>
                                                <Input maxLength={10} type="date" name="dataFine" id="dataFine"
                                                innerRef={dataFine} 
                                                placeholder=""
                                                defaultValue={dataFineDefault}
                                                onInput={(e)=>{handleCambiamentoMod(e);setDataFineDefault(dataFine.current.value)}}/>  
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row form>  
                                    <Col md={4}><FormGroup>
                                        <Label for="tipoVincolo">Tipo Vincolo</Label> 
                                        <FormGroup check>                                        
                                            <Label check>
                                                <Input type="radio" name="tipoVincolo" 
                                                innerRef={tipoVincolo} 
                                                defaultValue={tipoVincoloDefault}
                                                defaultChecked = {props.ope === "inserisci" || tipoVincoloDefault === "1" ?  'checked' :""}
                                                value="1"
                                                onInput={(e)=>{setInsTipoVincolo(e.target.value);handleCambiamentoMod(e);setTipoVincoloDefault(e.target.value)}}/>  {'Svincolato'}
            
                                            </Label>
                                        </FormGroup>
                                        <FormGroup check>
                                            <Label check>
                                                <Input type="radio" name="tipoVincolo" 
                                                innerRef={tipoVincolo} 
                                                defaultValue={tipoVincoloDefault}
                                                defaultChecked = {tipoVincoloDefault === "2" ?  'checked' :""}
                                                value="2"
                                                onInput={(e)=>{setInsTipoVincolo(e.target.value);handleCambiamentoMod(e);setTipoVincoloDefault(e.target.value)}}/> {'Vincolo Provvisorio'}
            
                                            </Label>
                                        </FormGroup>
                                        <FormGroup check>
                                            <Label check>
                                                <Input type="radio" name="tipoVincolo"
                                                innerRef={tipoVincolo} 
                                                defaultValue={tipoVincoloDefault}
                                                defaultChecked = {tipoVincoloDefault === "3" ?  'checked' :""}
                                                value="3"
                                                onInput={(e)=>{setInsTipoVincolo(e.target.value);handleCambiamentoMod(e);setTipoVincoloDefault(e.target.value)}}/> {'Vincolo Definitivo'}
                                                
                                            </Label>
                                        </FormGroup>
                                    </FormGroup></Col>
                                    <Col md={4}><FormGroup>
                                        {insTipoVincolo !== "1" ?
                                        <FormGroup>
                                            <Label for="dataInizioVincolo">Data Inizio Vincolo</Label>
                                                <Input maxLength={10} type="date" name="dataInizioVincolo" id="dataInizioVincolo"
                                                innerRef={dataInizioVincolo} 
                                                placeholder=""
                                                defaultValue={dataInizioVincoloDefault}
                                                onInput={(e)=>{handleCambiamentoMod(e);setDataInizioVincoloDefault(dataInizioVincolo.current.value)}}/> 
                                        </FormGroup>
                                        :""}
                                    </FormGroup></Col>
                                    <Col md={4}><FormGroup>
                                        {insTipoVincolo !== "1" ?
                                        <FormGroup>
                                            <Label for="dataFineVincolo">Data Fine Vincolo</Label>
                                                <Input maxLength={10} type="date" name="dataFineVincolo" id="dataFineVincolo"
                                                innerRef={dataFineVincolo} 
                                                placeholder=""
                                                defaultValue={dataFineVincoloDefault}
                                                onInput={(e)=>{handleCambiamentoMod(e);setDataFineVincoloDefault(dataFineVincolo.current.value)}}/>  
                                        </FormGroup>
                                       :""}
                                    </FormGroup></Col>
                                </Row>
                                
                                <Row form>  
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="note">Note</Label>
                                                <Input type="text" name="note" id="note"
                                                innerRef={note} 
                                                placeholder=""
                                                defaultValue={noteDefault}
                                                onInput={(e)=>{handleCambiamentoMod(e);setNoteDefault(note.current.value)}}/> 
                                        </FormGroup>
                                    </Col>
                                </Row>
                                </>
                                }
                                

                                </div>
                                <div className="schedaSpazioInferiore">
                                {props.ope === "inserisci" ? <Button disabled={disabilitaBottoni} color="success" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer()}}>Inserisci</Button> :""}
                                {props.ope === "modifica" && variazioneCampi === 1? <Button disabled={disabilitaBottoni} color="warning" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer()}}>Memorizza Modifiche</Button>:""}
                                
                                <Button disabled={disabilitaBottoni} color="primary" className="mt-2" onClick={() => tornaElenco()} >
                                {(variazioneCampi === 1 && props.ope === "modifica") ?  <Fragment> Annulla </Fragment>: <Fragment>Torna all'elenco</Fragment>}
                                </Button>
                                </div>
                    
                </div>
                :""}
            </Fragment>
        );
}
export default FormGridFormRow;
