
import React, {Fragment,useState} from 'react';
import { useEffect } from 'react';
import {
    Col, Row, Card, CardBody,
    Form,Label,CardTitle,Button,Input
} from 'reactstrap';
import Parametri from '../../parametri';
import TendinaEditabile from '../TendinaEditabile.js';

//https://fullcalendar.io/demos utile per dimostrazioni e manuale

import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import itLocale from '@fullcalendar/core/locales/it';//serve per lingua italiana
import timeGridPlugin from '@fullcalendar/timegrid';

import Clessidra from '../Clessidra.js';

import InterrogativoBianco from '../../immagini/interrogativo-bianco.png';
import Interrogativo from '../../immagini/interrogativo-azzurro.png';

import Modale from '../Modale.js';

const STATO_INIZIO = 0;
const STATO_RICERCA_DATI_CORRETTA = 1;
const STATO_ERRORE_CONNESSIONE = 2;
const STATO_RICERCA = 3;

function ReportPrenotazioniCorsi(props) {

    const [statoPagina,setStatoPagina]=useState(STATO_INIZIO);
    const [variazioneCampi,setVariazioneCampi]=useState(0);
    const [eventiCalendario,setEventiCalendario]=useState([]);
    const [statoTendinaCorso,setStatoTendinaCorso]=useState(false);
    const [statoTendinaIscritto,setStatoTendinaIscritto]=useState(false);
    const [risultatoServerDatiCorso,setRisultatoServerDatiCorso]=useState('');
    const [risultatoServerDatiPersona,setRisultatoServerDatiPersona]=useState('');
    const [defaultCorso,setDefaultCorso]=useState('Tutti');
    const [defaultPersona,setDefaultPersona]=useState('Tutti');
    const [insCorsoEdi,setInsCorsoEdi]=useState('');
    const [insIdCorsoEdi,setInsIdCorsoEdi]=useState('');
    const [insPersonaEdi,setInsPersonaEdi]=useState('');
    const [insIdPersonaEdi,setInsIdPersonaEdi]=useState('');
    const [loading,setLoading]= useState(false);

    const [error,setError]=useState('');

    const [zoom,setZoom]=useState([]);
    const [chiaveZoom,setChiaveZoom]=useState(1);

    const [accorpato,setAccorpato]=useState(1);//normalmente lo fornisco accorpato perchè più leggibile poi se uno vuole toglie il check
    const [orari,setOrari]=useState(0);//normalmente lo fornisco privo di orari perchè più leggibile poi se uno vuole mette il check

    const [spiegaAccorpa,setSpiegaAccorpa]=useState(false);
    const [spiegaOrari,setSpiegaOrari]=useState(false);
    const [spiegaReport,setSpiegaReport]=useState(false);
    
    useEffect(() => {
        setStatoPagina()
        setStatoTendinaIscritto(false);
        ricercaDatiPersona();
        setDefaultCorso("Tutti");
        setDefaultPersona("Tutti");
        cercaDatiCalendario();

        setSpiegaAccorpa(false);
        setSpiegaOrari(false);
        setSpiegaReport(false);
        //possibili zoom:
        //0 - ora
        //1 - mezzora
        //2 - 15 minuti
        //3 - 5 minuti
        //4 - 1 minuti
        let arr=[];
        for (let i=0; i<5; i++) {
            if(i===0) arr[i]="01:00:00";
            if(i===1) arr[i]="00:30:00";
            if(i===2) arr[i]="00:15:00";
            if(i===3) arr[i]="00:05:00";
            if(i===4) arr[i]="00:01:00";
            setZoom(arr);
        }
        setChiaveZoom(1);
    },[]);

    
    useEffect(() => {
        if(statoTendinaCorso=== true && statoTendinaIscritto === true){
            //console.log("RICERCA");
            cercaDatiCalendario();
        }
    },[insCorsoEdi,insPersonaEdi,accorpato]);


    function ricercaDatiPersona() {
        //ricerco tra gli iscritti che mi servono per popolare la tendina delle persone che possono prenotare un corso:
        setLoading(true);
        setStatoPagina(STATO_RICERCA);

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoPersone",recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                  }
                  if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                  if(valoreDiRitorno.risultatoOperazione===1){
                setRisultatoServerDatiPersona(valoreDiRitorno.risultatoRitorno);
                setStatoTendinaIscritto(true);
                ricercaDatiCorsi();
                }
            },
            (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }
    function ricercaDatiCorsi() {
        //ricerco tra i corsi che mi servono per popolare la tendina ed avere quando è attivo:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoCorsi",emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                  }
                  if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                  if(valoreDiRitorno.risultatoOperazione===1){
                        setRisultatoServerDatiCorso(valoreDiRitorno.risultatoRitorno);
                        setStatoTendinaCorso(true);
                        setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                  }
            },
            (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }


    function callbackSetVariazioneCampi(variazioneCampi){
        setVariazioneCampi(variazioneCampi);
    }
    function callbackSetValoreTendEditabilePersona(valore,pos,valoreNonVisibile){
        if(valore!==''){
            setInsPersonaEdi(valore);
            setInsIdPersonaEdi(valoreNonVisibile);
        }
    }
    function callbackSetValoreTendEditabileCorso(valore,pos,valoreNonVisibile){
        if(valore!==''){
            setInsCorsoEdi(valore);
            setInsIdCorsoEdi(valoreNonVisibile);
        }
    }

    function tornaPrenotazioni() {
        props.callbackTermineEscursioneInReport();
    }


    function cercaDatiCalendario(){
        //ricerco le prenotazioni già in calendario per la persona in esame:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoPrenotazCorsiPersona",idPersona:insIdPersonaEdi,idCorso:insIdCorsoEdi,accorpato:accorpato,emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                  }
                  if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                  if(valoreDiRitorno.risultatoOperazione===1){
                        setEventiCalendario(valoreDiRitorno.risultatoRitorno);
                        setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
                  }
            },
            (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }

    function regolaZoom (direzione){
        //direzione === 1 -> aumenta zoom
        //direzione === 0 -> diminuisci zoom
        //cinque step di zoom a seconda del valore della chiave:
        //0 - ora
        //1 - mezzora
        //2 - 15 minuti
        //3 - 5 minuti
        //4 - 1 minuti
        if(direzione === 1 && chiaveZoom<4) setChiaveZoom(parseInt(chiaveZoom)+1);
        if(direzione === 0 && chiaveZoom>1) setChiaveZoom(parseInt(chiaveZoom)-1);
    } 

    //const [flagResetPagine, setFlagResetPagine] = useState(false);

    //function callbackFlagResetPagine(f) {
    //    setFlagResetPagine(f);
    //}
    return(
        <Fragment>
        <Clessidra loading={loading}/>
        <div className="app-main">

            {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1Art {error.message}</div> :""}
            {statoPagina === STATO_RICERCA_DATI_CORRETTA ?
            <div className="app-main__outer">



                {spiegaAccorpa === true ?
                    <Modale 
                        titolo="Informativa su checkbox 'Accorpa le prenotazioni per corso'"
                        flagErrore={false}
                        contenuto={
                            <>
                                <strong>Accorpato:</strong> Il nome del corso sarà preceduto dal numero di prenotazioni per quel determinato corso in quel periodo. Se si filtra per un corso specifico, poichè saranno presenti solo le prenotazione del corso selezionato, per ogni periodo resterà solo l'indicazione del numero di prenotazioni.
                                <br /><br />
                                <strong>Non accorpato:</strong> Tutte le prenotazioni saranno visualizzate singolarmente.
                            </>
                        }
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setSpiegaAccorpa(false)}
                            }    
                        ]}
                    />
                :""}
                {spiegaOrari === true ?
                    <Modale 
                        titolo="Informativa su checkbox 'Visualizza Orari'"
                        flagErrore={false}
                        contenuto={
                            <>
                            Selezionando la checkbox <strong>"Visualizza orari"</strong>, ogni periodo mostrerà anche la data e l'orario di inizio e fine.
                            </>
                        }
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setSpiegaOrari(false)}
                            }    
                        ]}
                    />
                :""}
                {spiegaReport === true ?
                    <Modale 
                        titolo="Informativa sui dati del report"
                        flagErrore={false}
                        contenuto={
                            <>
                            In questo report sono riportate tutte le prenotazioni attualmente inserite a sistema.
                            <br /><br/>
                            E' possibile restringere il campo di estrazione dati ad un determinato iscritto e/o ad un determinato corso.
                            <br /><br/>
                            Inoltre, tramite le impostazioni 'Accorpa le prenotazioni' e 'Visualizza orari' è possibile andare a variare il layout (per ulteriori spegazioni si rimanda agli interrogativi posti vicino a tali impostazioni)
                            <br /><br/>
                            FIltrando solo per corso e non accorpando le prenotazioni, si avrà l'indicazione dell'iscritto che ha effettuato la prenotazione.
                            </>
                        }
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setSpiegaReport(false)}
                            }    
                        ]}
                    />
                :""}

                <Card className="main-card mb-3"> 
                            <CardBody>
                                <CardTitle>Report Prenotazioni <img src={InterrogativoBianco} className="interrogativo" alt="aiuto" onClick={() => setSpiegaReport(true)}/></CardTitle>
                               
                                <div style={{display:"flex", flexDirection:"row"}}>  
                                <div style={{flex:5}}>  
                                <Label for="iscritto">Iscritto</Label>         
                                <TendinaEditabile
                                    //titolo={"Iscritto"}
                                    nomeCampo="COGNOME"
                                    nomeCampo2="NOME"
                                    nomeCampoParentesi="NUMERO_TESSERA"
                                    nomeCampoNonVisibile="ID"
                                    bloccoInserimento = "1"
                                    valoreDefault={defaultPersona}
                                    arrayValori={risultatoServerDatiPersona}
                                    inserisciCampoTutti = "1"
                                    callbackSetVariazioneCampi={callbackSetVariazioneCampi}
                                    callbackSetValoreTendEditabile={callbackSetValoreTendEditabilePersona}
                                ></TendinaEditabile>
                                </div>  

                                {props.provvenienza === "tabellaPrenotazioniCorsi" ?
                                    <div style={{flex:4,marginLeft: 7}}>
                                    <Label for="corso">Prestazione / Attrezzatura</Label>
                                    <TendinaEditabile
                                        //titolo={"Corso"}
                                        nomeCampo="NOME"
                                        nomeCampoNonVisibile="ID"
                                        bloccoInserimento = "1"
                                        valoreDefault={defaultCorso}
                                        arrayValori={risultatoServerDatiCorso}
                                        inserisciCampoTutti = "1"
                                        callbackSetVariazioneCampi={callbackSetVariazioneCampi}
                                        callbackSetValoreTendEditabile={callbackSetValoreTendEditabileCorso}
                                    ></TendinaEditabile>
                                    </div>
                                :
                                    <div style={{flex:5,marginLeft: 7}}>
                                    <Label for="corso">Prestazione / Attrezzatura</Label>
                                    <TendinaEditabile
                                        //titolo={"Corso"}
                                        nomeCampo="NOME"
                                        nomeCampoNonVisibile="ID"
                                        bloccoInserimento = "1"
                                        valoreDefault={defaultCorso}
                                        arrayValori={risultatoServerDatiCorso}
                                        inserisciCampoTutti = "1"
                                        callbackSetVariazioneCampi={callbackSetVariazioneCampi}
                                        callbackSetValoreTendEditabile={callbackSetValoreTendEditabileCorso}
                                    ></TendinaEditabile>
                                    </div>
                                }
                                <div style={{ marginLeft: 7 }}>
                                    <div style={{display:"flex", flexDirection:"column"}}> 
                                        <div style={{flex:5,marginLeft: 7}}>
                                        <Input 
                                            className="ml-2 mt-2" 
                                            type="checkbox" 
                                            checked={accorpato === 1}  // Imposta il checkbox come checked se accorpa è 1
                                            onChange={() => setAccorpato(accorpato === 1 ? 0 : 1)}  // Inverte il valore di accorpa tra 1 e 0
                                        />
                                        <Label className="ml-4 mt-1">
                                            Accorpa le prenotazioni per corso<img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setSpiegaAccorpa(true)}/>
                                        </Label>
                                        </div>
                                        <div style={{flex:5,marginLeft: 7}}>
                                        {/* Secondo checkbox sotto il primo */}
                                        <Input 
                                            className="ml-2 mt-2" 
                                            type="checkbox" 
                                            checked={orari === 1}  // Imposta il checkbox come checked se orari è 1
                                            onChange={() => setOrari(orari === 1 ? 0 : 1)}  // Inverte il valore di orari tra 1 e 0
                                        />
                                        <Label className="ml-4 mt-1">
                                            Visualizza orari <img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setSpiegaOrari(true)}/>
                                        </Label>
                                        </div>
                                    </div>
                                </div>

                                <div style={{marginLeft: 7}}>
                                <Button color="primary" className="mt-3" onClick={() => {regolaZoom(1)}}>Zoom +</Button>
                                </div>
                                <div style={{marginLeft: 7}}>
                                <Button color="primary" className="mt-3" onClick={() => {regolaZoom(0)}}>Zoom -</Button>
                                </div>
                                {props.provvenienza === "tabellaPrenotazioniCorsi" ?
                                    <div style={{marginLeft: 7}}>
                                    <Button color="success" className="mt-2" onClick={() => tornaPrenotazioni()}>Torna alle Prenotazioni</Button>
                                    </div>
                                :""}
                                </div>
                                
                                    <Row form style={{marginTop:13}}>
                                        <Col md={12}>
                                       
                                        <FullCalendar
                                            slotDuration={zoom[chiaveZoom]}//The slotDuration changes the grid display to 30 minutes. 
                                            //snapDuration={"00:01:00"}
                                            plugins={[ dayGridPlugin, interactionPlugin ,timeGridPlugin ]}
                                            allDaySlot={false} //togli la cella iniziale che individua tutto il giorno
                                            //disegno la toolbar:
                                            headerToolbar={{
                                              start: 'title', // will normally be on the left. if RTL, will be on the right
                                              center: 'dayGridMonth,timeGridWeek,timeGridDay',
                                              end: 'prev,next', // will normally be on the right. if RTL, will be on the left
                                            }}
                                            
                                            //a seconda di che vista ho scelto tramite bottoni la propongo:
                                            initialView='timeGridWeek'//ti parte facendoti vedere la settimana e le ore a sx se metti ad es 'timeGridWeek'
                                            
                                            locale={itLocale} //serve per lingua italiana
                                            editable={false}
                                            eventOverlap={true} //true-> eventi sovrapponibili, false -> eventi non sovrapponibili

                                            selectable={true}//rende il giorno selezionabile
                                            dragScroll={true}//rende l'evento trascinabile

                                            eventMinHeight="15"//altezza minima di ogni evento (default = 15)

                                            weekends={true}//inserisce anche i weekends nel calendario
                                            displayEventEnd={true}//fà vedere l'ora di fine evento

                                            events={eventiCalendario}//inserisco i dati degli eventi attualmente già presenti

                                            slotLabelFormat={{
                                                hour: 'numeric',
                                                minute: '2-digit',
                                                omitZeroMinute: false,
                                                meridiem: 'short'
                                            
                                               }}
                                            

                                               eventContent={({ event }) => {
                                                // Se orari è 0, rimuovi l'orario all'interno dell'evento
                                                if (orari == 0) {
                                                    return (
                                                        <>
                                                            <div>{event.title}</div> {/* Mostra solo il titolo dell'evento */}
                                                        </>
                                                    );
                                                }
                                                return (
                                                    <>
                                                        <div>{event.start.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} {/* Mostra orario di inizio evento senza secondi */}
                                                        - {event.end.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} {/* Mostra orario di fine evento senza secondi */}
                                                        {event.title}</div>
                                                        
                                                    </>
                                                );
                                            }}


                                        />
                                        </Col>
                                    </Row> 
                                             
                            </CardBody>
                        </Card>
                {/*<AppFooter/>*/}
            </div>
            :""}
        </div>
    
        
    </Fragment>
    );
};

export default ReportPrenotazioniCorsi;