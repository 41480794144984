import React, { useState, useEffect } from 'react';
import { convertiDataComputer2Umano } from './funzioni.js'
import Clessidra from '../Pagine/Clessidra'
import stile from './stile.module.css';

function Abbonamenti(props) {
    const PAGINE = {
        "caricamentoDati": 1,
        "elencoAbbonamenti": 2,
    };
    const [pagina, setPagina] = useState(PAGINE.caricamentoDati);
    const [elencoAbbonamenti, setElencoAbbonamenti] = useState([]);

    function scaricaElencoAbbonamenti() {
        setPagina(PAGINE.caricamentoDati);
        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ op: "abbonatiRecuperaDati", "soloMiei": 1, emak: props.emak, sessionId: props.sessionId })
        };
        fetch(props.UrlBackend, requestOptions)
            .then(risposta => risposta.json())
            .then(
                (valore_di_ritorno) => {
                    if (valore_di_ritorno.verifvers === 0) {
                        // necessario aggiornare la app
                        props.callbackAggiornaApp();
                        return;
                    }
                    if (valore_di_ritorno.risultatoOperazione === 2) { props.callback(props.PAGINECLIENTI.login); return; }
                    if (valore_di_ritorno.risultatoOperazione !== 1) { 
                        window.alert("Sistema momentaneamente non disponibile. Se il problema persiste, contattare l'assistenza tecnica"); 
                        props.callback(props.PAGINECLIENTI.login);
                        return; 
                    }
                    setElencoAbbonamenti(valore_di_ritorno.risultatoRitorno);
                    setPagina(PAGINE.elencoAbbonamenti);
                },
                (error) => {
                    props.callback(props.PAGINECLIENTI.login);
                    return;
                }
            );                                 
    }

    useEffect(() => {
        scaricaElencoAbbonamenti();
    }, [])
    
    // gestione del tasto BACK
    useEffect(() => {
        if (props.flagRichiestaBack) {
            props.setFlagRichiestaBack(false);
            props.callback(props.PAGINECLIENTI.home);
        } 
    }, [props.flagRichiestaBack])  

    if (pagina === PAGINE.caricamentoDati) {
        return (<Clessidra loading={true} />)
    }

    if (pagina === PAGINE.elencoAbbonamenti) {
        return (        
            <div style={{overflowY:'auto'}}>
    
                {elencoAbbonamenti.length > 0 ? (
                    <div>    
                        {elencoAbbonamenti.map((x, i) => 
                            <div key={i} className={stile.movimentiElencoContenitoreElemento}>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <div style={{flex: 1, justifyContent: 'center', paddingRight: 2}}>
                                        <div className={stile.movimentiTestoData}>da {convertiDataComputer2Umano(x.DATA_INIZIO)}</div>
                                    </div>
                                    <div style={{flex: 1, justifyContent: 'center', paddingLeft: 2}}>
                                        <div className={stile.movimentiTestoData}>a {convertiDataComputer2Umano(x.DATA_FINE)}</div>
                                    </div>
                                </div>
                                <div style={{paddingTop: 5}}>
                                    <div className={stile.movimentiTestoOperazione}>{x.NOMEABBONAMENTO}</div>
                                </div>
                                {x.ACCESSI_RESIDUI >= 0 && 
                                    <div style={{paddingTop: 5}}>
                                        <div className={stile.movimentiTestoOperazione}>
                                            {x.ACCESSI_RESIDUI == 1 ? "Resta ":"Restano "}
                                            {x.ACCESSI_RESIDUI}
                                            {x.ACCESSI_RESIDUI == 1 ? " ingresso disponibile":" ingressi disponibili"}
                                        </div>
                                    </div>
                                }
                            </div>
                        )}
                    </div>
                ) : (
                    <div style={{color:"#FFFFFF", padding:10, textAlign:'center'}}>Non ci sono abbonamenti</div>
                )}
            </div>
        );
    }    

    return (
        <div>Errore pagina non trovata (Abbonamenti)</div>
    )
}

export default Abbonamenti;
