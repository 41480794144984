import React, {Fragment,useState,useEffect,useRef,Suspense} from 'react';
import { Table,Row, Col,
  Card, CardBody,
  CardTitle,Button } from 'reactstrap';
import Parametri from '../../parametri';

import FormGridFormRow from '../SchedaPrimaNota/FormGridFormRow';


import TabellaRicerca from '../tabellaricerca.js';
import ModaleRicerca from '../ModaleRicerca.js';

import TrasformaDateAUmano from '../TrasformaDateAUmano.js';

import Clessidra from '../Clessidra.js';
import { calcolaAnnoOggi } from '../../funzioni.js';
import Interrogativo from '../../immagini/interrogativo-azzurro.png';

import Modale from '../Modale.js';

//const STATO_INDEFINITO = 0;
const STATO_RICERCA_DATI = 1;
const STATO_OPERAZIONE_CORRETTA= 2;
const STATO_ERRORE_CONNESSIONE= 3;
const STATO_VAI_IN_MODIFICA= 4;
const STATO_VAI_IN_ELIMINA= 5;
const STATO_VAI_IN_INSERISCI= 6;


function TabellaPrimaNota(props) {
    
    const operazione = "elencoMovimentiPrimaNota";

    const  dataPartenza = useRef();
    
    const annoOggi = calcolaAnnoOggi(props.identitaSelezionata.data_chiusura_esercizio);
    const [dataPartenzaDefault,setDataPartenzaDefault]=useState(annoOggi);
    const [inizioPeriodo,setInizioPeriodo]=useState('');
    const [finePeriodo,setFinePeriodo]=useState('');
      
    const [risultatoServer,setRisultatoServer]=useState('');
    const [statoPagina,setStatoPagina]=useState(STATO_RICERCA_DATI);
    const [error,setError]=useState('');

    const [idModifica,setIdModifica]=useState('');
    const [flagModaleInfoPopolamento, setFlagModaleInfoPopolamento] = React.useState(false);

    const [flagModaleRicercaAvanzata, setFlagModaleRicercaAvanzata] = useState(false);
    const defaultSelezioneRicercaAvanzata = [
      {"campoDatabase":"", "criterio":"contiene", "dato":""},
      {"campoDatabase":"", "criterio":"contiene", "dato":""},
      {"campoDatabase":"", "criterio":"contiene", "dato":""}
    ];
    const [selezioneRicercaAvanzata, setSelezioneRicercaAvanzata] = useState(defaultSelezioneRicercaAvanzata);

    // nota: accessor DEVE chiamarsi così a causa di requisiti dei componenti usati, non fatti da me
    const colonne = [
      {
        etichetta: '',
        accessor: 'ID'
      },
      {
        etichetta: 'Risorsa',
        accessor: 'RISORSA',
        pdf: 1,
      },
      {
        etichetta: 'Descr Movimento',
        accessor: 'DESCRIZIONE',
        pdf: "Descrizione\nMovimento",
      },
      {
        etichetta: 'Importo Tot Lordo',
        accessor: 'IMPORTO',
        pdf: 'Importo\nTotale Lordo',
      },
      {
        etichetta: 'IVA',
        accessor: 'IVA',
        pdf: 1,
      },
      {
        etichetta: 'Data Pagamento',
        accessor: 'DATA_PAGAMENTO',
        pdf: "Data\nPagamento",
      },
      {
        etichetta: 'Voce di Bilancio',
        accessor: 'VOCE_DI_BILANCIO',
        pdf: 1,
      },
      {
        etichetta: "Attività principale",
        accessor: 'TIPO_ATTIVITA'
      },
      {
        etichetta: 'Data Variazione',
        accessor: 'DATA_VARIAZIONE',
        pdf: "Data\nVariazione",
      },
    ];

    const arrayTipiDati=[];
    arrayTipiDati[3]="importo";
    arrayTipiDati[4]="importo";
    arrayTipiDati[5]="data";
    arrayTipiDati[7]="flag";
    arrayTipiDati[8]="data";

    const campiRicercaAvanzata = [
      { etichetta: 'Risorsa', campoDatabase: 'RISORSE.DESCRIZIONE', tipo: 'alfanumerico' },
      { etichetta: 'Descr Movimento', campoDatabase: 'PRIMA_NOTA.DESCRIZIONE', tipo: 'alfanumerico' },
      { etichetta: 'Importo Tot Lordo', campoDatabase: 'PRIMA_NOTA.IMPORTO*PRIMA_NOTA.SEGNO', tipo: 'alfanumerico' },
      { etichetta: 'IVA', campoDatabase: 'PRIMA_NOTA.IVA*PRIMA_NOTA.SEGNO', tipo: 'alfanumerico' },
      { etichetta: 'Data Pagamento', campoDatabase: 'DATA_PAGAMENTO', tipo: 'data' },
      { etichetta: 'Voce di Bilancio', campoDatabase: 'VOCI_DI_BILANCIO.DESCRIZIONE', tipo: 'alfanumerico' },
      { etichetta: 'Attività principale', campoDatabase: 'TIPO_ATTIVITA', tipo: 'alfanumerico' },
      { etichetta: 'Data Variazione', campoDatabase: 'DATA_VARIAZIONE', tipo: 'data' },
    ]

    function VaiModifica(id) {
      setStatoPagina(STATO_VAI_IN_MODIFICA);
      setIdModifica(id);
    }

    function VaiElimina(id) {
      setStatoPagina(STATO_VAI_IN_ELIMINA);
      setIdModifica(id);
    }

    function callbackTermineModifica() {
      setStatoPagina(STATO_RICERCA_DATI);
    }
    function tornaElenco() {
      setFlagModaleInfoPopolamento(false);
    }

    


    useEffect(() => {
      if (props.flagResetPagine) {
        setStatoPagina(STATO_RICERCA_DATI);
        props.callbackFlagResetPagine(false);
      }
    }, [props.flagResetPagine])

    useEffect(() => {
      //resetto tutte le var di stato:
      setRisultatoServer(['']);
      setStatoPagina(STATO_RICERCA_DATI);    
    },[dataPartenzaDefault])
    
    useEffect(() => {
      if(statoPagina===STATO_RICERCA_DATI){
        // parametri per inviare i dati al server:
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({op:operazione,dataPartenza:dataPartenzaDefault,selezioneRicercaAvanzata:selezioneRicercaAvanzata,emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
          .then(risposta => risposta.json())
          .then(
            (valoreDiRitorno) => {
              if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
              }
              if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
              if(valoreDiRitorno.risultatoOperazione===1){
                setRisultatoServer(valoreDiRitorno.risultatoRitorno);
                setInizioPeriodo(valoreDiRitorno.periodo[0].DATA_INIZIO_PERIODO);            
                setFinePeriodo(valoreDiRitorno.periodo[0].DATA_FINE_PERIODO);
                setStatoPagina(STATO_OPERAZIONE_CORRETTA);
              }
                
            },
          (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
          );
      }    
    },[statoPagina])
    //},[props.numPagina])

    function ModaleRicercaAvanzata() {
      return (
        <ModaleRicerca        
          campiRicercaAvanzata={campiRicercaAvanzata}
          criteriIniziali = {selezioneRicercaAvanzata}
          onConferma={(criteri) => { 
            setFlagModaleRicercaAvanzata(false); 
            setSelezioneRicercaAvanzata(criteri);
            setStatoPagina(STATO_RICERCA_DATI);
          }}
          onAnnulla={() => { setFlagModaleRicercaAvanzata(false); setSelezioneRicercaAvanzata(defaultSelezioneRicercaAvanzata);setStatoPagina(STATO_RICERCA_DATI);}}
  
          callbackConferma = {
            (v) => {
              var indiceCampoSelezionato=-1;
              for (var i=0; i<campiRicercaAvanzata.length; i++) {                      
                if (campiRicercaAvanzata[i].campoDatabase==v[0].campoDatabase) {
                  indiceCampoSelezionato=i;
                  break;
                }
              }
              if (indiceCampoSelezionato<0) {
                setSelezioneRicercaAvanzata(defaultSelezioneRicercaAvanzata);
              } else {
                if (campiRicercaAvanzata[indiceCampoSelezionato].tipo==="data") {
                  // verifica correttezza della data
                  var dataOk = false;
                  var x = v[0].dato.split("/");
                  if (x.length==3) {
                    if (!isNaN(x[0])&&!isNaN(x[1])&&!isNaN(x[2])) {
                      if (x[0]>0 && x[0]<32 && x[1]>0 && x[1]<13 && x[2]>0 && x[2] < 2100) {
                        var dataOk = true;
                      }
                    }
                  }
                  if (!dataOk) {
                    alert("Data non corretta. Inserisci la data scrivendo giorno/mese/anno. Ad esempio: 1/1/2024");
                    return;
                  }
                }
                setSelezioneRicercaAvanzata(v);
              }
              setFlagModaleRicercaAvanzata(false);
              setStatoPagina(STATO_RICERCA_DATI);
            }
          }    
        /> 
      )
    }

  return (
      <div>
     {flagModaleRicercaAvanzata ? <ModaleRicercaAvanzata /> : <></>}

      {statoPagina === STATO_VAI_IN_INSERISCI ? 
        <div>
        <Suspense fallback={
          <div className="loader-container">
          <div className="loader-container-inner"><h6 className="mt-5">Caricamento scheda movimento prima nota in corso...</h6></div>
          </div>
        }>
        <FormGridFormRow {...props} ope={"inserisci"} callbackTermineModifica={callbackTermineModifica} sessionId={props.sessionId}></FormGridFormRow>
        </Suspense>
      </div>
      :""} 

      {statoPagina === STATO_VAI_IN_MODIFICA ? 
        <div>
          <Suspense fallback={
            <div className="loader-container">
            <div className="loader-container-inner"><h6 className="mt-5">Caricamento scheda movimento prima nota in corso...</h6></div>
            </div>
          }>
          <FormGridFormRow {...props} id={idModifica} ope={"modifica"} callbackTermineModifica={callbackTermineModifica} sessionId={props.sessionId}></FormGridFormRow>
          </Suspense>
        </div>
      :""} 

      {statoPagina === STATO_VAI_IN_ELIMINA ? 
        <div>
          <Suspense fallback={
            <div className="loader-container">
            <div className="loader-container-inner"><h6 className="mt-5">Caricamento scheda movimento prima nota in corso...</h6></div>
            </div>
          }>
          <FormGridFormRow {...props} id={idModifica} ope={"cancella"} callbackTermineModifica={callbackTermineModifica} sessionId={props.sessionId}></FormGridFormRow>
          </Suspense>
        </div>
      :""} 

      {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1E_MovPriNot</div> :""}  
      {statoPagina === STATO_RICERCA_DATI ?  <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}

      {statoPagina === STATO_OPERAZIONE_CORRETTA ?
        <div style={{height: props.dimensioniApp.yMain, overflow:"hidden", display:"flex", flexDirection:"column"}}>
          {flagModaleInfoPopolamento=== true ?
               <Modale 
                   larghezza = "lg"
                   titolo="Informativa prima nota"
                   flagErrore={false}
                   contenuto={<div>
                    In questo ambiente è possibile visualizzare, modificare e eliminare tutti i movimenti di cassa, sia in ingresso che in uscita, includendo quote associative o di abbonamento, incassi, spese, rimborsi, sponsorizzazioni e altro.
                    Per l'inserimento di queste tipologie di movimenti si rimanda alle rispettive voci di menu.
                    <br></br>
                    È inoltre possibile inserire, modificare ed eliminare movimenti diretti, come corrispettivi, scontrini e altre operazioni simili.
                   </div>}
                   bottoni={[
                       {
                           "etichetta":"OK",
                           "tipo":"primary",
                           callback:() => {tornaElenco()}
                       }    
                   ]}
               />
          :""}
          <CardBody>
            <CardTitle>Elenco Movimenti in Prima Nota &nbsp;
              <select
                name="dataPartenza"
                id="dataPartenza"
                innerRef={dataPartenza}
                onChange={(e) => setDataPartenzaDefault(e.target.value)}
              >
                {/* Significato di (_, i) => { ... }
                Il primo parametro _ rappresenta l'elemento corrente dell'array. In questo caso, l'array è creato con [...Array(annoOggi - 2014 + 1)], quindi è un array di elementi "vuoti" (tipo [undefined, undefined, ...]). Poiché non ci interessa il valore degli elementi, usiamo _ per indicare che non lo utilizziamo.
                Il secondo parametro i rappresenta l'indice corrente dell'iterazione (parte da 0 e cresce di 1 per ogni iterazione).*/}
                {[...Array((annoOggi+1) - 2014 + 1)].map((_, i) => {
                  const anno = (annoOggi+1) - i;
                  return (
                    <option key={anno} value={anno} selected={parseInt(dataPartenzaDefault) === anno}>
                      {anno}
                    </option>
                  );
                })}
              </select>
              


              {/*<select name="dataPartenza" id="dataPartenza" innerRef={dataPartenza} onChange={(e)=>{setDataPartenzaDefault(e.target.value)}}>
                {parseInt(dataPartenzaDefault) === annoOggi ? <option selected value={annoOggi}>{annoOggi}</option> : <option value={annoOggi}>{annoOggi}</option>}
                {parseInt(dataPartenzaDefault) === annoOggi-1 ? <option selected value={annoOggi-1}>{annoOggi-1}</option> : <option value={annoOggi-1}>{annoOggi-1}</option>}
                {parseInt(dataPartenzaDefault) === annoOggi-2 ? <option selected value={annoOggi-2}>{annoOggi-2}</option> : <option value={annoOggi-2}>{annoOggi-2}</option>}
                {parseInt(dataPartenzaDefault) === annoOggi-3 ? <option selected value={annoOggi-3}>{annoOggi-3}</option> : <option value={annoOggi-3}>{annoOggi-3}</option>}
                {parseInt(dataPartenzaDefault) === annoOggi-4 ? <option selected value={annoOggi-4}>{annoOggi-4}</option> : <option value={annoOggi-4}>{annoOggi-4}</option>}
                {parseInt(dataPartenzaDefault) === annoOggi-5 ? <option selected value={annoOggi-5}>{annoOggi-5}</option> : <option value={annoOggi-5}>{annoOggi-5}</option>}
                {parseInt(dataPartenzaDefault) === annoOggi-6 ? <option selected value={annoOggi-6}>{annoOggi-6}</option> : <option value={annoOggi-6}>{annoOggi-6}</option>}
                {parseInt(dataPartenzaDefault) === annoOggi-7 ? <option selected value={annoOggi-7}>{annoOggi-7}</option> : <option value={annoOggi-7}>{annoOggi-7}</option>}
                {parseInt(dataPartenzaDefault) === annoOggi-8 ? <option selected value={annoOggi-8}>{annoOggi-8}</option> : <option value={annoOggi-8}>{annoOggi-8}</option>}
                {parseInt(dataPartenzaDefault) === annoOggi-9 ? <option selected value={annoOggi-9}>{annoOggi-9}</option> : <option value={annoOggi-9}>{annoOggi-9}</option>}
                {parseInt(dataPartenzaDefault) === annoOggi-10 ? <option selected value={annoOggi-10}>{annoOggi-10}</option> : <option value={annoOggi-10}>{annoOggi-10}</option>}
              </select>  */}  
              &nbsp;dal <TrasformaDateAUmano data={inizioPeriodo}></TrasformaDateAUmano> al <TrasformaDateAUmano data={finePeriodo}></TrasformaDateAUmano> 
            </CardTitle>
            <Button color="success" onClick={() => setStatoPagina(STATO_VAI_IN_INSERISCI)}>Nuovo</Button>
            <Button color="success" className="ml-2" onClick={() => setFlagModaleRicercaAvanzata(true)}>Ricerca avanzata</Button>
            <img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setFlagModaleInfoPopolamento(true)}/>
            &nbsp;La giacenza per ogni Risorsa è disponibile nella sezione 'Gestione Economica - Risorse'

            {(selezioneRicercaAvanzata[0].campoDatabase!=="" || selezioneRicercaAvanzata[1].campoDatabase!=="" || selezioneRicercaAvanzata[2].campoDatabase!=="") &&
              <div 
                onClick={() => setFlagModaleRicercaAvanzata(true)} 
                style={{
                  marginLeft:0, 
                  marginTop:10, 
                  backgroundColor:"#FFFFFF", 
                  paddingLeft:5, 
                  paddingRight:5, 
                  paddingTop:5, 
                  paddingBottom:5, 
                  cursor:"pointer"
                }}>
                <b>Filtro</b>:&nbsp;
                {selezioneRicercaAvanzata.map((condizione, indiceCondizione) => 
                  <span key={indiceCondizione}>
                    {condizione.campoDatabase !== "" && 
                      <>
                        {indiceCondizione > 0 && <>,&nbsp;</>}
                        {campiRicercaAvanzata.map((x,i)=> {if(x.campoDatabase==condizione.campoDatabase) return x.etichetta; })}
                        &nbsp;{condizione.criterio} {condizione.dato}
                      </>
                    }
                  </span>
                )}
              </div>
            }

          </CardBody>      

          {risultatoServer !== undefined && risultatoServer !== "" ?
            <TabellaRicerca 
              titolo="Prima Nota"
              societa={props.identitaSelezionata.societa}
              columns={colonne}
              data={risultatoServer}
              arrayTipiDati={arrayTipiDati}
              campoChiave={0}
              campoOrdinamento={5}
              ordinamentoDecrescente={true}
              nascondiColonna={0}
              callbackSelezionaElemento={VaiModifica}
              callbackEliminaElemento={VaiElimina}
            />
          : 
            <Table responsive bordered hover striped className="mb-0"><thead><tr><th>Nessun dato presente</th></tr></thead></Table>
          }
        </div>
      :""}
    </div>
   );
}
export default TabellaPrimaNota;