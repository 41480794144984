import React, {Fragment,useState,useEffect,useRef,Suspense} from 'react';
import { Table,Row, Col,
  Card, CardBody,
  CardTitle,Button } from 'reactstrap';
import Parametri from '../../parametri';

import FormGridFormRow from '../SchedaCampagnaSponsor/FormGridFormRow';

import TabellaRicerca from '../tabellaricerca.js';

import TrasformaDateAUmano from '../TrasformaDateAUmano.js';

import Clessidra from '../Clessidra.js';

import { calcolaAnnoOggi } from '../../funzioni.js';
import Interrogativo from '../../immagini/interrogativo-azzurro.png';

import Modale from '../Modale.js';

//const STATO_INDEFINITO = 0;
const STATO_RICERCA_DATI = 1;
const STATO_OPERAZIONE_CORRETTA= 2;
const STATO_ERRORE_CONNESSIONE= 3;
const STATO_VAI_IN_MODIFICA= 4;
const STATO_VAI_IN_ELIMINA= 5;
const STATO_VAI_IN_INSERISCI= 6;


function TabellaCampagneSponsor(props) {
    
    const operazione = "elencoCampagneSponsor";

    const  dataPartenza = useRef();

    const annoOggi = calcolaAnnoOggi(props.identitaSelezionata.data_chiusura_esercizio);
    const [dataPartenzaDefault,setDataPartenzaDefault]=useState(annoOggi);
    const [inizioPeriodo,setInizioPeriodo]=useState('');
    const [finePeriodo,setFinePeriodo]=useState('');
      
    const [risultatoServer,setRisultatoServer]=useState('');
    const [statoPagina,setStatoPagina]=useState(STATO_RICERCA_DATI);
    const [error,setError]=useState('');

    const [idModifica,setIdModifica]=useState('');

    const coloreNonPagatiRigaPari="#FFC0CB";
    const coloreNonPagatiRigaDispari="#fadadd";
    const [flagModaleInfoPopolamento, setFlagModaleInfoPopolamento] = React.useState(false);
    
    // nota: accessor DEVE chiamarsi così a causa di requisiti dei componenti usati, non fatti da me
    const colonne = [
      {
        etichetta: '',
        accessor: 'ID'
      },
      {
        etichetta: 'Cognome',
        accessor: 'COGNOME_SPONSOR',
        pdf: 1,
      },
      {
        etichetta: 'Nome',
        accessor: 'NOME_SPONSOR',
        pdf: 1,
      },
      {
        etichetta: 'Cod. Fiscale',
        accessor: 'CODICE_FISCALE_SPONSOR',
        pdf: 1,
      },
      {
        etichetta: 'P. IVA',
        accessor: 'P_IVA_SPONSOR',
        pdf: 1,
      },
      {
        etichetta: 'Data Inizio',
        accessor: 'DATA_INIZIO',
        pdf: 1,
      },
      {
        etichetta: 'Data Fine',
        accessor: 'DATA_FINE',
        pdf: 1,
      },
      {
        etichetta: 'Imponibile',
        accessor: 'IMPONIBILE',
        pdf: 1,
      },
      {
        etichetta: 'IVA',
        accessor: 'IVA',
        pdf: 1,
      },
      {
        etichetta: 'Importo',
        accessor: 'IMPORTO',
        pdf: 1,
      },
      {
        etichetta: 'Stato',
        accessor: 'STATO',
        pdf: 1,
      },
      {
        etichetta: 'Descrizione',
        accessor: 'DESCRIZIONE',
        pdf: 1,
      },
    ];
    const arrayTipiDati=[];
    arrayTipiDati[5]="data";
    arrayTipiDati[6]="data";
    arrayTipiDati[7]="importo";
    arrayTipiDati[8]="importo";
    arrayTipiDati[9]="importo";

    
    function VaiModifica(id) {
      setStatoPagina(STATO_VAI_IN_MODIFICA);
      setIdModifica(id);
    }

    function VaiElimina(id) {
      setStatoPagina(STATO_VAI_IN_ELIMINA);
      setIdModifica(id);
    }

    function callbackTermineModifica() {
      setStatoPagina(STATO_RICERCA_DATI);
    }
    function tornaElenco() {
      setFlagModaleInfoPopolamento(false);
    }
    useEffect(() => {
      if (props.flagResetPagine) {
        setStatoPagina(STATO_RICERCA_DATI);
        props.callbackFlagResetPagine(false);
      }
    }, [props.flagResetPagine])

    useEffect(() => {
      //resetto tutte le var di stato:
      setRisultatoServer(['']);
      setStatoPagina(STATO_RICERCA_DATI);    
    },[dataPartenzaDefault])

    useEffect(() => {
      if(statoPagina===STATO_RICERCA_DATI){
        // parametri per inviare i dati al server:
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({op:operazione,dataPartenza:dataPartenzaDefault,emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
          .then(risposta => risposta.json())
          .then(
            (valoreDiRitorno) => {
              if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
              }
              if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
              if(valoreDiRitorno.risultatoOperazione===1){
              setRisultatoServer(valoreDiRitorno.risultatoRitorno);
              setInizioPeriodo(valoreDiRitorno.periodo[0].DATA_INIZIO_PERIODO);
              setFinePeriodo(valoreDiRitorno.periodo[0].DATA_FINE_PERIODO);
              setStatoPagina(STATO_OPERAZIONE_CORRETTA);}
            },
          (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
          );
      }    
    },[statoPagina])

  
  return (
      <div>

      {statoPagina === STATO_VAI_IN_INSERISCI ? 
        <div>
        <Suspense fallback={
          <div className="loader-container">
          <div className="loader-container-inner"><h6 className="mt-5">Caricamento scheda campagna sponsor in corso...</h6></div>
          </div>
        }>
        <FormGridFormRow {...props} ope={"inserisci"} callbackTermineModifica={callbackTermineModifica} sessionId={props.sessionId}></FormGridFormRow>
        </Suspense>
      </div>
      :""} 

      {statoPagina === STATO_VAI_IN_MODIFICA ? 
        <div>
          <Suspense fallback={
            <div className="loader-container">
            <div className="loader-container-inner"><h6 className="mt-5">Caricamento campagna sponsor in corso...</h6></div>
            </div>
          }>
          <FormGridFormRow {...props} id={idModifica} ope={"modifica"} callbackTermineModifica={callbackTermineModifica} sessionId={props.sessionId}></FormGridFormRow>
          </Suspense>
        </div>
      :""} 

      {statoPagina === STATO_VAI_IN_ELIMINA ? 
        <div>
          <Suspense fallback={
            <div className="loader-container">
            <div className="loader-container-inner"><h6 className="mt-5">Caricamento campagna sponsor in corso...</h6></div>
            </div>
          }>
          <FormGridFormRow {...props} id={idModifica} ope={"cancella"} callbackTermineModifica={callbackTermineModifica} sessionId={props.sessionId}></FormGridFormRow>
          </Suspense>
        </div>
      :""} 

      {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1E_C_Spo</div> :""}  
      {statoPagina === STATO_RICERCA_DATI ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}

      {statoPagina === STATO_OPERAZIONE_CORRETTA ?
        <div style={{height: props.dimensioniApp.yMain, overflow:"hidden", display:"flex", flexDirection:"column"}}>
                {flagModaleInfoPopolamento=== true ?
                    <Modale 
                        larghezza = "lg"
                        titolo="Informativa Campagne Sponsorizzazione"
                        flagErrore={false}
                        contenuto={<div>Questo ambiente consente di gestire le campagne di sponsorizzazione. <br></br>Gli sponsor vengono gestiti e catalogati in Sponsor / Convenzioni - Elenco Sponsor.<br></br><br></br>
                          Durante l'inserimento o la modifica di una campagna, è possibile creare direttamente un nuovo sponsor. <br></br>
                          Successivamente, accedendo a Sponsor / Convenzioni - Elenco Sponsor, sarà possibile completare l'operazione aggiungendo i campi mancanti non inseriti nella fase iniziale.<br></br><br></br>
                          I pagamenti delle campagne di sponsorizzazione possono avvenire anche in più rate.<br></br>
                          <br></br>
                          I pagamenti registrati in questo ambiente verranno visualizzati automaticamente anche in prima nota.
                        </div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {tornaElenco()}
                            }    
                        ]}
                    />
                :""}
                    <CardBody>
                            <CardTitle>Elenco Campagne Sponsor

                            &nbsp;
                            <select
                              name="dataPartenza"
                              id="dataPartenza"
                              innerRef={dataPartenza}
                              onChange={(e) => setDataPartenzaDefault(e.target.value)}
                            >
                              {/* Significato di (_, i) => { ... }
                              Il primo parametro _ rappresenta l'elemento corrente dell'array. In questo caso, l'array è creato con [...Array(annoOggi - 2014 + 1)], quindi è un array di elementi "vuoti" (tipo [undefined, undefined, ...]). Poiché non ci interessa il valore degli elementi, usiamo _ per indicare che non lo utilizziamo.
                              Il secondo parametro i rappresenta l'indice corrente dell'iterazione (parte da 0 e cresce di 1 per ogni iterazione).*/}
                              {[...Array((annoOggi+1) - 2014 + 1)].map((_, i) => {
                                const anno = annoOggi+1 - i;
                                return (
                                  <option key={anno} value={anno} selected={parseInt(dataPartenzaDefault) === anno}>
                                    {anno}
                                  </option>
                                );
                              })}
                            </select>
                            {/*
                            <select name="dataPartenza" id="dataPartenza" innerRef={dataPartenza} onChange={(e)=>{setDataPartenzaDefault(e.target.value)}}>
                              {parseInt(dataPartenzaDefault) === annoOggi ? <option selected value={annoOggi}>{annoOggi}</option> : <option value={annoOggi}>{annoOggi}</option>}
                              {parseInt(dataPartenzaDefault) === annoOggi-1 ? <option selected value={annoOggi-1}>{annoOggi-1}</option> : <option value={annoOggi-1}>{annoOggi-1}</option>}
                              {parseInt(dataPartenzaDefault) === annoOggi-2 ? <option selected value={annoOggi-2}>{annoOggi-2}</option> : <option value={annoOggi-2}>{annoOggi-2}</option>}
                              {parseInt(dataPartenzaDefault) === annoOggi-3 ? <option selected value={annoOggi-3}>{annoOggi-3}</option> : <option value={annoOggi-3}>{annoOggi-3}</option>}
                              {parseInt(dataPartenzaDefault) === annoOggi-4 ? <option selected value={annoOggi-4}>{annoOggi-4}</option> : <option value={annoOggi-4}>{annoOggi-4}</option>}
                              {parseInt(dataPartenzaDefault) === annoOggi-5 ? <option selected value={annoOggi-5}>{annoOggi-5}</option> : <option value={annoOggi-5}>{annoOggi-5}</option>}
                              {parseInt(dataPartenzaDefault) === annoOggi-6 ? <option selected value={annoOggi-6}>{annoOggi-6}</option> : <option value={annoOggi-6}>{annoOggi-6}</option>}
                              {parseInt(dataPartenzaDefault) === annoOggi-7 ? <option selected value={annoOggi-7}>{annoOggi-7}</option> : <option value={annoOggi-7}>{annoOggi-7}</option>}
                              {parseInt(dataPartenzaDefault) === annoOggi-8 ? <option selected value={annoOggi-8}>{annoOggi-8}</option> : <option value={annoOggi-8}>{annoOggi-8}</option>}
                              {parseInt(dataPartenzaDefault) === annoOggi-9 ? <option selected value={annoOggi-9}>{annoOggi-9}</option> : <option value={annoOggi-9}>{annoOggi-9}</option>}
                              {parseInt(dataPartenzaDefault) === annoOggi-10 ? <option selected value={annoOggi-10}>{annoOggi-10}</option> : <option value={annoOggi-10}>{annoOggi-10}</option>}
                            </select> */}   
                            &nbsp;Data Emissione dal <TrasformaDateAUmano data={inizioPeriodo}></TrasformaDateAUmano> al <TrasformaDateAUmano data={finePeriodo}></TrasformaDateAUmano>

                            </CardTitle>
                            <Button color="success" onClick={() => setStatoPagina(STATO_VAI_IN_INSERISCI)}>Nuovo</Button>
                            <img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setFlagModaleInfoPopolamento(true)}/>
                            &nbsp;&nbsp;Legenda:
                            <span style={{marginLeft:5,paddingLeft:5,paddingRight:5,border:"1px solid black"}}>Pagate</span>
                            <span style={{backgroundColor:coloreNonPagatiRigaPari,marginLeft:5,paddingLeft:5,paddingRight:5,border:"1px solid black"}}>Non Pagate</span>
                            </CardBody>
                            {risultatoServer !== undefined && risultatoServer !== "" ?
                              <TabellaRicerca 
                              titolo={`Elenco Campagne Sponsor ${parseInt(dataPartenzaDefault)}`} 
                              columns={colonne} 
                              data={risultatoServer} 
                              arrayTipiDati={arrayTipiDati} 
                              campoChiave={0} 
                              campoOrdinamento={1} 
                              coloriPersonalizzati={[
                                {"campo":"10","condizione":"==","valore":"Non pagato","carattereRigaPari":"#000000","sfondoRigaPari":coloreNonPagatiRigaPari,"carattereRigaDispari":" #000000","sfondoRigaDispari":coloreNonPagatiRigaDispari}
                              ]}
                              nascondiColonna={0} 
                              callbackSelezionaElemento={VaiModifica} 
                              callbackEliminaElemento={VaiElimina}/>
                            : 
                              <Table responsive bordered hover striped className="mb-0">
                              <thead>
                                <tr>
                                  <th>Nessun dato presente</th>
                                </tr>
                              </thead>
                              </Table>
                          }
                      


        </div>
      :""}
    </div>
   );
}
export default TabellaCampagneSponsor;