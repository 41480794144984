import React, {Fragment,useState,useRef} from 'react';
import { useEffect } from 'react';

import {
    Table,Col, Row, Card, CardBody,
    CardTitle, Button, Form, FormGroup, Label, Input,Modal
} from 'reactstrap';
import Parametri from '../../parametri';
import TendinaEditabile from '../TendinaEditabile.js';
import TrasformaDateAUmano from '../TrasformaDateAUmano.js';

import FormattaImportoVideo from '../FormattaImportoVideo.js';
import FormattaImportoDb from '../FormattaImportoDb.js';

import Clessidra from '../Clessidra.js';
import Modale from '../Modale.js';
import FinestraDialogo from '../FinestraDialogo.js';

import { Page, Text, View, Document, StyleSheet,Image } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import styles from '../stiliPdf.js';
import Interrogativo from '../../immagini/interrogativo-azzurro.png';
import controllaDataAAAAMMGG from '../controllaDataAAAAMMGG.js';

const STATO_INDEFINITO = 0;
const STATO_INSERISCI = 1;
const STATO_MEMORIZZAZIONE = 2;//utilizzato per eseguire le operazioni di inserimento/modifica/eliminazione record
const STATO_ERRORE_CONNESSIONE = 3;
const STATO_OPERAZIONE_FALLITA = 4;
const STATO_RICERCA_DATI = 5;//utilizzato nel caso modifica e cancella quando vado a ricercare i dati per popolare i campi della scheda
const STATO_RICERCA_DATI_CORRETTA = 6;//utilizzato a valle della ricerca dati record in caso di modifica o cancellazione di un record

const STATO_SCARICA_PDF = 8;
const STATO_PREPARA_PDF = 9;

function FormGridFormRow(props) {

    var today = new Date();
    let sMese = String(today.getMonth()+1);
    if(sMese.length === 1) sMese = "0"+sMese;
    let sGiorno = String(today.getDate());
    if(sGiorno.length === 1) sGiorno = "0"+sGiorno;

    const dataOggi = today.getFullYear()+'-'+sMese+'-'+sGiorno
    
    const [operazione,setOperazione] = useState('');
    const [variazioneCampi,setVariazioneCampi]=useState(0);

    const [flagAttivaModaleIncassoNonCancellabile, setFlagAttivaModaleIncassoNonCancellabile] = useState(0);
    const [flagAttivaModaleInserisciIscritto, setFlagAttivaModaleInserisciIscritto] = useState(0);
    const [flagAttivaModaleRichiediPermessoInserire, setFlagAttivaModaleRichiediPermessoInserire] = useState(0);
    const [flagAttivaModaleRichiediNumeroTessera, setFlagAttivaModaleRichiediNumeroTessera] = useState(0);
    const [flagAttivaModaleInserisciDescrizione, setFlagAttivaModaleInserisciDescrizione] = useState(0);
    const [flagAttivaModaleInserisciDataPagamento, setFlagAttivaModaleInserisciDataPagamento] = useState(0);
    const [flagAttivaModaleInserisciDataEmissione, setFlagAttivaModaleInserisciDataEmissione] = useState(0);
    const [flagAttivaModaleInserisciRisorsa, setFlagAttivaModaleInserisciRisorsa] = useState(0);
    const [flagAttivaModaleInserisciVoceBilancio, setFlagAttivaModaleInserisciVoceBilancio] = useState(0);
    const [flagAttivaModaleInserisciImporto, setFlagAttivaModaleInserisciImporto]  = useState(0);
    const [flagAttivaModaleInserisciImportoPagamento, setFlagAttivaModaleInserisciImportoPagamento]  = useState(0);
    const [flagAttivaModaleImponibileErrato, setFlagAttivaModaleImponibileErrato] = useState(0);
    const [flagAttivaModaleImportoErrato, setFlagAttivaModaleImportoErrato] = useState(0);
    const [flagAttivaModaleIvaErrata, setFlagAttivaModaleIvaErrata] = useState(0);
    const [flagAttivaModaleImportoPagamentoErrato, setFlagAttivaModaleImportoPagamentoErrato] = useState(0);
    const [flagAttivaModaleIvaPagamentoErrata, setFlagAttivaModaleIvaPagamentoErrata] = useState(0);
    const [flagAttivaModaleSpiegaAttivita, setFlagAttivaModaleSpiegaAttivita] = useState(0);


    const importo   = useRef();
    const imponibile  = useRef();
    const iva   = useRef();
    const descrizione   = useRef();
    //const dataEmissione   = useRef();
    //const stato   = useRef();
    const note   = useRef();

    //const dataScadenzaRata   = useRef({});
    const dataPagamentoRata   = useRef({});
    const importoRata   = useRef({});
    //const ivaRata   = useRef({});
    //const noteRata   = useRef({});
    //utile per indicazione del cognome in caso di iscritti con più cognomi (o cognomi composti) e/o più nomi:
    const cognome = useRef();


    const [statoPagina,setStatoPagina]=useState(STATO_INDEFINITO);
    const [statoTendinaIscritti,setStatoTendinaIscritti]=useState(false);
    const [statoRicercaRateFattura,setStatoRicercaRateFattura]=useState(false);
    const [statoTendinaRisorse,setStatoTendinaRisorse]=useState(false);
    const [statoTendinaVociDiBilancio,setStatoTendinaVociDiBilancio]=useState(false);

    const [error,setError]=useState('');
    //const [risultatoServer,setRisultatoServer]=useState('');

    const [risultatoServerDatiIscritti,setRisultatoServerDatiIscritti]=useState('');
    const [risultatoServerDatiTutteRisorse,setRisultatoServerDatiTutteRisorse]=useState('');
    const [risultatoServerDatiTutteVociDiBilancio,setRisultatoServerDatiTutteVociDiBilancio]=useState('');

    const [aggiungiRataFattura,setAggiungiRataFattura]=useState(0);

    const [arrayRisorsaRataFatt,setArrayRisorsaRataFatt]=useState([]);
    const [arrayVociDiBilancioRataFatt,setArrayVociDiBilancioRataFatt]=useState([]);
    const [arrayVociDiBilancioAttRataFatt,setArrayVociDiBilancioAttRataFatt]=useState(['']);
    const [arrayImportoRataFatt,setArrayImportoRataFatt]=useState([]);
    const [arrayIvaRataFatt,setArrayIvaRataFatt]=useState([]);
    const [arrayDataScadenzaRataFatt,setArrayDataScadenzaRataFatt]=useState([]);
    const [arrayDataPagamentoRataFatt,setArrayDataPagamentoRataFatt]=useState([dataOggi]);
    const [arrayNoteRataFatt,setArrayNoteRataFatt]=useState([]);
    const [arrayNumeroRate,setArrayNumeroRate]=useState([]);

    const [richiediDistinzCognomeNome,setRichiediDistinzCognomeNome]=useState(0);
    const [permessoInserirePersona,setPermessoInserirePersona]=useState(0);
    const [permessoInserireRisorsa,setPermessoInserireRisorsa]=useState(0);
    const [permessoInserireVoceDiBilancio,setPermessoInserireVoceDiBilancio]=useState(0);
    const [permessoCancellazioneForzata,setPermessoCancellazioneForzata]=useState(0);

    const [risorseNuove,setRisorseNuove]=useState([]);
    const [vociDiBilancioNuove,setVociDiBilancioNuove]=useState([]);
    
    //const [defaultPersona,setDefaultPersona]=useState('');
    //const [defaultRisorse,setDefaultRisorse]=useState([]);
    //const [defaultVociDiBilancio,setDefaultVociDiBilancio]=useState([]);
   
    const [importoDefault,setImportoDefault]=useState('');
    //const [imponibileDefault,setImponibileDefault]=useState('');
    //const [ivaDefault,setIvaDefault]=useState('');
    const [descrizioneDefault,setDescrizioneDefault]=useState('');
    const [dataEmissioneDefault,setDataEmissioneDefault]=useState(dataOggi);
    const [noteDefault,setNoteDefault]=useState('');
    const [codiceFiscaleIscritto,setCodiceFiscaleIscritto]=useState('');

    let tipologia = "incassi_iscritti";

    const [insIscrittiEdi,setInsIscrittiEdi]=useState('');

    const [insStato,setInsStato]=useState("Non pagato");

    //const [rivisualizza,setRivisualizza]=useState(0);

    //const [aggiornatoImportoRata,setAggiornatoImportoRata]=useState(0);
    //const [aggiornatoIvaRata,setAggiornatoIvaRata]=useState(0);

    const [disabilitaBottoni,setDisabilitaBottoni]= useState(false);
    const [loading,setLoading]= useState(false);

    const [ricercaLogo,setRicercaLogo]=useState('');
    const [fileLogo,setFileLogo]=useState('');
    const [typeLogo,setTypeLogo]=useState('');
    const [nomeSocieta,setNomeSocieta]=useState('');
    const [capSocieta,setCapSocieta]=useState('');
    const [cittaSocieta,setCittaSocieta]=useState('');
    const [indirizzoSocieta,setIndirizzoSocieta]=useState('');
    const [pIvaSocieta,setPIvaSocieta]=useState('');
    const [codiceFiscaleSocieta,setCodiceFiscaleSocieta]=useState('');

    const [ricevuta,setRicevuta]=useState(0);

    var elem = document.getElementById("cont");
    const [larghezzaPdf,setLarghezzaPdf] = useState(0);

    const [fineIscritti, setFineIscritti] = useState(0);

    const [operazioneInterna,setOperazioneInterna]=useState('');
    
    useEffect(() => {
      if(elem) setLarghezzaPdf(elem.getBoundingClientRect().width); 
    },[elem]);

    useEffect(() => {
        if(statoPagina === STATO_PREPARA_PDF){
            setStatoPagina(STATO_SCARICA_PDF);
        }
    },[statoPagina]);

    useEffect(() => {
        setStatoPagina(STATO_INSERISCI);
        setStatoTendinaIscritti(false);
        setAggiungiRataFattura(0);
        setInsStato("Non pagato");
        setPermessoInserirePersona(0);
        setPermessoInserireRisorsa(0);
        setPermessoInserireVoceDiBilancio(0);
        setPermessoCancellazioneForzata(0);
        scaricaLogo();
        //setAggiornatoImportoRata(0);
        //setAggiornatoIvaRata(0);
    },[]);

    /*useEffect(() => {
        setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
    },[rivisualizza])*/

    useEffect(() => {
        if(statoTendinaIscritti === true && statoRicercaRateFattura === true &&  statoTendinaRisorse === true &&  statoTendinaVociDiBilancio === true && ricercaLogo===1){
            setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
        }
    },[statoTendinaIscritti,statoRicercaRateFattura,statoTendinaRisorse,statoTendinaVociDiBilancio,ricercaLogo]);

    useEffect(() => {
        let ese = [...arrayNumeroRate];//popolo l'array locale con i dati dell'array di stato
        ese.push(ese.length);//aggiungo un elemento (per ora vuoto) all'array locale
        setArrayNumeroRate(ese);//aggiorno nuovamente la var di stato
    },[aggiungiRataFattura]);

    useEffect(() => {
        aggiornaStatoPagamentoFattura();
    },[arrayDataPagamentoRataFatt,arrayNumeroRate]);

    useEffect(() => {
        if((props.ope==="modifica" || props.ope==="inserisci") && 
        (permessoInserirePersona===1 && permessoInserireRisorsa===1 && permessoInserireVoceDiBilancio===1)){
            if(richiediDistinzCognomeNome!==1 && richiediDistinzCognomeNome!==2){
                if(ricevuta===1) fetchOperazioneServer(0,1,1);
                else fetchOperazioneServer(0,0,1);
            } 
        }
        if(props.ope==="cancella" && permessoCancellazioneForzata === 1) fetchOperazioneServer();
    },[permessoInserirePersona,permessoInserireRisorsa,permessoInserireVoceDiBilancio,permessoCancellazioneForzata]);

    useEffect(() => {
        if(props.ope==='inserisci' || operazioneInterna === 'copia') setOperazione("fattureIns");
        if(props.ope==='modifica' && operazioneInterna === '') setOperazione("fattureMod");
        if(props.ope==='cancella') setOperazione("fattureCanc");

        if(operazioneInterna === 'copia'){
            setDisabilitaBottoni(false);
            setArrayDataPagamentoRataFatt([]);
            setStatoPagina(STATO_RICERCA_DATI_CORRETTA);
        }else{
            if(props.ope==="modifica" || props.ope==="cancella"){
                //devo eseguire una fetch per popolare i campi della scheda con i dati presi da db per il record in esame:
                // parametri per inviare i dati al server:
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({op:"fattureRecuperaDati",
                        tipologia:tipologia,
                        id:props.id,
                        emak: props.emak,
                        sessionId:props.sessionId
                    })
                };
                setStatoPagina(STATO_RICERCA_DATI);

                fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                .then(risposta => risposta.json())
                .then(
                    (valoreDiRitorno) => {
                        if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                            alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                        }
                        if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                        if(valoreDiRitorno.risultatoOperazione===1){
                        //setRisultatoServer(valoreDiRitorno.risultatoRitorno);
                        let componiPersona="";
                        if(valoreDiRitorno.risultatoRitorno[0].COGNOME_ISCRITTO !== '' && valoreDiRitorno.risultatoRitorno[0].COGNOME_ISCRITTO !== undefined) componiPersona = componiPersona + valoreDiRitorno.risultatoRitorno[0].COGNOME_ISCRITTO;
                        if((valoreDiRitorno.risultatoRitorno[0].NOME_ISCRITTO !== '' && valoreDiRitorno.risultatoRitorno[0].NOME_ISCRITTO !== undefined) && componiPersona !== "") componiPersona = componiPersona + " ";
                        if(valoreDiRitorno.risultatoRitorno[0].NOME_ISCRITTO !== '' && valoreDiRitorno.risultatoRitorno[0].NOME_ISCRITTO !==undefined) componiPersona = componiPersona + valoreDiRitorno.risultatoRitorno[0].NOME_ISCRITTO;
                        if((valoreDiRitorno.risultatoRitorno[0].NUMERO_TESSERA !== '' && valoreDiRitorno.risultatoRitorno[0].NUMERO_TESSERA !== undefined) && componiPersona !== "") componiPersona = componiPersona + " ";
                        if(valoreDiRitorno.risultatoRitorno[0].NUMERO_TESSERA !== '' && valoreDiRitorno.risultatoRitorno[0].NUMERO_TESSERA !== undefined) componiPersona = componiPersona + "("+valoreDiRitorno.risultatoRitorno[0].NUMERO_TESSERA+")";
                        //setDefaultPersona(componiPersona);
                        setInsIscrittiEdi(componiPersona);//serve per settare la var di stato se non cambi valore nella tendina in caso di modifica
                        setCodiceFiscaleIscritto(valoreDiRitorno.risultatoRitorno[0].CODICE_FISCALE_ISCRITTO);
                        setImportoDefault(valoreDiRitorno.risultatoRitorno[0].IMPORTO);
                        //setImponibileDefault(valoreDiRitorno.risultatoRitorno[0].IMPONIBILE);
                        //setIvaDefault(valoreDiRitorno.risultatoRitorno[0].IVA);
                        setDescrizioneDefault(valoreDiRitorno.risultatoRitorno[0].DESCRIZIONE);
                        setDataEmissioneDefault(valoreDiRitorno.risultatoRitorno[0].DATA_EMISSIONE);
                        setNoteDefault(valoreDiRitorno.risultatoRitorno[0].NOTE);

                        ricercaDatiIscritti();
                        ricercaDatiRateFattura();
                        ricercaDatiTutteRisorse();
                        ricercaDatiTutteVociDiBilancio();
                        }
                        },
                    (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
                ); 
            }else{
                setStatoPagina(STATO_RICERCA_DATI);
                ricercaDatiIscritti();
                ricercaDatiRateFattura(props.ope);
                ricercaDatiTutteRisorse();
                ricercaDatiTutteVociDiBilancio();
            }
        }
    },[props.ope,operazioneInterna]);

    function copiaMovimento(){
        if (window.confirm("Sei sicuro di voler procedere con la copia dell' incasso selezionato?")) {
            setDisabilitaBottoni(true);
            setStatoPagina(STATO_RICERCA_DATI);
            setOperazioneInterna("copia");
        }
    }

    function scaricaLogo() {
        //console.log("scaricaLogo");
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({op:"ricercaLogo", emak: props.emak,sessionId: props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
          .then(risposta => risposta.json())
          .then(
          (valoreDiRitorno) => {          
              if(valoreDiRitorno.risultatoOperazione===1){
                  if (valoreDiRitorno.risultatoRitorno[0].FILE !== undefined) {
                    setFileLogo(valoreDiRitorno.risultatoRitorno[0].FILE);
                    setTypeLogo(valoreDiRitorno.risultatoRitorno[0].TYPE);
                    setNomeSocieta(valoreDiRitorno.risultatoRitorno[0].NOME);
                    setCapSocieta(valoreDiRitorno.risultatoRitorno[0].CAP);
                    setCittaSocieta(valoreDiRitorno.risultatoRitorno[0].CITTA);
                    setIndirizzoSocieta(valoreDiRitorno.risultatoRitorno[0].INDIRIZZO);
                    setPIvaSocieta(valoreDiRitorno.risultatoRitorno[0].PIVA);
                    setCodiceFiscaleSocieta(valoreDiRitorno.risultatoRitorno[0].CODICE_FISCALE);
                  }
                  setRicercaLogo(1);
              }
          },
          (error) => {console.log("Errore connessione");}
        );        
      }

    function ricercaDatiIscritti() {
        //ricerco gli iscritti che mi servono per popolare le tendine:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoPersone",ridotto:1,recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                setRisultatoServerDatiIscritti(valoreDiRitorno.risultatoRitorno);
                setStatoTendinaIscritti(true);
                }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }

    function ricercaDatiRateFattura(operazione) {
        if(operazione==='inserisci'){
            setStatoRicercaRateFattura(true);
        }else{
            
            //ricerco tutte le rate della fattura in esame:
            setLoading(true);
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({op:"elencoRateFattura",recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId,idFattura:props.id,})
            };
    
            fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                .then(risposta => risposta.json())
                .then(
                (valoreDiRitorno) => {
                    setLoading(false);
                    if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                        alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                    }
                    if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                    if(valoreDiRitorno.risultatoOperazione===1){
             
                    let arrLocaleRisorsaRataFatt=[];
                    let arrLocaleVociDiBilancioRataFatt=[];
                    let arrLocaleVociDiBilancioAttRataFatt=[];
                    let arrLocaleImportoRataFatt=[];
                    let arrLocaleIvaRataFatt=[];
                    let arrLocaleDataScadenzaRataFatt=[];
                    let arrLocaleDataPagamentoRataFatt=[];
                    let arrLocaleNoteRataFatt=[];
                    let arrLocaleNumeroRate=[];
       
                    for (let i=0; i<valoreDiRitorno.risultatoRitorno.length ; i++) {
                                            
                                             
                        arrLocaleRisorsaRataFatt[i]=valoreDiRitorno.risultatoRitorno[i].RISORSA;    
                        //let risorseAppoggio = [...defaultRisorse];
                        //risorseAppoggio.push(valoreDiRitorno.risultatoRitorno[i].RISORSA);
                        //setDefaultRisorse(risorseAppoggio);
                        arrLocaleVociDiBilancioRataFatt[i]=valoreDiRitorno.risultatoRitorno[i].VOCE_DI_BILANCIO; 
                        arrLocaleVociDiBilancioAttRataFatt[i]=valoreDiRitorno.risultatoRitorno[i].TIPO_ATTIVITA; 
                        //let vociAppoggio = [...defaultVociDiBilancio];
                        //vociAppoggio.push(valoreDiRitorno.risultatoRitorno[i].VOCE_DI_BILANCIO);
                        //setDefaultVociDiBilancio(vociAppoggio);                  
                        arrLocaleImportoRataFatt[i]=FormattaImportoVideo(valoreDiRitorno.risultatoRitorno[i].IMPORTO_RATA);       
                        arrLocaleIvaRataFatt[i]=FormattaImportoVideo(valoreDiRitorno.risultatoRitorno[i].IVA_RATA);             
                        arrLocaleDataScadenzaRataFatt[i]=valoreDiRitorno.risultatoRitorno[i].DATA_SCADENZA_RATA;                    
                        arrLocaleDataPagamentoRataFatt[i]=valoreDiRitorno.risultatoRitorno[i].DATA_PAGAMENTO_RATA;                      
                        arrLocaleNoteRataFatt[i]=valoreDiRitorno.risultatoRitorno[i].NOTE_RATA;    
                        //aggiorno il numero di rate:
                        arrLocaleNumeroRate.push(i);
                        
                    } 
                    //setDefaultRisorse(arrLocaleRisorsaRataFatt);
                    //setDefaultVociDiBilancio(arrLocaleVociDiBilancioRataFatt);
                    
                    setArrayRisorsaRataFatt(arrLocaleRisorsaRataFatt);
                    setArrayVociDiBilancioRataFatt(arrLocaleVociDiBilancioRataFatt);
                    setArrayVociDiBilancioAttRataFatt(arrLocaleVociDiBilancioAttRataFatt);
                    setArrayImportoRataFatt(arrLocaleImportoRataFatt);
                    setArrayIvaRataFatt(arrLocaleIvaRataFatt);
                    setArrayDataScadenzaRataFatt(arrLocaleDataScadenzaRataFatt);
                    setArrayDataPagamentoRataFatt(arrLocaleDataPagamentoRataFatt);
                    setArrayNoteRataFatt(arrLocaleNoteRataFatt);
                    setArrayNumeroRate(arrLocaleNumeroRate);
                    setStatoRicercaRateFattura(true);
                }
                },
                (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
                );
        }
    }

    function ricercaDatiTutteRisorse() {
        //ricerco tutte le risorse disponibili che mi servono per popolare la tendina:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoRisorse",recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                setRisultatoServerDatiTutteRisorse(valoreDiRitorno.risultatoRitorno);         
                setStatoTendinaRisorse(true);
                }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }

    function ricercaDatiTutteVociDiBilancio() {
        //ricerco tutte le risorse disponibili che mi servono per popolare la tendina:
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"elencoVociDiBilancio",recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
                setRisultatoServerDatiTutteVociDiBilancio(valoreDiRitorno.risultatoRitorno);         
                setStatoTendinaVociDiBilancio(true);
                }
            },
            (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
    }

    function callbackSetValoreTendEditabileRisorsa(valore,index){
        let dati = [...arrayRisorsaRataFatt];//popolo l'array locale con i dati dell'array di stato
        dati.splice(index,1,valore);//);
        setArrayRisorsaRataFatt(dati);//aggiorno nuovamente la var di stato
    }

    function callbackSetValoreTendEditabileVociDiBilancio(valore,index,valoreNonVisibile){
        let dati = [...arrayVociDiBilancioRataFatt];//popolo l'array locale con i dati dell'array di stato
        dati.splice(index,1,valore);//);
        setArrayVociDiBilancioRataFatt(dati);//aggiorno nuovamente la var di stato

        let dati1 = [...arrayVociDiBilancioAttRataFatt];//popolo l'array locale con i dati dell'array di stato
        dati1.splice(index,1,valoreNonVisibile);//);
        setArrayVociDiBilancioAttRataFatt(dati1);//aggiorno nuovamente la var di stato
    }
    /*function aggiornaProssimaVoceDiBilancio(index){
        //quando aggiungo un pagamento: controllo la prima voce di bilancio e la pongo uguale: in questo modo suggesco l'omogeneità 
        //delle voci dando comunque la possibilità di variarle
        
        let dati = [...arrayVociDiBilancioRataFatt];//popolo l'array locale con i dati dell'array di stato
        if(!dati[index+1] || (dati[index+1] && dati[index+1]=='')){
            dati.splice(index+1,1,arrayVociDiBilancioRataFatt[0]);
            setArrayVociDiBilancioRataFatt(dati);//aggiorno nuovamente la var di stato
        }
    }
    function aggiornaProssimaDataPagamento(index,dataOggi){
        //la data di pagamento successiva la pondo di default = alla data di oggi:
        let dati = [...arrayDataPagamentoRataFatt];//popolo l'array locale con i dati dell'array di stato
        if(!dati[index+1] || (dati[index+1] && dati[index+1]=='')){
            dati.splice(index+1,1,dataOggi);
            setArrayDataPagamentoRataFatt(dati);//aggiorno nuovamente la var di stato
        }
    }*/

    function tornaElenco() {
        props.callbackTermineModifica();
    }
    function tornaScheda() {

        setFlagAttivaModaleIncassoNonCancellabile(0);
        setFlagAttivaModaleInserisciIscritto(0);
        setFlagAttivaModaleRichiediPermessoInserire(0);
        setFlagAttivaModaleRichiediNumeroTessera(0);
        setFlagAttivaModaleInserisciDescrizione(0);
        setFlagAttivaModaleInserisciDataPagamento(0);
        setFlagAttivaModaleInserisciDataEmissione(0);
        setFlagAttivaModaleInserisciRisorsa(0);
        setFlagAttivaModaleInserisciVoceBilancio(0);
        setFlagAttivaModaleInserisciImporto(0);
        setFlagAttivaModaleInserisciImportoPagamento(0);
        setFlagAttivaModaleImponibileErrato(0);
        setFlagAttivaModaleImportoErrato(0);
        setFlagAttivaModaleIvaErrata(0);
        setFlagAttivaModaleImportoPagamentoErrato(0);
        setFlagAttivaModaleIvaPagamentoErrata(0);
        setRicevuta(0);
        setFineIscritti(0);
    }

    function handleCambiamentoMod(event) {
        //event.preventDefault();
        setVariazioneCampi(1);
    }

   
    /*function eliminaRataFattura(posizione) {
        let nrat = [...arrayNumeroRate];//popolo l'array locale con i dati dell'array di stato
        nrat.splice(posizione, 1);//cancello l'elemento desiderato
        setArrayNumeroRate(nrat);//aggiorno nuovamente la var di stato

        let riso = [...arrayRisorsaRataFatt];//
        riso.splice(posizione, 1);//
        setArrayRisorsaRataFatt(riso);//
        //setDefaultRisorse(riso);

        let voci = [...arrayVociDiBilancioRataFatt];//
        voci.splice(posizione, 1);//
        setArrayVociDiBilancioRataFatt(voci);//
        //setDefaultVociDiBilancio(voci);

        let dscad = [...arrayDataScadenzaRataFatt];//
        dscad.splice(posizione, 1);//
        setArrayDataScadenzaRataFatt(dscad);//

        let dpag = [...arrayDataPagamentoRataFatt];//
        dpag.splice(posizione, 1);//
        setArrayDataPagamentoRataFatt(dpag);//

        let importo = [...arrayImportoRataFatt];//
        importo.splice(posizione, 1);//
        setArrayImportoRataFatt(importo);//

        let iva = [...arrayIvaRataFatt];//
        iva.splice(posizione, 1);//
        setArrayIvaRataFatt(iva);//

        let note = [...arrayNoteRataFatt];//
        note.splice(posizione, 1);//
        setArrayNoteRataFatt(note);//

        //setRivisualizza(rivisualizza+1);
    }*/

    function aggiornaStatoPagamentoFattura(){
        let datiStato = [...arrayDataPagamentoRataFatt];
        let i = 0;
        let controlloPagamentoFattura = 0;
        for (i=0; i<datiStato.length; i++) { 
            //console.log("posizione pagamento " + i + " data pagamento  " + datiStato[i]);
            if(datiStato[i]!==undefined && datiStato[i]!==''){
                //ad ogni rata non pagata incremento la variabile:
                controlloPagamentoFattura=controlloPagamentoFattura+1;
            }
        }
  //console.log("controllo pagamento fattura  " + controlloPagamentoFattura + "mentre le rate sono "+ arrayNumeroRate.length);
        //controllo lo stato della variabile:
        if(controlloPagamentoFattura===0){
            setInsStato("Non pagato");
            //console.log("setto NON PAGATA ");
        }else{
            if(controlloPagamentoFattura!==arrayNumeroRate.length){
                setInsStato("Pagato parzialmente");
                //console.log("setto PAGATA PARZIALMENTE");
            }else{
                setInsStato("Pagato");
                //console.log("setto PAGATA");
            }
        }
    }

    /*function aggiornaDataScadenzaRata(index,dato){
        let dati = [...arrayDataScadenzaRataFatt];//popolo l'array locale con i dati dell'array di stato
        dati.splice(index,1,dato);//modifico l'elemento desiderato
        setArrayDataScadenzaRataFatt(dati);//aggiorno nuovamente la var di stato
    }*/

    function aggiornaDataPagamentoRata(index,dato){
        let dati = [...arrayDataPagamentoRataFatt];//popolo l'array locale con i dati dell'array di stato
        dati.splice(index,1,dato);//modifico l'elemento desiderato
        setArrayDataPagamentoRataFatt(dati);//aggiorno nuovamente la var di stato   
    }

    function aggiornaImportoRata(index,dato,aggiornatoAMano=0){
        let dati = [...arrayImportoRataFatt];//popolo l'array locale con i dati dell'array di stato
        dati.splice(index,1,dato);//modifico l'elemento desiderato
        setArrayImportoRataFatt(dati);//aggiorno nuovamente la var di stato
        //if(aggiornatoAMano === 0) setAggiornatoImportoRata(1);
        //ricalcolaIvaRate(importoDefault,ivaDefault,dati);
    }


    function annullaInserimentoDatiNuovi(){
        setRichiediDistinzCognomeNome(0);
        setPermessoInserirePersona(0);
        setPermessoInserireRisorsa(0);
        setPermessoInserireVoceDiBilancio(0);
        setPermessoCancellazioneForzata(0);

        tornaScheda();
        //setStatoPagina(STATO_INSERISCI);
    }

    function confermaInserimentoDatiNuovi(){
        setPermessoInserirePersona(1);
        setPermessoInserireRisorsa(1);
        setPermessoInserireVoceDiBilancio(1);
        setPermessoCancellazioneForzata(1);
        //setStatoPagina(STATO_INSERISCI);
    }

    /*function allineaImportoPagamentoAlTotale(importoFattura){
        if(props.ope==='inserisci' && arrayNumeroRate.length==1 && aggiornatoImportoRata==0){
            aggiornaImportoRata(0,FormattaImportoDb(importoFattura),1);
        }
    }
    function allineaIvaPagamentoAlTotale(ivaFattura){
        if(props.ope==='inserisci' && arrayNumeroRate.length==1 && aggiornatoIvaRata==0){
            aggiornaIvaRata(0,FormattaImportoDb(ivaFattura),1);
        }
    }
    function ricalcolaIvaRate(importoTotFattura,ivaTotFattura,arrayImportoRataFatt){
        //per ogni rata calcolo il valore dell'iva in proporzione a quanto ammonta l'importo della rata rispetto all'importo totale fattura
        for (let i=0; i<arrayImportoRataFatt.length ; i++) {
            let importoIvaRata = 0;
            importoIvaRata = FormattaImportoDb(arrayImportoRataFatt[i]) * FormattaImportoDb(ivaTotFattura) / FormattaImportoDb(importoTotFattura);
            aggiornaIvaRata(i,FormattaImportoVideo(importoIvaRata.toFixed(2)));
        }
    }*/

    function fetchOperazioneServer(bypassaControlli=0,presentaRicevuta=0,permessoInserire=0) {
        setFlagAttivaModaleRichiediPermessoInserire(0);
        //invio l'azione da fare sul server (aggiungi/togli) e l'elemento in esame al server che mi ritornerà l'elenco aggiornato
        let controlli=0;

        //cancello comunque;
        if(props.ope!=='cancella' && bypassaControlli===0){
            if(note.current.value.length>500){
                controlli = 1;
                alert("Il campo Note contiene troppi caratteri (massimo accettato 500)");
                setDisabilitaBottoni(false);
            }
            if(insIscrittiEdi=== ''){
                controlli = 1;
                setFlagAttivaModaleInserisciIscritto(1);
            }
            if(descrizione.current.value=== ''){
                controlli = 1;
                setFlagAttivaModaleInserisciDescrizione(1);
            }
            /*if(importo.current.value== ''){
                controlli = 1;
                setModaleInserisciImporto(true);
                setFlagAttivaModaleInserisciImporto(1);
            }*/
            /*if(dataEmissione.current.value== ''){
                controlli = 1;
                setModaleInserisciDataEmissione(true);
                setFlagAttivaModaleInserisciDataEmissione(1);
            }*/
            /*if(isNaN(FormattaImportoDb(importo.current.value))){
                controlli = 1;
                setModaleImportoErrato(true);
                setFlagAttivaModaleImportoErrato(1);
            }*/
            /*if(isNaN(FormattaImportoDb(imponibile.current.value))){
                controlli = 1;
                setModaleImponibileErrato(true);
                setFlagAttivaModaleImponibileErrato(1);
            }*/
            /*if(isNaN(FormattaImportoDb(iva.current.value))){
                controlli = 1;
                setModaleIvaErrata(true);
                setFlagAttivaModaleIvaErrata(1);
            }*/
            //controllo che ogni pagamento abbia la data di pagamento indicata:
            for (let i=0; i<arrayNumeroRate.length; i++) { 
                if(arrayDataPagamentoRataFatt[i]==='' || !(arrayDataPagamentoRataFatt[i])){
                    controlli = 1;
                    setFlagAttivaModaleInserisciDataPagamento(1);             
                }else{
                    arrayDataPagamentoRataFatt[i] = controllaDataAAAAMMGG(arrayDataPagamentoRataFatt[i], 2015);
                    if (arrayDataPagamentoRataFatt[i] == "errore") {
                        var nPag = i+1;
                        controlli = 1;
                        alert("La Data di Pagamento non è valida");
                        setDisabilitaBottoni(false);
                        annullaInserimentoDatiNuovi();
                    }
                }
                if(arrayRisorsaRataFatt[i]==='' || !(arrayRisorsaRataFatt[i])){
                    controlli = 1;
                    setFlagAttivaModaleInserisciRisorsa(1);             
                }
                if(arrayVociDiBilancioRataFatt[i]==='' || !(arrayVociDiBilancioRataFatt[i])){
                    controlli = 1;
                    setFlagAttivaModaleInserisciVoceBilancio(1);             
                }
                if(arrayImportoRataFatt[i]==='' || !(arrayImportoRataFatt[i])){
                    controlli = 1;
                    setFlagAttivaModaleInserisciImportoPagamento(1);             
                }
                if(isNaN(FormattaImportoDb(arrayImportoRataFatt[i]))){
                    controlli = 1;
                    setFlagAttivaModaleImportoPagamentoErrato(1);
                }
                /*if(isNaN(FormattaImportoDb(arrayIvaRataFatt[i]))){
                    controlli = 1;
                    setModaleIvaPagamentoErrata(true);
                    setFlagAttivaModaleIvaPagamentoErrata(1);
                }*/
            }
        }

        if(controlli===0){
            if(props.ope==='modifica' && variazioneCampi===0){
                //in relatà non e stata fatta alcuna variazione nei campi originali per cui torno ad elenco:
                tornaElenco();
            }else{
                // parametri per inviare i dati al server:
                let cog="";
                if(richiediDistinzCognomeNome===1) cog = cognome.current.value;

                setLoading(true);
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: props.ope!=='cancella' ?  JSON.stringify({op:operazione,
                        persona:insIscrittiEdi,
                        cognome:cog,
                        permessoInserirePersona:permessoInserire,
                        permessoInserireRisorsa:permessoInserire,
                        permessoInserireVoceDiBilancio:permessoInserire,
                        importo:FormattaImportoDb(arrayImportoRataFatt[0]),
                        imponibile:FormattaImportoDb(arrayImportoRataFatt[0]),
                        iva:FormattaImportoDb("0"),
                        tipologia:tipologia,
                        descrizione:descrizione.current.value,
                        dataEmissione:arrayDataPagamentoRataFatt[0],
                        stato:insStato,
                        note:note.current.value,
                        arrayRisorsaRataFatt:arrayRisorsaRataFatt,
                        arrayVociDiBilancioRataFatt:arrayVociDiBilancioRataFatt,
                        arrayDataScadenzaRataFatt:arrayDataScadenzaRataFatt ,
                        arrayDataPagamentoRataFatt:arrayDataPagamentoRataFatt,  
                        arrayImportoRataFatt:arrayImportoRataFatt, 
                        arrayIvaRataFatt:arrayIvaRataFatt, 
                        arrayNoteRataFatt:arrayNoteRataFatt,   
                        arrayNumeroRate:arrayNumeroRate,  

                        idElemento:props.id,
                        emak: props.emak,
                        sessionId:props.sessionId
                    })
                    : 
                        JSON.stringify({op:operazione,
                        idElemento:props.id,
                        permessoCancellazioneForzata:permessoCancellazioneForzata,
                        emak: props.emak,
                        sessionId:props.sessionId})
                };
            
                // qui faccio la chiamata alla pagina sul server, quindi ne leggo la risposta che io trasformo in oggetto json 
                // con .json() ottenendo "valore_di_ritorno": in questo oggetto json ho "risultato_ritorno" che metto nella 
                // variabile di stato "risultato_server"
                //setStatoPagina(STATO_MEMORIZZAZIONE);

                fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
                    .then(risposta => risposta.json())
                    .then(
                    (valoreDiRitorno) => {
                        setLoading(false);
                        if(valoreDiRitorno.risultatoOperazione===1){
                            if(presentaRicevuta === 0){
                                tornaElenco(1);
                            }else{
                                setStatoPagina(STATO_PREPARA_PDF)
                            }
                        }
                        if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                        if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                            if(valoreDiRitorno.mess !== undefined){
                                if(valoreDiRitorno.mess.indexOf("Indicare numero di tessera per individuazione") !== -1){
                                    setFlagAttivaModaleRichiediNumeroTessera(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Richiedi permesso per inserire iscritto") !== -1){
                                    setPermessoInserirePersona(-1);
                                    setFlagAttivaModaleRichiediPermessoInserire(1);
                                } 
                                if(valoreDiRitorno.mess.indexOf("Indica quale è cognome e quale nome") !== -1){
                                    setPermessoInserirePersona(-1);
                                    setFlagAttivaModaleRichiediPermessoInserire(1);
                                    setRichiediDistinzCognomeNome(1);
                                } 
                                if(valoreDiRitorno.mess.indexOf("Reindica quale è cognome e quale nome") !== -1){
                                    setPermessoInserirePersona(-1);
                                    setFlagAttivaModaleRichiediPermessoInserire(1);
                                    setRichiediDistinzCognomeNome(2);
                                } 
                                if(valoreDiRitorno.mess.indexOf("Richiedi permesso per inserire risorse") !== -1){
                                    setPermessoInserireRisorsa(-1);
                                    setRisorseNuove(valoreDiRitorno.risorse_nuove);
                                    setFlagAttivaModaleRichiediPermessoInserire(1);
                                } 
                                if(valoreDiRitorno.mess.indexOf("Richiedi permesso per inserire voci di bilancio") !== -1){
                                    setPermessoInserireVoceDiBilancio(-1);
                                    setVociDiBilancioNuove(valoreDiRitorno.voci_di_bilancio_nuove);
                                    setFlagAttivaModaleRichiediPermessoInserire(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Richiedi titolare fattura") !== -1){
                                    setFlagAttivaModaleInserisciIscritto(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Dato non cancellabile: presente in prima nota") !== -1){
                                    setPermessoCancellazioneForzata(-1);
                                    setFlagAttivaModaleIncassoNonCancellabile(1);
                                } 
                                if(valoreDiRitorno.mess.indexOf("Descrizione non valorizzata") !== -1){
                                    setFlagAttivaModaleInserisciDescrizione(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Data pagamento non valorizzata") !== -1){
                                    setFlagAttivaModaleInserisciDataPagamento(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Data emissione non valorizzata") !== -1){
                                    setFlagAttivaModaleInserisciDataEmissione(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Risorsa non valorizzata") !== -1){
                                    setFlagAttivaModaleInserisciRisorsa(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Voce bilancio non valorizzata") !== -1){
                                    setFlagAttivaModaleInserisciVoceBilancio(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Importo non valorizzata") !== -1){
                                    setFlagAttivaModaleInserisciImporto(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Importo pagamento non valorizzata") !== -1){
                                    setFlagAttivaModaleInserisciImportoPagamento(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Importo pagamento errato") !== -1){
                                    setFlagAttivaModaleImportoPagamentoErrato(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Iva pagamento errata") !== -1){
                                    setFlagAttivaModaleIvaPagamentoErrata(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Importo errato") !== -1){
                                    setFlagAttivaModaleImportoErrato(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Imponibile errato") !== -1){
                                    setFlagAttivaModaleImponibileErrato(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Iva errata") !== -1){
                                    setFlagAttivaModaleIvaErrata(1);
                                }
                                if(valoreDiRitorno.mess.indexOf("Iscritti Terminati") !== -1){
                                    setFineIscritti(1);
                                }
                            }
                            //altrimenti vado in errore generico
                            if(!valoreDiRitorno.mess) setStatoPagina(STATO_OPERAZIONE_FALLITA);
                        } 
                    },
                    (error) => {setLoading(false);setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
                );
            }
        }
    }

    function aggiornaNote(nota){
        // Crea una copia dell'array corrente
         const nuovoArray = [...arrayNoteRataFatt];
        // Modifica il primo elemento dell'array con il valore di 'nota'
        nuovoArray[0] = nota;
        // Aggiorna lo stato con il nuovo array
        setArrayNoteRataFatt(nuovoArray);
    }

    //JSON.stringify(risultatoServer)
        return (
            <div id="cont" style={{minHeight: "100%",backgroundColor:"#F2F2F2"}}>

                {statoPagina === STATO_SCARICA_PDF ? 
                        <div>
                        {/*<PDFViewer style={styles.viewer}>*/}
                        <div style={{height: props.dimensioniApp.yMain,width: larghezzaPdf,display:"flex"}}><Clessidra loading={true}/></div>
                        <PDFViewer style={{height: props.dimensioniApp.yMain,width: larghezzaPdf ,display:"flex", flexDirection:"column",backgroundColor:"transparent",position:"absolute",top:props.dimensioniApp.yTitolo}}>
    
                            <Document>
                            <Page size="A4" style={styles.page}>
                                <View style={{width:"100%"}} >
                                    <View style={styles.tableIntestazione}> {/*apre table intestazione*/}
                                        <View wrap={false} style={styles.tableRowIntestazione}> 
                                            <View style={styles.tableColIntestazioneSx}> 
                                                <View style={styles.tableCell}> 
                                                    {fileLogo !== "" ? <Image style={styles.logo} src={"data:" + typeLogo + ";base64," + fileLogo} />:<Text></Text>}                                    
                                                </View> 
                                            </View> 
                                            <View style={styles.tableColIntestazioneDx}>   
                                                <View style={styles.tableCellIntestazione}> 
                                                    {nomeSocieta !== "" ?<Text style={styles.nomesocieta}>{nomeSocieta}</Text>:<Text></Text>}

                                                    {indirizzoSocieta !== "" ?
                                                    <Text style={styles.datisocieta}>{indirizzoSocieta}
                                                    {cittaSocieta !== "" ?
                                                        capSocieta !== "" ?
                                                            " "+capSocieta+" "+cittaSocieta
                                                        :" "+cittaSocieta
                                                    :""}
                                                    </Text>
                                                    :<Text></Text>}
                                                    {pIvaSocieta !== "" ?<Text style={styles.datisocieta}>Partita IVA: {pIvaSocieta}</Text>:<Text></Text>}
                                                    {codiceFiscaleSocieta !== "" ?<Text style={styles.datisocieta}>Codice Fiscale: {codiceFiscaleSocieta}</Text>:<Text></Text>}
                                                

                                                    <Text style={styles.titolo}>
                                                         Ricevuta Incasso
                                                    </Text>
                                                </View> 
                                            </View> 
                                        </View> 
                                    </View> {/*chiude table intestazione*/}

                   
                                            <View style={styles.rigaRicevuta}> 
                                                <Text style={styles.testoGrande}>Si dichiara di ricevere dall' iscritto </Text>
                                            </View>  
                                            

                                            <View style={styles.rigaRicevutaCentrata}> 
                                                <Text style={styles.testoGrassetto}>{insIscrittiEdi}</Text>  
                                            </View> 
                                            {codiceFiscaleIscritto !=="" ? <View style={styles.rigaRicevutaCentrata}><Text style={styles.testoGrassetto}>{codiceFiscaleIscritto}</Text></View>:""} 

                                            <View style={styles.rigaRicevuta}> 
                                                <Text style={styles.testoGrande}>in data</Text>  
                                            </View>  

                                            <View style={styles.rigaRicevutaCentrata}> 
                                                <Text style={styles.testoGrassetto}><TrasformaDateAUmano data={arrayDataPagamentoRataFatt[0]}></TrasformaDateAUmano></Text>  
                                            </View>  

                                            <View style={styles.rigaRicevuta}> 
                                                <Text style={styles.testoGrande}>la somma pari a</Text>   
                                            </View> 

                                            <View style={styles.rigaRicevutaCentrata}> 
                                                <Text style={styles.testoGrassetto}>{FormattaImportoVideo(arrayImportoRataFatt[0])} euro</Text>  
                                            </View> 
                                        
                                            <View style={styles.rigaRicevuta}>  
                                                <Text style={styles.testoGrande}>
                                                descrizione :  {descrizioneDefault}
                                                </Text>  
                                            </View>  
                                </View>
                            <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (`${pageNumber} / ${totalPages}`)} fixed />
                            </Page>
                        </Document>
                        </PDFViewer>
                    </div>

                :""}



                {/*eccola {props.sessionId}*/}
            
                {statoPagina === STATO_MEMORIZZAZIONE ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}
                {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1C_IncDivIscr {error.message}</div> :""}
                {statoPagina === STATO_OPERAZIONE_FALLITA ? <div>Errore per operazione errata</div> :""}
                {statoPagina === STATO_RICERCA_DATI ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}
                {statoPagina === STATO_PREPARA_PDF ? <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
                <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}><Clessidra loading={true}/>
                </div>
                </div> :""}

                {statoPagina === STATO_RICERCA_DATI_CORRETTA || statoPagina === STATO_SCARICA_PDF ||statoPagina === STATO_PREPARA_PDF ?

                <div style={{display:statoPagina === STATO_SCARICA_PDF?"none":"block"}}>
                <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>

                    {flagAttivaModaleInserisciImportoPagamento===1 ?
                        <Modale 
                            titolo="Richiesta importo pagamento"
                            flagErrore={true}
                            contenuto={<div>Non è stato indicato l'importo pagamento, che è necessario</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModaleInserisciDataPagamento===1 ?
                        <Modale 
                            titolo="Richiesta importo pagamento"
                            flagErrore={true}
                            contenuto={<div>Non è stata indicata la data pagamento, che è necessaria</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModaleInserisciDataEmissione===1 ?
                        <Modale 
                            titolo="Richiesta data emissione"
                            flagErrore={true}
                            contenuto={<div>Non è stata indicata la data emissione, che è necessaria</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModaleInserisciVoceBilancio===1 ?
                        <Modale 
                            titolo="Richiesta voce bilancio"
                            flagErrore={true}
                            contenuto={<div>Non è stata indicata la voce di bilancio, che è necessaria</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModaleInserisciRisorsa===1 ?
                        <Modale 
                            titolo="Richiesta risorsa"
                            flagErrore={true}
                            contenuto={<div>Non è stata indicata la risorsa, che è necessaria</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModaleInserisciDescrizione===1 ?
                        <Modale 
                            titolo="Richiesta descrizione"
                            flagErrore={true}
                            contenuto={<div>Non è stata indicata la descrizione, che è necessaria</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        /> 
                    :""}

                    {flagAttivaModaleInserisciImporto===1 ?
                        <Modale 
                            titolo="Richiesta importo"
                            flagErrore={true}
                            contenuto={<div>Non è stato indicato l'importo, che è necessario</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        /> 
                    :""} 

                    {flagAttivaModaleIncassoNonCancellabile===1 ?
                        <Modale 
                            titolo="Informativa cancellazione incasso"
                            flagErrore={true}
                            contenuto={<div>L'incasso selezionato è associato ad uno o più record in prima nota. Premendo Ok si andrà a cancellare sia il record relativo all'incasso in esame sia i relativi record in prima nota</div>}
                            bottoni={[
                                {
                                    "etichetta":"Ok",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);setPermessoCancellazioneForzata(1);fetchOperazioneServer(1)}
                                } ,
                                {
                                    "etichetta":"Annulla",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        /> 
                    :""}

                    {fineIscritti=== 1 ?
                        <Modale 
                        titolo="Informativa numero massimo iscritti"
                        flagErrore={true}
                        contenuto={<div>E' stato raggiunto il numero massimo di iscritti</div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                            }    
                        ]}
                    />
                    :""}

                    {flagAttivaModaleRichiediPermessoInserire===1 ?
                        <Modale 
                        titolo="Richiesta permesso inserimento"
                        flagErrore={true}
                        larghezza="lg"
                        contenuto={<div>

                            {permessoInserirePersona===-1 ? <>Vuoi davvero inserire {insIscrittiEdi} nell'elenco degli iscritti?<br/></> :""}
                            {permessoInserireRisorsa===-1 ? <>Vuoi davvero inserire le seguenti nell'elenco delle risorse? 
                                    {risorseNuove !== undefined ?
                                        risorseNuove.map(risorseNuoveElemento =>
                                            <div key={risorseNuoveElemento}>
                                            <Label>{risorseNuoveElemento}</Label>
                                            </div>                                                   
                                        )
                                    :""}<br/></> :""}
                            {permessoInserireVoceDiBilancio===-1 ?<>Vuoi davvero inserire le seguenti nell'elenco delle voci di bilancio?
                                        {vociDiBilancioNuove !== undefined ?
                                        vociDiBilancioNuove.map(vociDiBilancioNuoveElemento =>
                                            <div key={vociDiBilancioNuoveElemento}>
                                            <Label>{vociDiBilancioNuoveElemento}</Label>
                                            </div>                                                   
                                        )
                                    :""}<br/>
                                    Inizialmente le nuove voci di bilancio verrnno impostate come attività principale.<img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setFlagAttivaModaleSpiegaAttivita(1)}/><br/> E' possibile modificare l'impostazione recandosi in Gestione Economica, Voci di Bilancio, selezionare la voce desiderata e attuare le modifiche.<br/>
                                    </> :""}

                            {richiediDistinzCognomeNome===1 && permessoInserirePersona!==-1 ? <>Indica qual'è il Cognome dell'Iscritto che intendi inserire:<br/></> :""} 
                            {richiediDistinzCognomeNome===2 && permessoInserirePersona!==-1 ? <>Indicazione del Cognome non ritenuta valida. Indicare nuovamente qual'è il Cognome dell'Iscritto che intendi inserire:<br/></> :""}
                        
                            {(richiediDistinzCognomeNome===1 || richiediDistinzCognomeNome===2) && permessoInserirePersona!==-1?
                                <FormGroup>
                                <Label for="cognome">Cognome</Label>
                                <Input  maxLength={50} type="text" name="cognome" id="cognome"
                                        innerRef={cognome} 
                                         placeholder=""
                                        defaultValue={insIscrittiEdi}
                                /> 
                                </FormGroup>
                            :""}
                        </div>}      
                        bottoni={
                         
                                [{
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);confermaInserimentoDatiNuovi()}
                                },  
                                {
                                "etichetta":"Annulla",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                                }]
                      
                        }
                        />
                    :""}

{/*
                    
                    {flagAttivaModaleRichiediPermessoInserire===1 ?
                        <Modale 
                            titolo="Richiesta permesso inserire"
                            flagErrore={true}
                            contenuto={<div><Table responsive><thead>
                                {permessoInserirePersona===-1 ? <tr><th><center>Vuoi davvero inserire {insIscrittiEdi} nell'elenco degli iscritti?</center></th></tr> :""}
                                {permessoInserireRisorsa===-1 ? 
                                    <tr><th><center>Vuoi davvero inserire le seguenti nell'elenco delle risorse? 
                                    {risorseNuove !== undefined ?
                                        risorseNuove.map(risorseNuoveElemento =>
                                            <div key={risorseNuoveElemento}>
                                            <Label>{risorseNuoveElemento}</Label>
                                            </div>                                                   
                                        )
                                    :""}
                                    </center></th></tr>
                                :""}
                                {permessoInserireVoceDiBilancio===-1 ? 
                                    <tr><th><center>Vuoi davvero inserire le seguenti nell'elenco delle voci di bilancio?
                                        {vociDiBilancioNuove !== undefined ?
                                        vociDiBilancioNuove.map(vociDiBilancioNuoveElemento =>
                                            <div key={vociDiBilancioNuoveElemento}>
                                            <Label>{vociDiBilancioNuoveElemento}</Label>
                                            </div>                                                   
                                        )
                                    :""}                                                  
                                    </center></th></tr> 
    
                                :""}
                                {richiediDistinzCognomeNome!==1 && richiediDistinzCognomeNome!==2 ? 
                                    <tr><th><center><Button color="success" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(false);confermaInserimentoDatiNuovi()}}>Conferma</Button>
                                    <Button color="primary" className="mt-2" onClick={() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}}>Annulla</Button></center></th></tr>  
                                :""}
    
                                {richiediDistinzCognomeNome===1 ? <tr><th><center>Indica qual'è il Cognome dell'Iscritto che intendi inserire:</center></th></tr> :""} 
                                {richiediDistinzCognomeNome===2 ? <tr><th><center>Indicazione del Cognome non ritenuta valida: indicare nuovamente qual'è il Cognome dell'Iscritto che intendi inserire:</center></th></tr> :""} 
                                {richiediDistinzCognomeNome===1 || richiediDistinzCognomeNome===2?
                                    <tr><th><center><Form>
                                    <FormGroup>
                                        <Label for="cognome">Cognome</Label>
                                        <Input maxLength={50} type="text" name="cognome" id="cognome"
                                            innerRef={cognome} 
                                            placeholder=""
                                            defaultValue={insIscrittiEdi}
                                            /> 
                                    </FormGroup>
                                    </Form>
                                    </center></th></tr>
                                :""}
                                {richiediDistinzCognomeNome===1 || richiediDistinzCognomeNome===2 ? 
                                <tr><th><center><Button color="success" className="mt-2  bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(false);confermaInserimentoDatiNuovi()}}>Conferma</Button>
                                <Button color="primary" className="mt-2" onClick={() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}}>Annulla</Button>
                                </center></th></tr>  :""} 
                                </thead></Table></div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);tornaScheda()}
                                }    
                            ]}
                        /> 
                    :""}
                        */}


                    {flagAttivaModaleSpiegaAttivita=== 1 ?
                        <Modale 
                        titolo="Informativa Attività Principali / Secondarie"
                        flagErrore={false}
                        larghezza="lg"
                        contenuto={<div><p>La classificazione di una voce di bilancio come attività principale dipende dalla sua natura.</p> 
                            <p>Per attività principale si intende tutto ciò che è connesso direttamente alle attività sportive ed alle responsabilità istituzionali.
                            L'attivita principale coinvolge i ruoli fondamentali per la pratica sportiva stessa, come istruttori, allenatori, arbitri, tecnici e atleti.
                            Inoltre riguarda tutto ciò che è indispensabile allo svolgimento delle attività sportive: attrezzature, materiali sportivi e strutture adeguate ecc..</p>
                            <p>Sono invece da includere nelle attività secondarie tutto ciò che non è da considerarsi indispensabile per la pratica sportiva diretta.
                            Si tratta spesso di attività legate all’organizzazione e al funzionamento delle strutture sportive.
                            Ad esempio: addetti alla manutenzione degli impianti, addetti alla reception, gestione dell'area ristoro o del merchandising sportivo ecc.. </p>
                            <p>La distinzione tra le attività è utile per adempiere alle norme relative alla Riforma dello Sport.</p>
                            Il <b>report "Attività Principali / Secondarie"</b>, disponibile nella sezione Report, può essere un utile strumento per confrontare il volume di attività 
                            principali e secondarie. 
                            <p>Fornisce inoltre un'analisi dettagliata del rapporto tra attività secondarie e ricavi totali, nonché tra attività secondarie e costi totali.</p>
                        </div>}
                        bottoni={[
                            {
                                "etichetta":"OK",
                                "tipo":"primary",
                                callback:() => {setDisabilitaBottoni(false);setFlagAttivaModaleSpiegaAttivita(0)}
                            }    
                        ]}
                        />
                    :""}



                    {flagAttivaModaleRichiediNumeroTessera===1 ?
                        <Modale 
                            titolo="Richiesta numero tessera"
                            flagErrore={true}
                            contenuto={<div>Nel database ci sono più iscritti con il Cognome ed il Nome: {insIscrittiEdi} Si prega di indicare il numero di tessera in modo da poter individuare quello desiderato oppure per procedere con l'inserimento di un nuovo iscritto qualora il numero di tessera non corrispondesse a nessuno degli iscritti individuati</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                                }    
                            ]}
                        />
                    :""}

                    {flagAttivaModaleInserisciIscritto===1 ?
                        <Modale 
                            titolo="Richiesta iscritto"
                            flagErrore={true}
                            contenuto={<div>Non è stato indicato l'iscritto, che è necessario</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                                }    
                            ]}
                        /> 
                    :""}
                    
                    {flagAttivaModaleImportoErrato===1 ?
                        <Modale 
                            titolo="Informativa importo errato"
                            flagErrore={true}
                            contenuto={<div>L'importo inserito non è un numero: completare il campo con un valore numerico</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                                }    
                            ]}
                        />  
                    :""}

                    {flagAttivaModaleImponibileErrato===1 ?
                        <Modale 
                            titolo="Informativa imponibile errato"
                            flagErrore={true}
                            contenuto={<div>L'importo inserito per l'imponibile non è un numero: completare il campo con un valore numerico</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                                }    
                            ]}
                        />  
                    :""}

                    {flagAttivaModaleIvaErrata===1 ?
                        <Modale 
                            titolo="Informativa importo errato"
                            flagErrore={true}
                            contenuto={<div>L'importo inserito per l'iva non è un numero: completare il campo con un valore numerico</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                                }    
                            ]}
                        />  
                    :""}

                    {flagAttivaModaleImportoPagamentoErrato===1 ?
                        <Modale 
                            titolo="Informativa importo pagamento errato"
                            flagErrore={true}
                            contenuto={<div>L'importo inserito per l'importo pagamento non è un numero: completare il campo con un valore numerico</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                                }    
                            ]}
                        />  
                    :""}

                    {flagAttivaModaleIvaPagamentoErrata===1 ?
                        <Modale 
                            titolo="Informativa iva Pagamento errata"
                            flagErrore={true}
                            contenuto={<div>L'importo inserito per l'iva pagamento non è un numero: completare il campo con un valore numerico</div>}
                            bottoni={[
                                {
                                    "etichetta":"OK",
                                    "tipo":"primary",
                                    callback:() => {setDisabilitaBottoni(false);annullaInserimentoDatiNuovi()}
                                }    
                            ]}
                        />  
                    :""}

                    <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}>
                            {props.ope === "inserisci" ? <CardTitle>Inserimento Incasso Diverso Da Iscritto</CardTitle> :""}
                            {props.ope === "modifica" ? 
                                operazioneInterna === 'copia' ?
                                <CardTitle>Inserimento Incasso Diverso Da Iscritto</CardTitle> 
                                :
                                <CardTitle>Modifica Incasso Diverso Da Iscritto</CardTitle> 
                            :""}
                            

{/*{noteRata!== undefined ? <CardTitle>mostro noterata in zero={noteRata.current[0].value}</CardTitle> :""}*/}
                           
                            <Clessidra loading={loading}/>

                            {props.ope === "cancella" ?
                                <FinestraDialogo 
                                    titolo="Conferma eliminazione"
                                    flagErrore={true}                                
                                    contenuto={<div> 
                                        Confermi l'eliminazione dell' incasso dall' iscritto <b>{insIscrittiEdi}</b> con importo <b>{FormattaImportoVideo(importoDefault)}</b> euro in data <b><TrasformaDateAUmano data={dataEmissioneDefault}></TrasformaDateAUmano></b> ?<br/>                                    
                                    </div>}
                                    bottoni={[
                                        {
                                            "etichetta":"Conferma",
                                            "tipo":"primary",
                                            callback:() => {setDisabilitaBottoni(true);fetchOperazioneServer()}
                                        },
                                        {
                                            "etichetta":"Annulla",
                                            "tipo":"primary",
                                            callback: () => {tornaElenco()}
                                        }
                                    ]}
                                />                        

                            :

                                <>
                                <Row form>  
                                <Col md={6}>
                                <Label>Iscritto*</Label>
                                {props.ope === "inserisci"  ?
                                <TendinaEditabile
                                    //titolo={"Iscritto*"}
                                    nomeCampo="COGNOME"
                                    nomeCampo2="NOME"
                                    nomeCampoParentesi="NUMERO_TESSERA"
                                    valoreDefault={insIscrittiEdi}
                                    arrayValori={risultatoServerDatiIscritti}
                                    callbackSetVariazioneCampi={setVariazioneCampi}
                                    callbackSetValoreTendEditabile={setInsIscrittiEdi}
                                ></TendinaEditabile>
                                :""}
                                {props.ope === "modifica" ? <Label>: {insIscrittiEdi}</Label> : ""}
                                </Col>

                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="descrizione">Descrizione*</Label>
                                                <Input maxLength={200} type="text" name="descrizione" id="descrizione"
                                                innerRef={descrizione} 
                                                placeholder=""
                                                defaultValue={descrizioneDefault}
                                                onInput={(e)=>{handleCambiamentoMod(e);setDescrizioneDefault(descrizione.current.value);}}/> 
                                        </FormGroup>
                                    </Col>
                                </Row>

                                {/*<Row>
                                <Col md="3"><FormGroup><Label>Risorsa*</Label></FormGroup></Col>
                                <Col md="3"><FormGroup><Label>Voce di Bilancio*</Label></FormGroup></Col>
                                <Col md="3"><FormGroup><Label>Data*</Label></FormGroup></Col>
                                <Col md="3"><FormGroup><Label>Importo*</Label></FormGroup></Col>
                                </Row>*/}
                                {arrayNumeroRate !== undefined && arrayNumeroRate !=='' && arrayNumeroRate.length>0 ?
                                    arrayNumeroRate.map((elemento,index) => <Row key={index}>
                                    
                                        <Col md="3"><FormGroup>
                                            <Label for="risorsa">Risorsa*</Label>
                                            <TendinaEditabile
                                                    nomeCampo="DESCRIZIONE"
                                                    //valoreDefault={defaultRisorse[index]}
                                                    valoreDefault={arrayRisorsaRataFatt[index]}
                                                    arrayValori={risultatoServerDatiTutteRisorse}
                                                    callbackSetVariazioneCampi={setVariazioneCampi}
                                                    callbackSetValoreTendEditabile={callbackSetValoreTendEditabileRisorsa}
                                                ></TendinaEditabile>
                                        </FormGroup></Col>

                                        <Col md="3"><FormGroup>
                                        <label htmlFor="voce">
                                        Voce di Bilancio*
                                        {arrayVociDiBilancioAttRataFatt[index] === '1' 
                                            ? <> (attività principale<img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setFlagAttivaModaleSpiegaAttivita(1)}/>)</> 
                                            : arrayVociDiBilancioAttRataFatt[index] === '0' 
                                            ? <> (attività secondaria<img src={Interrogativo} className="interrogativo" alt="aiuto" onClick={() => setFlagAttivaModaleSpiegaAttivita(1)}/>)</>
                                            : ''
                                        }
                                        </label>  
                                            <TendinaEditabile
                                                nomeCampo="DESCRIZIONE"
                                                nomeCampoNonVisibile = "TIPO_ATTIVITA"
                                                //valoreDefault={defaultVociDiBilancio[index]}
                                                valoreDefault={arrayVociDiBilancioRataFatt[index]}
                                                arrayValori={risultatoServerDatiTutteVociDiBilancio}
                                                callbackSetVariazioneCampi={setVariazioneCampi}
                                                callbackSetValoreTendEditabile={callbackSetValoreTendEditabileVociDiBilancio}
                                            ></TendinaEditabile>
                                        </FormGroup></Col>
                                
                                        <Col md="3"><FormGroup>
                                            <Label for="risorsa">Data*</Label>
                                            <Input maxLength={10} type="date"  defaultValue={arrayDataPagamentoRataFatt[index]} 
                                            ref={(elemento1) => dataPagamentoRata.current[index] = elemento1}
                                                   placeholder="" onInput={(e)=>{
                                                    handleCambiamentoMod(e);
                                                    setDataEmissioneDefault(index,e.target.value);
                                                    aggiornaDataPagamentoRata(index,e.target.value);}}/>
                                        </FormGroup></Col>
                                        
                                        <Col md="3"><FormGroup>
                                            <Label for="risorsa">Importo*</Label>
                                            <Input maxLength={12} type="text"  defaultValue={FormattaImportoVideo(arrayImportoRataFatt[index],1)} 
                                            ref={(elemento1) => importoRata.current[index] = elemento1}
                                                   placeholder="" onInput={(e)=>{
                                                    handleCambiamentoMod(e);
                                                    //setImponibileDefault(index,e.target.value);
                                                    setImportoDefault(index,e.target.value);
                                                    aggiornaImportoRata(index,e.target.value)}}/>
            
                                        </FormGroup></Col>
                                    </Row>)   
                                
                                :""}

                                <Row form>  
                                    <Col md={12}>
                                        <FormGroup>
                                            <Label for="note">Note Pagamento</Label>
                                                <Input type="text" name="note" id="note"
                                                innerRef={note} 
                                                placeholder=""
                                                defaultValue={arrayNoteRataFatt[0]}
                                                onInput={(e)=>{handleCambiamentoMod(e);aggiornaNote(note.current.value);}}/> 
                                        </FormGroup>
                                    </Col>
                                </Row>
                                </>
                                }
                                
                                 
                                </div>

                                <div className="schedaSpazioInferiore">
                                {props.ope === "inserisci" && variazioneCampi === 1  || operazioneInterna === 'copia' ? <Button disabled={disabilitaBottoni} color="success" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer()}}>Inserisci senza ricevuta</Button> :""}
                                {props.ope === "inserisci" && variazioneCampi === 1  || operazioneInterna === 'copia' ? <Button disabled={disabilitaBottoni} color="success" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer(0,1);setRicevuta(1)}}>Inserisci con ricevuta</Button> :""}
                                {props.ope === "modifica" && variazioneCampi === 1 && operazioneInterna === ''? <Button disabled={disabilitaBottoni} color="warning" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);fetchOperazioneServer()}}>Memorizza Modifiche</Button>:""}
                          
                                {props.ope === "modifica" && variazioneCampi === 0 && operazioneInterna === ''? 
                                    <Button disabled={disabilitaBottoni} color="warning" className="mt-2 bottoneMargineADestra" onClick={() => {copiaMovimento()}}>Copia Incasso</Button>
                                :""}

                                <Button disabled={disabilitaBottoni} color="primary" className="mt-2 bottoneMargineADestra" onClick={() => tornaElenco()} >
                                {(variazioneCampi === 1 && props.ope === "modifica" && operazioneInterna === '') ?  <Fragment>Annulla</Fragment>: <Fragment>Torna all'elenco</Fragment>}
                                </Button>

                                {(variazioneCampi === 0 && props.ope === "modifica" && operazioneInterna === '') ?
                                    <Button disabled={disabilitaBottoni} color="success" className="mt-2 bottoneMargineADestra" onClick={() => {setDisabilitaBottoni(true);setStatoPagina(STATO_PREPARA_PDF)}}>Stampa ricevuta</Button>
                                :""}

                                </div>
                                </div>

                    </div>
                :""}
            </div>
        );
}
export default FormGridFormRow;
