import React, {Fragment,useState,useEffect,useRef,Suspense} from 'react';
import {Row, Col,
  Card, CardBody,
  CardTitle,Button,Form,Label,Input } from 'reactstrap';

import FormGridFormRow from '../SchedaIscritto/FormGridFormRow';

import TrasformaDateAUmano from '../TrasformaDateAUmano.js';

import Clessidra from '../Clessidra.js';

//import html2pdf from 'html2pdf.js';//per estrazione pdf

import Parametri from '../../parametri';
import TendinaEditabile from '../TendinaEditabile.js';

import { Page, Text, View, Document, StyleSheet,Image } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import styles from '../stiliPdf.js';

import Modale from '../Modale.js';

import controllaDate from '../controllaDate';

let larghColonna = 2;
let larghColonnaStretta = 1;

const STATO_INDEFINITO = 0;
const STATO_RICERCA_DATI = 1;
const STATO_OPERAZIONE_CORRETTA= 2;
const STATO_ERRORE_CONNESSIONE= 3;
const STATO_RICERCA_DATI_DETTAGLIO= 5;
//const STATO_VAI_IN_INSERISCI= 6;
//const STATO_OPERAZIONE_CORRETTA2= 7;

const STATO_SCARICA_PDF = 9;

function TabellaAccessi(props) {

    var today = new Date();
    let sMese = String(today.getMonth()+1);
    if(sMese.length === 1) sMese = "0"+sMese;
    let sGiorno = String(today.getDate());
    if(sGiorno.length === 1) sGiorno = "0"+sGiorno;

    const dataOggi = today.getFullYear()+'-'+sMese+'-'+sGiorno

    //calcolo:
    let SMeseInf=parseInt(sMese)-1;//tolgo un mese
    let SAnnoInf=today.getFullYear();
    if(SMeseInf<1){
      //ho cambiato anno:
      SMeseInf=12+SMeseInf;
      SAnnoInf = SAnnoInf-1;
    }
    SMeseInf = String(SMeseInf);
    if(SMeseInf.length === 1) SMeseInf = "0"+SMeseInf;
    let dataCorretta = controllaDate(SAnnoInf,SMeseInf,sGiorno);
    const dataOggiMeno1Mesi = dataCorretta;

    let sMese1 = String(today.getMonth()+1);
    if(sMese1.length === 1) sMese1 = "0"+sMese1;
    let sGiorno1 = String(today.getDate());
    if(sGiorno1.length === 1) sGiorno1 = "0"+sGiorno1;
    let SMeseSup=parseInt(sMese1)+0;//aggiungo 0 mesi (dopo oggi non può essere entrato nessuno)

    let SAnnoSup=today.getFullYear();
    if(SMeseSup>12){
      SMeseSup=SMeseSup-12;
      SAnnoSup = SAnnoSup+1;
    }
    SMeseSup = String(SMeseSup);
    if(SMeseSup.length === 1) SMeseSup = "0"+SMeseSup;
    let dataCorretta1 = controllaDate(SAnnoSup,SMeseSup,sGiorno1);

    const dataOggiPiu0Mesi = dataCorretta1;
    const [statoPagina,setStatoPagina]=useState(STATO_INDEFINITO);
    const [variazioneCampi,setVariazioneCampi]=useState(0);
   
    const [statoTendinaIscritto,setStatoTendinaIscritto]=useState(false);
   
    const [risultatoServerDatiPersona,setRisultatoServerDatiPersona]=useState('');
  
    const [insPersonaEdi,setInsPersonaEdi]=useState('');
    const [insIdPersonaEdi,setInsIdPersonaEdi]=useState('');
    const [defaultPersona,setDefaultPersona]=useState('');

    const [error,setError]=useState('');

    const  dataPartenza = useRef();
    const  dataArrivo = useRef();
 
    const [dataPartenzaDefault,setDataPartenzaDefault]=useState(dataOggiPiu0Mesi);
    const [dataArrivoDefault,setDataArrivoDefault]=useState(dataOggiPiu0Mesi);

    const [loading,setLoading]= useState(false);

    const [calcola,setCalcola]=useState(false);
    const [visualizza,setVisualizza]=useState(false);
    const [visualizzaInPdf,setVisualizzaInPdf]=useState(false);

    const [risultatoAccessi,setRisultatoAccessi]=useState('');

    //per il logo:
    const [ricercaLogo,setRicercaLogo]=useState('');
    const [fileLogo,setFileLogo]=useState('');
    const [typeLogo,setTypeLogo]=useState('');
    const [nomeSocieta,setNomeSocieta]=useState('');
    const [capSocieta,setCapSocieta]=useState('');
    const [cittaSocieta,setCittaSocieta]=useState('');
    const [indirizzoSocieta,setIndirizzoSocieta]=useState('');
    const [pIvaSocieta,setPIvaSocieta]=useState('');
    const [codiceFiscaleSocieta,setCodiceFiscaleSocieta]=useState('');

    //var MyDocument = '';
    var elem = document.getElementById("cont");
    const [larghezzaPdf,setLarghezzaPdf] = useState(0);
    
    useEffect(() => {
      if(elem) setLarghezzaPdf(elem.getBoundingClientRect().width); 
    },[elem]);

    useEffect(() => {
      setStatoTendinaIscritto(false);
      ricercaDatiPersona();
      setDefaultPersona("Tutti");
      scaricaLogo();
    },[]);
 
    useEffect(() => {
      setVisualizza(false);
      setCalcola(false);
    },[insPersonaEdi,dataPartenzaDefault,dataArrivoDefault]);

    useEffect(() => {
      if(calcola === true){
        ricercaDatiAccessi();
      }
    },[calcola]);

    useEffect(() => {
      if(visualizzaInPdf === true) {
        //handleEsportaPDF();
        setStatoPagina(STATO_SCARICA_PDF);
      }
    },[visualizzaInPdf]);

    useEffect(() => {
      if(statoTendinaIscritto === true  && ricercaLogo === 1){
        setStatoPagina(STATO_OPERAZIONE_CORRETTA);
      }
    },[statoTendinaIscritto,ricercaLogo]);

    //funzioni:
    function ricercaDatiAccessi(){
      //ricerco Accessi per la persona in esame:

      //verifico di aver passato i controlli:
      let controlli=0;
      
      if(controlli===0){ 
        setLoading(true);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({op:"reportElencoAccessi",
            idPersona:insIdPersonaEdi,
            dataInizio:dataPartenzaDefault,
            dataFine:dataArrivoDefault,
            emak: props.emak,
            sessionId:props.sessionId})
        };
    
        fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
            .then(risposta => risposta.json())
            .then(
            (valoreDiRitorno) => {
                setLoading(false);
                if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                    alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                  }
                  if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                  if(valoreDiRitorno.risultatoOperazione===1){
                    setRisultatoAccessi(valoreDiRitorno.risultatoRitorno);                    
                    setVisualizza(true);
                  }
            },
            (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
            );
      }
  }
  function scaricaLogo() {
    setLoading(true);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({op:"ricercaLogo", emak: props.emak,sessionId: props.sessionId})
    };

    fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
      .then(risposta => risposta.json())
      .then(
      (valoreDiRitorno) => {     
        setLoading(false);     
          if(valoreDiRitorno.risultatoOperazione===1){
              if (valoreDiRitorno.risultatoRitorno[0].FILE !== undefined) {
                setFileLogo(valoreDiRitorno.risultatoRitorno[0].FILE);
                setTypeLogo(valoreDiRitorno.risultatoRitorno[0].TYPE);
                setNomeSocieta(valoreDiRitorno.risultatoRitorno[0].NOME);
                setCapSocieta(valoreDiRitorno.risultatoRitorno[0].CAP);
                setCittaSocieta(valoreDiRitorno.risultatoRitorno[0].CITTA);
                setIndirizzoSocieta(valoreDiRitorno.risultatoRitorno[0].INDIRIZZO);
                setPIvaSocieta(valoreDiRitorno.risultatoRitorno[0].PIVA);
                setCodiceFiscaleSocieta(valoreDiRitorno.risultatoRitorno[0].CODICE_FISCALE);
              }
              setRicercaLogo(1);
          }
      },
      (error) => {console.log("Errore connessione");}
    );        
  }


    function ricercaDatiPersona() {
      //ricerco tra gli iscritti che mi servono per popolare la tendina delle persone che possono avere accessi:
      setLoading(true);
      const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({op:"elencoPersone",recordPartenza:1,intervallo:'',emak: props.emak,sessionId:props.sessionId})
      };
  
      fetch(Parametri("serverURL",props.identitaSelezionata), requestOptions)
          .then(risposta => risposta.json())
          .then(
          (valoreDiRitorno) => {
              setLoading(false);
              if(valoreDiRitorno.risultatoOperazione!==1 && valoreDiRitorno.risultatoOperazione!==2){
                  alert("Si è verificato un errore gestendo la richiesta, se l'errore persiste contattare l'assistenza tecnica");
                }
                if(valoreDiRitorno.risultatoOperazione===2) props.setPagina(props.PAGINE.tornaLogin);
                if(valoreDiRitorno.risultatoOperazione===1){
              setRisultatoServerDatiPersona(valoreDiRitorno.risultatoRitorno);
              setStatoTendinaIscritto(true);
              }
          },
          (error) => {setError(error);setStatoPagina(STATO_ERRORE_CONNESSIONE);}
          );
    }


    function callbackSetVariazioneCampi(variazioneCampi){
      setVariazioneCampi(variazioneCampi);
    }
    function callbackSetValoreTendEditabilePersona(valore,pos,valoreNonVisibile){

        setInsPersonaEdi(valore);
        setInsIdPersonaEdi(valoreNonVisibile);
    }
    //fine funzioni

    function callbackTermineModifica() {
      setStatoPagina(STATO_RICERCA_DATI);
    }

  return (
    <div id="cont" style={{minHeight: "100%",backgroundColor:"#F2F2F2"}}><Clessidra loading={loading}/>
     
  {statoPagina === STATO_SCARICA_PDF ? 
      <div>
      {/*<PDFViewer style={styles.viewer}>*/}
      <div style={{height: props.dimensioniApp.yMain,width: larghezzaPdf,display:"flex"}}><Clessidra loading={true}/></div>
      <PDFViewer style={{height: props.dimensioniApp.yMain,width: larghezzaPdf ,display:"flex", flexDirection:"column",backgroundColor:"transparent",position:"absolute",top:props.dimensioniApp.yTitolo}}>
    
        <Document>
          {/*<Page orientation="landscape" size="A4" style={styles.page}>*/}
          <Page size="A4" style={styles.page}>
            <View style={{width:"100%"}} >
              <View style={styles.tableIntestazione}> {/*apre table intestazione*/}
                      <View wrap={false} style={styles.tableRowIntestazione}> 
                        <View style={styles.tableColIntestazioneSx}> 
                          <View style={styles.tableCell}> 
                            {fileLogo !== "" ? <Image style={styles.logo} src={"data:" + typeLogo + ";base64," + fileLogo} />:<Text></Text>}                                    
                          </View> 
                        </View> 
                      <View style={styles.tableColIntestazioneDx}>   
                        <View style={styles.tableCellIntestazione}> 
                          {nomeSocieta !== "" ?<Text style={styles.nomesocieta}>{nomeSocieta}</Text>:<Text></Text>}
                          {indirizzoSocieta !== "" ?
                          <Text style={styles.datisocieta}>{indirizzoSocieta}
                          {cittaSocieta !== "" ?
                            capSocieta !== "" ?
                              " "+capSocieta+" "+cittaSocieta
                            :" "+cittaSocieta
                          :""}
                          </Text>
                          :<Text></Text>}
                          {pIvaSocieta !== "" ?<Text style={styles.datisocieta}>Partita IVA: {pIvaSocieta}</Text>:<Text></Text>}
                          {codiceFiscaleSocieta !== "" ?<Text style={styles.datisocieta}>Codice Fiscale: {codiceFiscaleSocieta}</Text>:<Text></Text>}
                                                
                          <Text style={styles.titolo}>Elenco Accessi</Text>
                         
                      </View> 
                    </View> 
                    </View> 
                  </View> {/*chiude table intestazione*/}

                  <View>
                    <Text style={styles.titoloPiccolo}>
                      Report del <TrasformaDateAUmano data={dataOggi} />
                    </Text>
                    <Text style={{ fontSize: 10, color: 'red', marginTop: 5 }}>
                      * Le righe evidenziate in rosso indicano utenti con uno o più accessi rifiutati.
                    </Text>
                  </View>
                  <View wrap={false}> 
                      {insIdPersonaEdi !=='' ?
                        <View style={{marginTop: 5 }}>
                          <Text style={styles.titoloPiccolo}>Iscritto: {insPersonaEdi}</Text>
                        </View>
                      :""}

                        <View style={{marginTop: 5 }}>
                          {/*<Text style={styles.titoloPiccolo}>Inizio Periodo di Analisi: <TrasformaDateAUmano senzaGiorni={true} data={dataPartenza.current.value}></TrasformaDateAUmano></Text>*/}
                          <Text style={styles.titoloPiccolo}>Inizio Periodo di Analisi: <TrasformaDateAUmano data={dataPartenzaDefault}/></Text>
                        </View>
                      
                      
                        <View style={{marginTop: 5 }}>
                          {/*<Text style={styles.titoloPiccolo}>Fine Periodo di Analisi: <TrasformaDateAUmano senzaGiorni={true} data={dataArrivo.current.value}></TrasformaDateAUmano></Text>*/}
                          <Text style={styles.titoloPiccolo}>Fine Periodo di Analisi: <TrasformaDateAUmano data={dataArrivoDefault}/></Text>
                        </View>
                     
                    </View> 

                <View style={styles.table}>
                  <View wrap={false} style={styles.tableRowBlu}>
                          <View style={styles.tableCol5Di100}> 
                            <Text style={styles.tableCellGrassettoAllineatoSinistra}></Text> 
                          </View>  
                          <View style={styles.tableCol30Di100}> 
                            <Text style={styles.tableCellGrassettoAllineatoSinistra}>Cognome</Text> 
                          </View> 
                            <View style={styles.tableCol25Di100}> 
                              <Text style={styles.tableCellGrassettoAllineatoSinistra}>Nome</Text> 
                            </View>
                            <View style={styles.tableCol20Di100}> 
                              <Text style={styles.tableCellGrassettoAllineatoSinistra}>N.Tessera</Text> 
                            </View> 
                            <View style={styles.tableCol10Di100}> 
                              <Text style={styles.tableCellGrassettoAllineatoSinistra}>Data</Text> 
                            </View> 
                            <View style={styles.tableCol10Di100}> 
                              <Text style={styles.tableCellGrassettoAllineatoSinistra}>Numero</Text> 
                            </View> 
                      </View>

                      {risultatoAccessi.map((elemento, index) => (
                        <View
                        style={[
                          styles.tableRow,
                          elemento.RESPINTO == 1 ? { backgroundColor: 'red' } : {}
                        ]}
                        wrap={false}
                        key={index}
                      >
                      
                          <View style={styles.tableCol5Di100}>
                            <Text style={styles.tableCell}> {index + 1}</Text>
                          </View>
                          <View style={styles.tableCol30Di100}>
                            <Text style={styles.tableCellAllineatoSinistra}> {elemento.COGNOME}</Text>
                          </View>
                          <View style={styles.tableCol25Di100}>
                            <Text style={styles.tableCellAllineatoSinistra}> {elemento.NOME}</Text>
                          </View>
                          <View style={styles.tableCol20Di100}>
                            <Text style={styles.tableCellAllineatoSinistra}> {elemento.NUMERO_TESSERA}</Text>
                          </View>
                          <View style={styles.tableCol10Di100}>
                            {elemento.DATA_ACCESSO.length > 7 ? (
                              <Text style={styles.tableCellAllineatoSinistra}>
                                <TrasformaDateAUmano data={elemento.DATA_ACCESSO.split('T')[0]}/> {elemento.DATA_ACCESSO.split('T')[1]}
                              </Text>
                            ) : (
                              <Text style={styles.tableCellAllineatoSinistra}>
                                <TrasformaDateAUmano senzaGiorni={true} data={elemento.DATA_ACCESSO.split('T')[0]}/> {elemento.DATA_ACCESSO.split('T')[1]}
                              </Text>
                            )}
                          </View>
                          <View style={styles.tableCol10Di100}>
                            <Text style={styles.tableCellAllineatoSinistra}> {elemento.NUMERO_ACCESSI}</Text>
                          </View>
                        </View>
                      ))}
                  </View>
              </View>
              <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (`${pageNumber} / ${totalPages}`)} fixed />
            </Page>
          </Document>
      </PDFViewer>
    </div>

    :""}


      {statoPagina === STATO_ERRORE_CONNESSIONE ? <div>Errore: 1E_R_EleAcc</div> :""}  
      {statoPagina === STATO_RICERCA_DATI || statoPagina === STATO_RICERCA_DATI_DETTAGLIO ? <Card className="main-card mb-3"><Clessidra loading={true}/></Card> :""}

      {statoPagina === STATO_OPERAZIONE_CORRETTA ?
       
        <div className="schedaContenitore" style={{height: props.dimensioniApp.yMain}}>
          
            <Row>
              <Col lg="12">
                  <div className="schedaSpazioCentrale" style={{height: props.dimensioniApp.yMain}}>
                      {visualizzaInPdf === true ?
                        <div id='idDivGenerazionePdf' style={{display:'none'}}>Generazione PDF in corso...</div>
                      :""}

                            <CardTitle id="titolo">Elenco Accessi&nbsp;
                            <input type="date" defaultValue={dataPartenzaDefault} onChange={(e)=>{setDataPartenzaDefault(e.target.value)}}/>
                            &nbsp;al&nbsp;
                            <input type="date" defaultValue={dataArrivoDefault} onChange={(e)=>{setDataArrivoDefault(e.target.value)}}/>
                            &nbsp;
                            </CardTitle>
                           
                                <Row form>  
                                <Col md={10}>    
                                <Label for="iscritto">Iscritto</Label>         
                                <TendinaEditabile
                                    //titolo={"Iscritto"}
                                    nomeCampo="COGNOME"
                                    nomeCampo2="NOME"
                                    nomeCampoParentesi="NUMERO_TESSERA"
                                    nomeCampoNonVisibile="ID"
                                    bloccoInserimento = "1"
                                    valoreDefault={defaultPersona}
                                    arrayValori={risultatoServerDatiPersona}
                                    inserisciCampoTutti = "1"
                                    callbackSetVariazioneCampi={callbackSetVariazioneCampi}
                                    callbackSetValoreTendEditabile={callbackSetValoreTendEditabilePersona}
                                ></TendinaEditabile>
                                </Col>       
                                
                                {/*
                                <Col md={2}>
                                <Label>Inizio Periodo di Analisi</Label>
                                <input type="date" defaultValue={dataPartenzaDefault} onChange={(e)=>{setDataPartenzaDefault(e.target.value)}}/>
                                </Col>
                                <Col md={2}>
                                <Label>Fine Periodo di Analisi</Label>
                                <input type="date" defaultValue={dataArrivoDefault} onChange={(e)=>{setDataArrivoDefault(e.target.value)}}/>
                                </Col>*/}
                                {visualizza !== true ?
                                  <Col md={2}>
                                    <div style={{paddingTop:24}}> 
                                      <Button color="success" className="mt-2" onClick={() => {setCalcola(true);setVisualizzaInPdf(false)}}>Calcola</Button>
                                    </div> 
                                  </Col>
                                : 
                                <Col md={2}>
                                    <div style={{paddingTop:24}}> 
                                      <Button color="success" className="mt-2" onClick={(e)=>{setVisualizzaInPdf(true)}}>Esporta il PDF</Button>
                                    </div> 
                                </Col>
                                }
                                </Row>
                              
                            <div id="dettaglioReport">
                            {visualizza === true ?
                              risultatoAccessi.length === 0 ?
                                <Fragment >
                                  <Row form className="border">
                                  <Col md='12'><b>Non risultano accessi che soddisfino le condizioni impostate</b></Col>
                                  </Row>
                                </Fragment>
                              :
                              
                              <Fragment>
                              
                              <Row form style={{ marginTop: 10, marginBottom: 10 }}>
                                <Col>
                                  <b>Report del <TrasformaDateAUmano data={dataOggi} /></b>
                                  <div style={{ fontSize: "10px", color: "red", marginTop: "5px" }}>
                                    * Le righe evidenziate in rosso indicano utenti con uno o più accessi rifiutati.
                                  </div>
                                </Col>
                              </Row>
  
                              <Row form className="rigaTitoloTabellaReport">
                                <Col md={larghColonnaStretta} className="titoloTabellaReport"><b></b></Col>
                                <Col md={larghColonna} className="titoloTabellaReport">Cognome</Col>
                                <Col md={larghColonna} className="titoloTabellaReport">Nome</Col>
                                <Col md={larghColonnaStretta} className="titoloTabellaReport">Tessera</Col>                            
                                <Col md={larghColonna} className="titoloTabellaReport">Data</Col>
                                <Col md={larghColonnaStretta} className="titoloTabellaReport">Numero<br/>Accessi</Col>
                                <Col md={larghColonnaStretta} className="titoloTabellaReport">Accessi<br/>Residui</Col>
                                <Col md={larghColonna} className="titoloTabellaReport">Manca</Col>
                              </Row>
              
                              {risultatoAccessi.map((elemento, index) => (
                                <Row
                                  key={index}
                                  className="rigaTabellaReport"
                                  style={elemento.RESPINTO == 1 ? { backgroundColor: "red", color: "white" } : {}}
                                >
                                  <Col md={larghColonnaStretta}><center>{index + 1}</center></Col>
                                  <Col md={larghColonna}>{elemento.COGNOME}</Col>
                                  <Col md={larghColonna}>{elemento.NOME}</Col>
                                  <Col md={larghColonnaStretta}>{elemento.NUMERO_TESSERA}</Col>
                                  {elemento.DATA_ACCESSO.length > 7 ? (

                                    <Col md={larghColonna}><TrasformaDateAUmano data={elemento.DATA_ACCESSO.split('T')[0]}/> {elemento.DATA_ACCESSO.split('T')[1]}</Col>
                                  ) : (
                                    <Col md={larghColonna}><TrasformaDateAUmano senzaGiorni={true} data={elemento.DATA_ACCESSO.split('T')[0]}/> {elemento.DATA_ACCESSO.split('T')[1]}</Col>
                                  )}
                                  <Col md={larghColonnaStretta}>{elemento.NUMERO_ACCESSI}</Col>
                                  <Col md={larghColonnaStretta}>{elemento.ACCESSI_RESIDUI==-1?"inf.":elemento.ACCESSI_RESIDUI}</Col>
                                  <Col md={larghColonna}>
                                    {elemento.FLAG_ABB==0 && "abb "}                                    
                                    {elemento.FLAG_DOC==0 && "doc.id "}
                                    {elemento.FLAG_CERT==0 && "cert.medico "}
                                    {elemento.FLAG_PRIVACY==0 && "doc.privacy "}
                                  </Col>
                                </Row>
                              ))}
                              </Fragment>
                            :""}
                        </div>                            
                      
                    </div>
                </Col>
            </Row>
        </div>
      :""}
    </div>
   );
}
export default TabellaAccessi;